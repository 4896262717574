import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function MacOS(){
    const related = [
        {
            href: '/kaos',
            name: 'KaOS',
            side: 'full support',
            text: 'KaOS is a community Linux distribution. Learn how to use JXL images within KaOS.'
        },
        {
            href: '/macos',
            name: 'macOS',
            side: 'partial support',
            text: 'macOS does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
        {
            href: '/openmandriva',
            name: 'OpenMandriva',
            side: 'full support',
            text: 'OpenMandriva is a community Linux distribution. Learn how to use JXL images within OpenMandriva.'
        },
        {
            href: '/windows',
            name: 'Windows',
            side: 'partial support',
            text: 'Windows does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
    ]
    return(
        <>
            <Heading h='macOS JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#macos-is-kaos' text='macOS JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='macos-is-kaos'>
                    <h1 className="main">macOS JXL support</h1>
                    <p className='umain'>macOS offers no native support for JPEG XL. No public plans exist if Apple enables support in the future. Still, you can use several free and publicly available image viewers to view JPEG XL images, including:</p>
                    <span className='green'>JXLook, a JPEG-XL viewer for macOS, also working with QuickLook</span>
                    <span className='green'>PhotoQT, a simple yet powerful image viewer for Mac OS X</span>
                    <span className='green'>XnView MP, an efficient multimedia viewer for macOS</span>
                    <p className='umain'></p>
                    <p className='umain'></p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>

                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default MacOS