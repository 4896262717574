import './Tutorials.css';
import { useEffect, useState } from 'react';

function Tutorials(props) {
    const styles = {
        styles1: {
            backgroundColor: 'rgba(46,175,111,.1)'
        },
        styles2: {
            backgroundColor: 'rgba(152,48,90,.1)'
        },
        styles3: {
            backgroundColor: 'rgba(159,159,7,.1)'
        }
    };

    const [style, setStyle] = useState(styles.styles1);

    useEffect(() => {
        if (props.side === 'partial support') {
            setStyle(styles.styles3);
        } else if (props.side === 'full support') {
            setStyle(styles.styles1);
        } else {
            setStyle(styles.styles2);
        }
    }, [props.side]);

    return (
        <a href={props.href} className='tutorial'>
            <div className='side_text' style={style}>
                {props.side}
            </div>
            <div className='tutorial_content'>
                <h2>{props.name}</h2>
                <p>{props.text}</p>
            </div>
        </a>
    );
}

export default Tutorials;
