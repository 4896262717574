import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import jxl from "../../images/frog.jxl";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Rust(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return (
        <>
            <Heading h='Rust JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-rust' text='What is Rust?'/>
                        <LiContent n='2' a='#rust-jxl-support' text='Rust JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-rust'>
                    <h1 className="main">What is Rust?</h1>
                    <p className='umain'>As a fast and memory-efficient language, Rust does not require a runtime or garbage collector, so it can be used to run embedded devices, power performance-critical web services, and easily integrate with other languages. Rust's rich type system and ownership model guarantees memory safety and thread safety, enabling you to eliminate many categories of bugs before they are compiled. There is excellent documentation, an excellent compiler with helpful error messages, and top-quality tooling - an integrated package manager and build tool, superb support for multi-editors with auto-completion and type inspection, as well as an auto-formatter.</p>
                </span>
                <Line/>

                <span id='rust-jxl-support'>
                    <h1 className="main">Rust JXL support</h1>
                    <p className='umain'>The crate JPEGXL_RS offers a safe JPEGXL wrapper over the jpeg-xl library, based on the official libjxl.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Rust