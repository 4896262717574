import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Libvips(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return (
        <>
            <Heading h='libvips JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-libvips' text='What is libvips?'/>
                        <LiContent n='2' a='#libvips-jxl-support' text='libvips JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-libvips'>
                    <h1 className="main">What is libvips?</h1>
                    <p className='umain'>LibVIPS is an image processing library that is horizontally threaded based on demand. LibVIPS runs rapidly and uses little memory in comparison to similar libraries. The LGPL 2.1+ license applies to libvips. The algorithm has approximately 300 operations, including arithmetic, histograms, convolutions, morphological operations, frequency filtering, color, resampling, statistics, etc. It supports a wide range of numeric types, from 8-bit integers to 128-bit complex numbers. Images may consist of any number of bands. It supports a good range of image formats, including JPEG, JPEG2000, TIFF, PNG, WebP, HEIC, AVIF, FITS, Matlab, OpenEXR, PDF, SVG, HDR, PPM / PGM / PFM, CSV, GIF, Analyze, NIfTI, DeepZoom, and OpenSlide. It can also load images using ImageMagick or GraphicsMagick, enabling it to work with DICOM formats. Bindings are available for C, C++, and the command line. A complete set of bindings is available for Ruby, Python, PHP, C# / .NET, Go, and Lua. Several software applications use libvips as an image processing engine, including sharp (on node.js), bimg, sharp for Go, Ruby on Rails, carrierwave-vips, mediawiki, PhotoFlow and others. The official GUI for libvips is nip2, a strange combination of a spreadsheet and a photo editor.</p>
                </span>
                <br/>
                <Line/>

                <span id='libvips-jxl-support'>
                    <h1 className="main">libvips JXL support</h1>
                    <p className='umain'>A new version of libvips 8.11 was released on June 4th, 2021, with experimental support for JPEG XL. JPEG has been replaced with other formats several times in the past few years. HEIC is the best known: it can compress files to about half their size compared with comparable JPEG files and supports several valuable features, including animations, transparency, and lossless compression. Some patent issues may limit its usefulness. AVIF is similar to HEIC. However, it does not possess a patent. Unfortunately, the available libraries for loading and saving are slow. Compared to HEIC and AVIF, JPEG-XL appears to offer the same compression level and valuable features but has no patent issues and is sufficiently fast to be practical.</p>
                    <p className='umain'>Due to the support for JPEG XL in libvips, support will likely be added to other libraries and programs, including the Node.JS module sharp for image processing.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Libvips;