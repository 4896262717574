import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import irfan from '../../images/irfanview.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";
function IrfanView(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='IrfanView JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-irfanview' text='What is IrfanView?'/>
                        <LiContent n='2' a='#irfanview-jxl-support' text='IrfanView JXL Support'/>
                        <LiContent n='3' a='#irfanview-jxl-tutorial' text='IrfanView JXL Tutorial'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-irfanview'>
                    <h1 className="main">What is IrfanView?</h1>
                    <p className='umain'>IrfanView is a free program for Windows that allows the user to view, edit, organize, and convert images. It is a small, fast, and easy-to-use program that can handle a wide variety of graphic formats. Furthermore, it is capable of creating and painting pictures as well as playing video and audio files. A free version of IrfanView is available for non-commercial use, whereas a registered version is available for commercial use. It was first released in 1996.</p>
                </span>
                <Line/>

                <span id='irfanview-jxl-support'>
                    <h1 className="main">IrfanView JXL Support</h1>
                    <p className='umain'>A new evaluation copy of IrfanView has recently been released by the developer, which includes JXL support. A Reddit user, Jaystarx, has been in touch with the developer and provided a link to the version on the JPEG XL subreddit. Upon consultation with the developer, he made the version available for experienced JXL users to test Irfanview's compatibility to ensure that it works correctly before the official release.</p>
                </span>
                <Line/>

                <span id='irfanview-jxl-tutorial'>
                    <h1 className="main">IrfanView JXL Tutorial</h1>
                    <p className='umain'>Download the preview with the link below Unzip the content directly into the common IrfanView folder Overwrite any files in the process Make sure the JXL plugin is activated (Help>Installed plugins> JPEG_XL.DLL)</p>
                    <p className='umain'>The files can then be read and saved as JXL. The basic JXL settings (Save Quality and Speed Effort) can then be adjusted, and a checkbox option is provided to save the JXL as a Progressive JXL.</p>
                    <img src={irfan} className='code5'/>
                    <br/>
                    <p className='umain'>Irfanview can also be associated with JXL files if you want to click on the JXL file directly from Explorer. Go to the menu: Options > Properties/Settings > Extensions, and in the list of file-types, tick the 'JXL' checkbox.</p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default IrfanView;