function LiContent(props){
    return(
        <>
            <li>
                <span className='num'>{props.n}</span>
                <a href={props.a} className='green'>{props.text}</a>
            </li>
        </>
    )
}

export default LiContent;