import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function OpenMandriva(){
    const related = [
        {
            href: '/kaos',
            name: 'KaOS',
            side: 'full support',
            text: 'KaOS is a community Linux distribution. Learn how to use JXL images within KaOS.'
        },
        {
            href: '/macos',
            name: 'macOS',
            side: 'partial support',
            text: 'macOS does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
        {
            href: '/openmandriva',
            name: 'OpenMandriva',
            side: 'full support',
            text: 'OpenMandriva is a community Linux distribution. Learn how to use JXL images within OpenMandriva.'
        },
        {
            href: '/windows',
            name: 'Windows',
            side: 'partial support',
            text: 'Windows does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
    ]
    return(
        <>
            <Heading h='OpenMandriva JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-openmandriva' text='What is OpenMandriva?'/>
                        <LiContent n='2' a='#openmandriva-jxl-support' text='OpenMandriva JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-openmandriva'>
                    <h1 className="main">What is OpenMandriva?</h1>
                    <p className='umain'>OpenMandriva's mission is to create a pragmatic distribution that provides the best user experience to all users, including newcomers and developers alike. Their marketing material states that they have achieved their goal of balancing the most modern features with stability. Having their roots in Mandrake and its traditions, they are a worldwide community of free software enthusiasts who collaborate and take technical and strategic decisions together.</p>
                </span>
                <Line/>

                <span id='openmandriva-jxl-support'>
                    <h1 className="main">OpenMandriva JXL support</h1>
                    <p className='umain'>OpenMandriva 4.3 RC1 has been released, and it natively supports handling of JPEG-XL at all levels of the Operating System: QT applications can access it, GDK applications can use it, and even Gimp can use it if desired.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default OpenMandriva