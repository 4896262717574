import React, {useEffect, useRef, useState} from 'react';
import './Support.css';
import Article from "./Article/Article";
import Tutorials from "./Tutorials/Tutorials";
import {useLocation} from "react-router-dom";

function Support() {
    const location = useLocation();
    const refs = {
        'blog': useRef(null),
        'tutorials': useRef(null)
    }
    const [filter, setFilter] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [isCustomFilterActive, setIsCustomFilterActive] = useState(false);
    const [array, setArray] = useState([]);
    const [partial, setPartial] = useState([]);
    const [full, setFull] = useState([]);
    const [no, setNo] = useState([]);

    const handleFilterClick = (selectedFilter) => {
        setFilter(filter === selectedFilter ? 'all' : selectedFilter);
        setIsCustomFilterActive(false);
    };

    useEffect(() => {
        console.log(tutorials)
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
        setIsCustomFilterActive(false);
    };

    const handleCustomFilterClick = (name) => {
        if (name === filter) {
            setIsCustomFilterActive(false);
            setFilter('all');
            setArray([]);
        } else {
            setIsCustomFilterActive(true);
            setFilter(name);
            if (name === 'browser') {
                setArray(browser);
            } else if (name === 'cdn') {
                setArray(cdn);
            } else if (name === 'coding') {
                setArray(coding);
            } else if (name === 'graphics') {
                setArray(graphics);
            } else if (name === 'social') {
                setArray(social);
            } else if (name === 'os') {
                setArray(os);
            } else if (name === 'mail') {
                setArray(mail);
            } else if (name === 'cms') {
                setArray(cms);
            } else if (name === 'partial') {
                setArray(partial);
            } else if (name === 'full') {
                setArray(full);
            } else if (name === 'no') {
                setArray(no);
            } else {
                setArray([]);
            }
        }
    };

    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];

    const tutorials = [{
        href: '/brave',
        name: 'Brave',
        side: 'partial support',
        text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
    }, {
        href: '/chrome',
        name: 'Chrome',
        side: 'partial support',
        text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
    }, {
        href: '/cloudinary',
        name: 'Cloudinary',
        side: 'partial support',
        text: 'Image CDNs help you serve the best image to users. Find out how you can use JPEG XL images in Cloudinary.'
    }, {
        href: '/css',
        name: 'CSS',
        side: 'full support',
        text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
    }, {
        href: '/digikam',
        name: 'digiKam',
        side: 'full support',
        text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
    }, {
        href: '/edge',
        name: 'Edge',
        side: 'partial support',
        text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
    }, {
        href: '/exiftool',
        name: 'ExifTool',
        side: 'full support',
        text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
    }, {
        href: '/exiv2',
        name: 'Exiv2',
        side: 'full support',
        text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
    }, {
        href: '/facebook',
        name: 'Facebook',
        side: 'partial support',
        text: 'Facebook is by far the most-known social network. Learn how to use JXL images within Facebook.'
    }, {
        href: '/firefox',
        name: 'Firefox',
        side: 'partial support',
        text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
    }, {
        href: '/geeqie',
        name: 'Geeqie',
        side: 'full support',
        text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
    }, {
        href: '/gimp',
        name: 'GIMP',
        side: 'partial support',
        text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
    }, {
        href: '/gthumb',
        name: 'gThumb',
        side: 'full support',
        text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
    }, {
        href: '/gwenview',
        name: 'Gwenview',
        side: 'full support',
        text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
    }, {
        href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
    }, {
        href: '/imageglass',
        name: 'ImageGlass',
        side: 'full support',
        text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
    }, {
        href: '/imagemagick',
        name: 'ImageMagick',
        side: 'full support',
        text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
    }, {
        href: '/irfanview',
        name: 'IrfanView',
        side: 'partial support',
        text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
    }, {
        href: '/kaos',
        name: 'KaOS',
        side: 'full support',
        text: 'KaOS is a community Linux distribution. Learn how to use JXL images within KaOS.'
    }, {
        href: '/kde-frameworks',
        name: 'KDE Frameworks',
        side: 'full support',
        text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
    }, {
        href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
    }, {
        href: '/macos',
        name: 'macOS',
        side: 'partial support',
        text: 'macOS does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
    }, {
        href: '/nomacs',
        name: 'nomacs',
        side: 'partial support',
        text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
    }, {
        href: '/openmandriva',
        name: 'OpenMandriva',
        side: 'full support',
        text: 'OpenMandriva is a community Linux distribution. Learn how to use JXL images within OpenMandriva.'
    }, {
        href: '/opera',
        name: 'Opera',
        side: 'partial support',
        text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
    }, {
        href: '/photoqt',
        name: 'PhotoQt',
        side: 'full support',
        text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
    }, {
        href: '/photostack',
        name: 'PhotoStack',
        side: 'full support',
        text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
    }, {
        href: '/php',
        name: 'PHP',
        side: 'partial support',
        text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
    }, {
        href: '/picview',
        name: 'PicView',
        side: 'full support',
        text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
    }, {
        href: '/qimgv',
        name: 'qimgv',
        side: 'full support',
        text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
    }, {
        href: '/rust',
        name: 'Rust',
        side: 'full support',
        text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
    }, {
        href: '/safari',
        name: 'Safari',
        side: 'no support',
        text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
    }, {
        href: '/shimmercat',
        name: 'ShimmerCat',
        side: 'full support',
        text: 'Image CDNs help you serve the best image to users. Find out how you can use JPEG XL images in ShimmerCat.'
    }, {
        href: '/squoosh',
        name: 'squoosh',
        side: 'partial support',
        text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
    }, {
        href: '/the-lounge',
        name: 'The Lounge',
        side: 'no support',
        text: 'The Lounge is a modern web-based IRC client. Learn how to use JXL images within The Lounge.'
    }, {
        href: '/thunderbird',
        name: 'Thunderbird',
        side: 'partial support',
        text: 'Thunderbird is the first email client to support JXL images. Find out how you can use JXL images in Thunderbird.'
    }, {
        href: '/vivaldi',
        name: 'Vivaldi',
        side: 'partial support',
        text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
    }, {
        href: '/windows',
        name: 'Windows',
        side: 'partial support',
        text: 'Windows does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
    }, {
        href: '/wordpress',
        name: 'Wordpress',
        side: 'partial support',
        text: 'Learn how to use JXL images within Wordpress.'
    }, {
        href: '/xnview',
        name: 'XnView',
        side: 'full support',
        text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
    }];

    const filteredArticles = articles.filter(article => article.name.toLowerCase().includes(searchQuery) || article.text.toLowerCase().includes(searchQuery));

    const filteredTutorials = tutorials.filter(tutorial => tutorial.name.toLowerCase().includes(searchQuery) || tutorial.text.toLowerCase().includes(searchQuery));


    useEffect(() => {
        const partialSupport = [];
        const fullSupport = [];
        const noSupport = [];

        tutorials.forEach(tutorial => {
            if (tutorial.side === 'partial support') {
                partialSupport.push(tutorial);
            } else if (tutorial.side === 'full support') {
                fullSupport.push(tutorial);
            } else if (tutorial.side === 'no support') {
                noSupport.push(tutorial);
            }
        });

        setPartial(partialSupport);
        setFull(fullSupport);
        setNo(noSupport);
    }, []);

    const browser = [tutorials[0], tutorials[1], tutorials[5], tutorials[9], tutorials[24], tutorials[31], tutorials[36]]
    const cdn = [tutorials[2], tutorials[32]]
    const coding = [tutorials[3], tutorials[6], tutorials[7], tutorials[14], tutorials[16], tutorials[19], tutorials[20], tutorials[27], tutorials[30], tutorials[33]]
    const graphics = [tutorials[4], tutorials[10], tutorials[11], tutorials[12], tutorials[13], tutorials[15], tutorials[17], tutorials[22], tutorials[25], tutorials[26], tutorials[28], tutorials[29], tutorials[39]]
    const social = [tutorials[8], tutorials[34]]
    const os = [tutorials[18], tutorials[21], tutorials[23], tutorials[37]]
    const mail = [tutorials[35]]
    const cms = [tutorials[38]]

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash && refs[hash] && refs[hash].current) {
            refs[hash].current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location, refs]);

    return (
        <div id='blog' ref={refs['blog']}>
            <h1 className='support'>JPEG XL SUPPORT</h1>
            <p className='subheading'>Articles and Tutorials</p>
            <div className='search'>
                <input type="text" placeholder="🔎︎ Search all posts" onChange={handleSearchChange}/>
                <div className='options'>
                    <div className={`option ${filter === 'articles' ? 'articles-selected' : ''}`}
                         onClick={() => handleFilterClick('articles')}>articles
                    </div>
                    <div className={`option ${filter === 'tutorials' ? 'tutorials-selected' : ''}`}
                         onClick={() => handleFilterClick('tutorials')}>tutorials
                    </div>
                </div>
                <div className='options'>
                    <div className={`option ${filter === 'browser' ? 'browser-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('browser')}>browser
                    </div>
                    <div className={`option ${filter === 'cdn' ? 'cdn-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('cdn')}>cdn
                    </div>
                    <div className={`option ${filter === 'coding' ? 'coding-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('coding')}>coding
                    </div>
                    <div className={`option ${filter === 'graphics' ? 'graphics-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('graphics')}>graphics
                    </div>
                    <div className={`option ${filter === 'social' ? 'social-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('social')}>social
                    </div>
                    <div className={`option ${filter === 'os' ? 'os-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('os')}>os
                    </div>
                    <div className={`option ${filter === 'mail' ? 'mail-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('mail')}>mail
                    </div>
                    <div className={`option ${filter === 'cms' ? 'cms-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('cms')}>cms
                    </div>
                </div>
                <div className='options'>
                    <div className={`option ${filter === 'partial' ? 'partial-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('partial')}>partial support
                    </div>
                    <div className={`option ${filter === 'full' ? 'full-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('full')}>full support
                    </div>
                    <div className={`option ${filter === 'no' ? 'no-selected' : ''}`}
                         onClick={() => handleCustomFilterClick('no')}>no support
                    </div>
                </div>
                {filter === 'all' && !isCustomFilterActive && (<>
                    {filteredArticles.length > 0 && (<>
                        <p className='search_heading'>Articles</p>
                        <div className='search_container'>
                            {filteredArticles.map((article, index) => (
                                <Article href={article.href} key={index} name={article.name}
                                         text={article.text}/>))}
                        </div>
                    </>)}
                    {filteredTutorials.length > 0 && (<>
                        <p className='search_heading' id='tutorials' ref={refs['tutorials']}>Tutorials</p>
                        <div className='search_container'>
                            {filteredTutorials.map((tutorial, index) => (
                                <Tutorials key={index} href={tutorial.href} name={tutorial.name} side={tutorial.side}
                                           text={tutorial.text}/>))}
                        </div>
                    </>)}
                </>)}
                {filter === 'articles' && !isCustomFilterActive && filteredArticles.length > 0 && (<>
                    <p className='search_heading'>Articles</p>
                    <div className='search_container'>
                        {filteredArticles.map((article, index) => (
                            <Article key={index} href={article.href} name={article.name} text={article.text}/>))}
                    </div>
                </>)}
                {filter === 'tutorials' && !isCustomFilterActive && filteredTutorials.length > 0 && (<>
                    <p className='search_heading'>Tutorials</p>
                    <div className='search_container'>
                        {filteredTutorials.map((tutorial, index) => (
                            <Tutorials key={index} href={tutorial.href} name={tutorial.name} side={tutorial.side}
                                       text={tutorial.text}/>))}
                    </div>
                </>)}
                {isCustomFilterActive && (<>
                    <br/>
                    <div className='search_container'>
                        {array.map((tutorial, index) => (
                            <Tutorials key={index} href={tutorial.href} name={tutorial.name} side={tutorial.side}
                                       text={tutorial.text}/>))}
                    </div>
                </>)}
                <br/>
                <span className='line'/>
                <br/>
            </div>
        </div>
    )
}

export default Support;