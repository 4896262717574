import './Article.css'

function Article(props) {
    return (
        <a href={props.href} className='article'>
            <h2>{props.name}</h2>
            <p>{props.text}</p>
        </a>
    )
}

export default Article