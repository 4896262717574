import './Advantage.css'
import Atropos from "atropos/react";
import 'atropos/css'

function Advantage(props) {
    const styles = {
        backgroundImage: `url('${props.image}')`
    };

    return (
        <Atropos shadow={false} className="my-atropos">
            <div className="advantage" data-atropos-offset="-5">
                <div className="advantage_background" style={styles}></div>
                <div className="advantage_overlay"></div>
                <div className="advantage_content">
                    <Atropos data-atropos-offset="10" shadow={false} highlight={false}>
                        <img className="advantage_image" src={props.image} alt="Advantage"/>
                    </Atropos>
                    <p>{props.text}</p>
                </div>
            </div>
        </Atropos>
    );
}

export default Advantage;
