import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Windows(){
    const related = [
        {
            href: '/kaos',
            name: 'KaOS',
            side: 'full support',
            text: 'KaOS is a community Linux distribution. Learn how to use JXL images within KaOS.'
        },
        {
            href: '/macos',
            name: 'macOS',
            side: 'partial support',
            text: 'macOS does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
        {
            href: '/openmandriva',
            name: 'OpenMandriva',
            side: 'full support',
            text: 'OpenMandriva is a community Linux distribution. Learn how to use JXL images within OpenMandriva.'
        },
        {
            href: '/windows',
            name: 'Windows',
            side: 'partial support',
            text: 'Windows does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
    ]
    return(
        <>
            <Heading h='Windows JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='2' a='#windows-jxl-support' text='Windows JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>The JPEG XL Image Coding System (ISO/IEC 18181) is particularly well suited to responsive web environments so that that content can be viewed effectively across a wide variety of devices. Further, it includes several features that facilitate the transition from the legacy JPEG format. The JPEG XL format supports both lossless and lossy compression, and it is a royalty-free raster graphics file format. It is designed to surpass existing raster formats and thus to replace them universally.</p>
                    <p className='umain'>JPEG XL provides the following key features:</p>
                    <p className='umain'>&#10004; High-fidelity lossy image compression.</p>
                    <p className='umain'>&#10004; Best lossless image compression.</p>
                    <p className='umain'>&#10004; Progressive decoding.</p>
                    <p className='umain'>&#10004; Lossless JPEG transcoding.</p>
                    <p className='umain'>&#10004; Designed for both photographic and synthetic images.</p>
                    <p className='umain'>&#10004; Fast software encoding and decoding.</p>
                    <p className='umain'>&#10004; Full support for wide gamut and HDR.</p>
                    <p className='umain'>&#10004; Perceptually optimizing reference encoder.</p>
                    <p className='umain'>&#10004; FOSS and royalty-free.</p>
                    <p className='umain'>Thanks to&nbsp;<span className='green'>jpegxl.info</span>&nbsp;for the great summary!</p>
                </span>
                <Line/>

                <span id='windows-jxl-support'>
                    <h1 className="main">Windows JXL support</h1>
                    <p className='umain'>There are several ways to enable support JPEG XL on Windows. Unfortunately, to this date, Windows offers no native support. You can either download plugins created by developers or use an external image viewer.</p>
                    <p className='umain'><b>Windows Thumbnails</b></p>
                    <p className='umain'>With the JXL-Winthumb plugin for Windows File Explorer, you can display thumbnail images for JPEG XL (*.jxl) files.</p>
                    <p className='umain'>&#10004; Install Microsoft Visual C++ Redistributable for Visual Studio 2019 if it's not already installed.</p>
                    <p className='umain'>&#10004; Download the latest dll file from the releases page.</p>
                    <p className='umain'>&#10004; Open a terminal window as an administrator.</p>
                    <p className='umain'>&#10004; Move to your directory where the DLL file is located.</p>
                    <p className='umain'>&#10004; Type regsvr32 jxl_winthumb.dll and press Enter.</p>
                    <p className='umain'><b>Windows Thumbnails</b></p>
                    <p className='umain'>Besides these plugins and tricks, you can also use an external image viewer that support JXL files, such as:</p>
                    <a className='ugreen' href='/nomacs'>nomacs</a>
                    <a className='ugreen' href='/photoqt'>PhotoQT</a>
                    <a className='ugreen' href='/xnview'>XnView MP</a>
                    <a className='ugreen' href='/imageglass'>ImageGlass</a>
                    <br/>
                    <br/>
                    <p className='umain'><b>JPEGXL WIC</b></p>
                    <p className='umain'>This approach is not for a regular end-user since it provides no binary to open and install.</p>
                    <p className='umain'>JPEG XL WIC provides an implementation of Windows Imaging Components (WIC) architecture for the JPEG XL image format. It enables Windows to recognize the JPEG XL file format and use the JPEG XL codec directly when processing images. Through this technology, viewing pictures is as simple as using Windows Explorer, Microsoft Photo Viewer, or Microsoft Photos. The JPEG XL WIC, as well as the JPEG XL codec, are still being developed. The goal is to utilize the JPEG XL codec in Windows, focusing on decoding HDR/WCG images.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Windows