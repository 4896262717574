import './Home.css'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "../Components/Header/Header";
import ConvertPage from "../Components/ConvertPage/ConvertPage";
import Advantages from "../Components/Advantages/Advantages"
import Support from "../Components/JpegXLSupport/Support";
import Summary from "../Components/Summary/Summary";
import Footer from "../Components/Footer/Footer";
import Example from "../OtherPages/examples";
import Overview from "../OtherPages/Articles/Overview";
import FAQ from "../OtherPages/Articles/FAQ";
import SEO from "../OtherPages/Articles/SEO";
import SiteMap from "../OtherPages/Articles/SiteMap";
import Solution from "../OtherPages/Articles/Solution";
import ANS from "../OtherPages/Articles/ANS";
import Brave from "../OtherPages/Tutorials/Brave";
import Chrome from "../OtherPages/Tutorials/Chrome";
import Edge from "../OtherPages/Tutorials/Edge";
import Firefox from "../OtherPages/Tutorials/Firefox";
import Opera from "../OtherPages/Tutorials/Opera";
import Safari from "../OtherPages/Tutorials/Safari";
import Vivaldi from "../OtherPages/Tutorials/Vivaldi";
import Cloudinary from "../OtherPages/Tutorials/Cloudinary";
import ShimmerCat from "../OtherPages/Tutorials/ShimmerCat";
import CSS from "../OtherPages/Tutorials/CSS";
import ExifTool from "../OtherPages/Tutorials/ExifTool";
import Exiv2 from "../OtherPages/Tutorials/Exiv2";
import HTML from "../OtherPages/Tutorials/HTML";
import ImageMagick from "../OtherPages/Tutorials/ImageMagick";
import Libvips from "../OtherPages/Tutorials/libvips";
import PHP from "../OtherPages/Tutorials/PHP";
import Rust from "../OtherPages/Tutorials/Rust";
import Squoosh from "../OtherPages/Tutorials/squoosh";
import DigiKam from "../OtherPages/Tutorials/digiKam";
import GIMP from "../OtherPages/Tutorials/GIMP";
import Geeqie from "../OtherPages/Tutorials/Geeqie";
import GThumb from "../OtherPages/Tutorials/gThumb";
import Gwenview from "../OtherPages/Tutorials/Gwenview";
import ImageGlass from "../OtherPages/Tutorials/ImageGlass";
import IrfanView from "../OtherPages/Tutorials/IrfanView";
import Nomacs from "../OtherPages/Tutorials/nomacs";
import PhotoQt from "../OtherPages/Tutorials/PhotoQt";
import PhotoStack from "../OtherPages/Tutorials/PhotoStack";
import PicView from "../OtherPages/Tutorials/PicView";
import Qimgv from "../OtherPages/Tutorials/qimgv";
import XnView from "../OtherPages/Tutorials/XnView";
import Facebook from "../OtherPages/Tutorials/Facebook";
import TheLounge from "../OtherPages/Tutorials/TheLounge";
import KaOS from "../OtherPages/Tutorials/KaOS";
import MacOS from "../OtherPages/Tutorials/macOS";
import OpenMandriva from "../OtherPages/Tutorials/OpenMandriva";
import Windows from "../OtherPages/Tutorials/Windows";
import Thunderbird from "../OtherPages/Tutorials/Thunderbird";
import Wordpress from "../OtherPages/Tutorials/Wordpress";
import KDEFrameworks from "../OtherPages/Tutorials/KDEFrameworks";
import FAQComponent from "../OtherPages/Components/FAQComponent/FAQComponent";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";

function Home() {
    return (
        <div id='home'>
            <Router>
                <Header/>
                <Routes>
                    <Route path='/' element=
                        {
                            <>
                                <div className='gradient'/>
                                <ConvertPage/>
                                <Advantages/>
                                <Support/>
                                <Summary/>
                            </>
                        }/>
                    <Route path='/support-overview' element={<Overview/>}/>
                    <Route path='/faq' element={<FAQ/>}/>
                    <Route path='/seo' element={<SEO/>}/>
                    <Route path='/sitemap' element={<SiteMap/>}/>
                    <Route path='/solution' element={<Solution/>}/>
                    <Route path='/patent' element={<ANS/>}/>

                    <Route path='/brave' element={<Brave/>}/>
                    <Route path='/chrome' element={<Chrome/>}/>
                    <Route path='/edge' element={<Edge/>}/>
                    <Route path='/firefox' element={<Firefox/>}/>
                    <Route path='/opera' element={<Opera/>}/>
                    <Route path='/safari' element={<Safari/>}/>
                    <Route path='/vivaldi' element={<Vivaldi/>}/>

                    <Route path='/cloudinary' element={<Cloudinary/>}/>
                    <Route path='/shimmercat' element={<ShimmerCat/>}/>\

                    <Route path='/css' element={<CSS/>}/>
                    <Route path='/exiftool' element={<ExifTool/>}/>
                    <Route path='/exiv2' element={<Exiv2/>}/>
                    <Route path='/html' element={<HTML/>}/>
                    <Route path='/imagemagick' element={<ImageMagick/>}/>
                    <Route path='/kde-frameworks' element={<KDEFrameworks/>}/>
                    <Route path='/libvips' element={<Libvips/>}/>
                    <Route path='/php' element={<PHP/>}/>
                    <Route path='/rust' element={<Rust/>}/>
                    <Route path='/squoosh' element={<Squoosh/>}/>

                    <Route path='/digikam' element={<DigiKam/>}/>
                    <Route path='/geeqie' element={<Geeqie/>}/>
                    <Route path='/gimp' element={<GIMP/>}/>
                    <Route path='/gthumb' element={<GThumb/>}/>
                    <Route path='/gwenview' element={<Gwenview/>}/>
                    <Route path='/imageglass' element={<ImageGlass/>}/>
                    <Route path='/irfanview' element={<IrfanView/>}/>
                    <Route path='/nomacs' element={<Nomacs/>}/>
                    <Route path='/photoqt' element={<PhotoQt/>}/>
                    <Route path='/photostack' element={<PhotoStack/>}/>
                    <Route path='/picview' element={<PicView/>}/>
                    <Route path='/qimgv' element={<Qimgv/>}/>
                    <Route path='/xnview' element={<XnView/>}/>

                    <Route path='/facebook' element={<Facebook/>}/>
                    <Route path='/the-lounge' element={<TheLounge/>}/>

                    <Route path='/kaos' element={<KaOS/>}/>
                    <Route path='/macos' element={<MacOS/>}/>
                    <Route path='/openmandriva' element={<OpenMandriva/>}/>
                    <Route path='/windows' element={<Windows/>}/>

                    <Route path='/thunderbird' element={<Thunderbird/>}/>
                    <Route path='/wordpress' element={<Wordpress/>}/>

                    <Route path='/sitemapping' element={
                        <div className='sitemapping'>
                            <a href='/'>Home</a>
                            <a href='/support-overview'>JPEG XL - Browser Support Overview</a>
                            <a href='/faq'>JPEG XL FAQ: Everything You Need to Know!</a>
                            <a href='/seo'>Optimize your images for SEO</a>
                            <a href='/sitemap'>Create image sitemaps</a>
                            <a href='/solution'>JPEG XL - Why the new image format is a comprehensive solution.</a>
                            <a href='/patent'>A patent has been granted to Microsoft for ANS</a>
                            <br/>
                            <a href='/brave'>Brave JPEG XL support</a>
                            <a href='/chrome'>Chrome JPEG XL support</a>
                            <a href='/edge'>Edge JPEG XL support</a>
                            <a href='/firefox'>Firefox JPEG XL support</a>
                            <a href='/opera'>Opera JPEG XL support</a>
                            <a href='/safari'>Safari JPEG XL support</a>
                            <a href='/vivaldi'>Vivaldi JPEG XL support</a>
                            <br/>
                            <a href='/cloudinary'>Cloudinary JPEG XL support</a>
                            <a href='/shimmercat'>ShimmerCat AVIF support</a>
                            <br/>
                            <a href='/css'>CSS JPEG XL support</a>
                            <a href='/exiftool'>ExifTool JPEG XL support</a>
                            <a href='/exiv2'>Exiv2 JPEG XL support</a>
                            <a href='/html'>HTML JPEG XL support</a>
                            <a href='/imagemagick'>ImageMagick JPEG XL support</a>
                            <a href='/kde-frameworks'>KDE Frameworks JPEG XL support</a>
                            <a href='/libvips'>libvips JPEG XL support</a>
                            <a href='/php'>PHP JPEG XL support</a>
                            <a href='/rust'>Rust JPEG XL support</a>
                            <a href='/squoosh'>Squoosh JPEG XL support</a>
                            <br/>
                            <a href='/digikam'>digiKam JPEG XL support</a>
                            <a href='/geeqie'>Geeqie JPEG XL support</a>
                            <a href='/gimp'>GIMP JPEG XL support</a>
                            <a href='/gthumb'>gThumb JPEG XL support</a>
                            <a href='/gwenview'>Gwenview JPEG XL support</a>
                            <a href='/imageglass'>ImageGlass JPEG XL support</a>
                            <a href='/irfanview'>IrfanView JPEG XL support</a>
                            <a href='/nomacs'>nomacs JPEG XL support</a>
                            <a href='/photoqt'>PhotoQt JPEG XL support</a>
                            <a href='/photostack'>PhotoStack JPEG XL support</a>
                            <a href='/picview'>PicView JPEG XL support</a>
                            <a href='/qimgv'>qimgv JPEG XL support</a>
                            <a href='/xnview'>XnView JPEG XL support</a>
                            <br/>
                            <a href='/facebook'>Facebook JPEG XL support</a>
                            <a href='/the-lounge'>The Lounge JPEG XL support</a>
                            <br/>
                            <a href='/kaos'>KaOS JPEG XL support</a>
                            <a href='/macos'>macOS JPEG XL support</a>
                            <a href='/openmandriva'>OpenMandriva JPEG XL support</a>
                            <a href='/windows'>Windows JPEG XL support</a>
                            <br/>
                            <a href='/thunderbird'>Thunderbird JPEG XL support</a>
                            <br/>
                            <a href='/wordpress'>Wordpress JPEG XL support</a>
                        </div>
                    }/>
                </Routes>
                <Footer/>
                <ScrollToTop />
            </Router>
        </div>
    )
}

export default Home;