import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Nomacs(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='nomacs JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-nomacs' text='What is nomacs?'/>
                        <LiContent n='2' a='#nomacs-jxl-support' text='nomacs JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-nomacs'>
                    <h1 className="main">What is nomacs?</h1>
                    <p className='umain'>Nomacs is a free and open-source image viewer available for many platforms. It supports all standard image formats, including RAW and PSD formats. One of the most notable features of Nomacs is the semi-transparent widgets that display additional information, including thumbnails, metadata, and histograms. Photos in zip files or MS Office files can be viewed through the program. Metadata associated with the image can be displayed, as well as notes attached to it. Furthermore, a thumbnail preview of the current folder is displayed and a panel for switching between folders. An image directory can be filtered so that only images whose filenames contain a specific string or match a regular expression are displayed. Caching allows you to switch between images at any time. Nomacs is a program that allows users to adjust the brightness, contrast, saturation, hue, gamma, and exposure of images. A pseudo-color function is provided in the program to produce false-color images. One of the unique aspects of Nomacs is its ability to synchronize multiple instances. By utilizing this feature, you can easily compare two images by zooming or panning at the same position or overlaying them with different opacities.</p>
                </span>
                <Line/>

                <span id='nomacs-jxl-support'>
                    <h1 className="main">nomacs JXL support</h1>
                    <p className='umain'>Daniel Novomeský is a workhorse! Once again, he created a plugin to add JPEG XL support to software. This time, he presents us with a plugin for nomacs, and it is fairly easy to install:</p>
                    <p className='umain'>&#10004; Download qjpegxl.dll (it is a Qt plugin).</p>
                    <p className='umain'>&#10004; Copy the qjpegxl.dll into C:/Program Files/nomacs/binimageformats.</p>
                    <p className='umain'>&#10004; Enjoy using Nomacs to view JXL files like any other image.</p>
                    <p className='umain'>The extension is open-source and available on Github to view the source code or report issues.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Nomacs