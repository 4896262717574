import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import jxl from "../../images/frog.jxl";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import firefox from '../../images/firefox.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Firefox(){
    const related = [
        {
            href: '/brave',
            name: 'Brave',
            side: 'partial support',
            text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/chrome',
            name: 'Chrome',
            side: 'partial support',
            text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/edge',
            name: 'Edge',
            side: 'partial support',
            text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
        },
        {
            href: '/firefox',
            name: 'Firefox',
            side: 'partial support',
            text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
        },
        {
            href: '/opera',
            name: 'Opera',
            side: 'partial support',
            text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
        },
        {
            href: '/safari',
            name: 'Safari',
            side: 'no support',
            text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
        },
        {
            href: '/vivaldi',
            name: 'Vivaldi',
            side: 'partial support',
            text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
        }
    ]
    return (
        <>
            <Heading h='Firefox JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#what-is-firefox' text='What is Firefox?'/>
                        <LiContent n='3' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='4' a='#firefox-jpegxl-tutorial' text='Firefox JPEG XL Tutorial'/>
                    </ol>
                </nav>
                <br/>
                <Line/>
                <a className='ugreen' href='#'></a>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>Those who are interested will find an introduction explaining Firefox and JPEG XL below for SEO purposes. You can jump to the tutorial
                        on <a className='ugreen' href='#firefox-jpegxl-tutorial'>how to enable JPEG XL support by clicking here.</a></p>
                </span>
                <Line/>

                <span id='what-is-firefox'>
                    <h1 className="main">What is Firefox?</h1>
                    <p className='umain'>More than 500 million people worldwide use Firefox as their primary web browser for surfing and interacting with the Internet. In addition to being available on Linux, Mac, Windows, and handheld devices, Firefox is also available in more than 70 languages. Firefox places a strong emphasis on protecting your online privacy and security and helps to protect your personal information and activity on the Internet from being viewed or exploited. The browser is widely regarded the most customizable on the market. You can customize Firefox to look and function as you wish by choosing from a selection of over 8,000 free add-ons on the Firefox Add-ons site.</p>
                </span>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>Developed by the Joint Photographic Experts Group, Google, and Cloudinary, JPEG XL is a royalty-free, ISO-standardized image format and free software implementation that aims to be the best image format in all respects. Many features are offered, including lossless compression, lossy compression, transparency, animation, huge resolutions (up to one million by one million pixels), progressive decoding, and HDR (up to 32-bits of color per channel). The software also comes with a unique feature: lossless JPEG recompression, where it can convert a JPG to a JXL to losslessly shrink it by about 20% and then recover the original JPG from it if you wish. It is superior to JPG, JPEG 2000, JPEG XR, and WEBP in terms of lossy quality, but HEIF and AVIF images defeat it at meager bit rates. When it comes to lossless compression, it is superior to anything else available. As an animation codec, it outperforms GIF, MNG, and APNG on reduction. However, it is bested by video codecs, as it is designed to be used as an image codec.</p>
                </span>
                <Line/>

                <span id='firefox-jpegxl-tutorial'>
                    <h1 className="main">Firefox JPEG XL Tutorial</h1>
                    <p className='umain'>Firefox enabled experimental support for JPEG XL behind a flag since version 90.0a1, released on the 13th of July 2021. However, current experimental support is only available on the Nightly version of the browser. In conclusion, even though you could enable JXL as an experimental feature in the stable release version of Firefox, images will still not be displayed.</p>
                    <p className='umain'>To support JXL images, you have to do the following:</p>
                    <p className='umain'>&#10004; Download the Nightly version of Firefox.</p>
                    <p className='umain'>&#10004; Enter about:config inside the URL bar.</p>
                    <p className='umain'>&#10004; Confirm if any warning appears.</p>
                    <p className='umain'>&#10004; Type jxl in the search bar.</p>
                    <p className='umain'>&#10004; Look for the setting image.jxl.enabled.</p>
                    <p className='umain'>&#10004; Set it to true by clicking the button on the right.</p>
                    <img src={firefox} className='code5'/>
                    <p className='umain'>Then, restart your browser and come back to see this awesome frog image:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <br/><br/>
                    <p className='umain'><b>Congratulations!</b></p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Firefox;