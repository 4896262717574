import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Squoosh(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return (
        <>
            <Heading h='Squoosh JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-squoosh' text='What is Squoosh?'/>
                        <LiContent n='2' a='#squoosh-jxl-support' text='Squoosh JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-squoosh'>
                    <h1 className="main">What is Squoosh?</h1>
                    <p className='umain'>Squoosh is a product developed by Google. Using Squoosh, you can reduce the size of images in a variety of formats. Using Squoosh, you can compress many pictures at once through an API and a CLI. Images are not sent to the server. All compression is performed locally.</p>
                    <p className='umain'>In comparison to jpegxl.io, we offer the ability to convert in bulk and additional conversion options in an elegant user interface. Squoosh provides a user-friendly interface for comparing input and output immediately. Moreover, we use Squoosh for comparisons of quality and jpegxl.io for batch conversions.</p>
                </span>
                <Line/>

                <span id='squoosh-jxl-support'>
                    <h1 className="main">Squoosh JXL support</h1>
                    <p className='umain'>Squoosh.app supports JPEG XL. However, support is still in beta. From our tests, we didn't experience any crashes or uncommon behavior.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Squoosh