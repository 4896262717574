import React from "react";
import LiContent from "../Components/LiContent/LiContent";
import Heading from "../Components/Heading/Heading";
import Line from "../Components/Line/Line";
import ConvertButton from "../Components/ConvertButton";
import RelatedT from "../Components/RelatedTutorials/Related";
import jxl from '../../images/frog.jxl'
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Overview() {
    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];
    return (
        <>
            <Heading h='JPEG XL - Browser Support Overview' p='#articles'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#jpeg-xl' text='JPEG XL'/>
                        <LiContent n='2' a='#browser-support' text='Browser Support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>
                <span id='jpeg-xl'>
                    <h1 className='main'>JPEG XL</h1>
                    <p className='umain'>
                        An improved version of JPEG lies within JPEG XL, which is a next-generation format. The JPEG XL format resembles a JPEG but is the successor to the legacy JPEG format. According to the JPEG Committee, it provides significantly better image quality and better compression ratios than legacy JPEG. A significant advantage of this format is that it is free, offers suitable encoding and decoding speeds, and allows for lossless transcoding of JPEGs at small file sizes.
                    </p>
            </span>
                <br/>
                <Line/>
                <span id='browser-support'>
                    <h1 className='main'>Browser Support</h1>
                    <p className='umain'>In recent months, browser vendors have been implementing support for JPEG XL in their browsers. The image displayed below this paragraph indicates your browser supports the new image format.</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <p className='umain'>Don't worry if you don't see the JPEG XL image! This necessarily does not mean that support is unavailable, only that it may not be enabled by default. If you want to allow support, make sure your browser is up-to-date and follow these guides:</p>
                <p className='umain'>&#10004;&nbsp;<a href='/chrome' className='ugreen'>Google Chrome</a> </p>
                <p className='umain'>&#10004;&nbsp;<a href='/firefox' className='ugreen'>Mozilla Firefox</a></p>
                <p className='umain'>&#10004;&nbsp;<a href='/edge' className='ugreen'>Microsoft Edge</a></p>
                <p className='umain'>&#10004;&nbsp;<a href='/brave' className='ugreen'>Brave</a></p>
                <p className='umain'>&#10004;&nbsp;<a href='/vivaldi' className='ugreen'>Vivaldi</a></p>
                <p className='umain'>&#10004;&nbsp;<a href='/safari' className='ugreen'>Safari (no support yet)</a></p>
            </span>
                <FAQComponent/>

                <br/>
                <RelatedT related={articles}/>
                <br/>
                <br/>
                <Line/><br/>
                <br/>
            </article>
            <ConvertButton/>
            <br/>
        </>)
}

export default Overview