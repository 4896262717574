import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import chrome from '../../images/chrome.png'
import chrome2 from '../../images/chrome2.png'
import RelatedT from "../Components/RelatedArticles/Related";
import jxl from "../../images/frog.jxl";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Chrome() {
    const related = [
        {
            href: '/brave',
            name: 'Brave',
            side: 'partial support',
            text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/chrome',
            name: 'Chrome',
            side: 'partial support',
            text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/edge',
            name: 'Edge',
            side: 'partial support',
            text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
        },
        {
            href: '/firefox',
            name: 'Firefox',
            side: 'partial support',
            text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
        },
        {
            href: '/opera',
            name: 'Opera',
            side: 'partial support',
            text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
        },
        {
            href: '/safari',
            name: 'Safari',
            side: 'no support',
            text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
        },
        {
            href: '/vivaldi',
            name: 'Vivaldi',
            side: 'partial support',
            text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
        }
    ]
    return (
        <>
            <Heading h='Chrome JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='3' a='#what-is-chrome' text='What is Chrome?'/>
                        <LiContent n='4' a='#check-chrome-version' text='Check your Chrome Version'/>
                        <LiContent n='5' a='#chrome-flags' text='What are Chrome Flags?'/>
                        <LiContent n='6' a='#chrome-jpeg-xl-tutorial' text='Chrome JPEG XL Tutorial'/>
                        <LiContent n='7' a='#chrome-jpeg-xl-test-image' text='Chrome JPEG XL Test Image'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>Those who are interested will find an introduction explaining Chrome and JPEG XL below for SEO purposes. You can jump to the tutorial on<a
                        className='ugreen' href='#chrome-jpeg-xl-tutorial'>how to enable JPEG XL support on Chrome and Chrome for Android by clicking here.</a></p>
                </span>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>As a professional image delivery format, the JPEG XL image format meets the needs of web photographers and web designers alike. As well as supporting wide color gamut and high dynamic range, it also supports high bit depth images. Additional features of JPEG XL include animation, alpha channels, layers, thumbnails, lossless, and progressive decoding.</p>
                    <p className='umain'>JPEG XL provides a significant improvement in image quality and compression ratio compared to legacy JPEG. You can quickly transfer legacy JPEGs to the new format due to the implementation of lossless JPEG recompression.</p>
                    <p className='umain'>JPEG XL allows for lossless compression and lossy compression. Typical compression allows between 20-60% reduction in file size. Using software implementations, it is possible to encode and decode in a computationally efficient manner without requiring any hardware acceleration.</p>
                </span>
                <Line/>

                <span id='what-is-chrome'>
                    <h1 className="main">What is Chrome?</h1>
                    <p className='umain'>Google develops and maintains its closed-source web browser called Google Chrome. Google Chrome is an excellent web browser that makes it easy to access the web from mobile devices and desktop computers because of its ease of use and flexibility. Chrome is known for its superior speed and performance compared to other browsers such as Internet Explorer, Microsoft Edge, or Firefox. In 2008, Google introduced Chrome for Windows. After that, Chrome has become available on macOS, Linux, iOS, and Android. Chrome's fans enjoy its extensive collection of extensions, its excellent performance, and its customization capabilities. Nonetheless, Chrome has received some criticism for its high memory usage and its history of tracking user data. Statcounter reports that Chrome occupies 63.5% of the global market share for web browsers. Despite Chrome's all-time high adoption rate of 72% in 2018, Google still holds a significant monopoly on the browser market.</p>
                </span>
                <Line/>

                <span id='check-chrome-version'>
                    <h1 className="main">Check your Chrome Version</h1>
                    <p className='umain'>Version 91 of Google Chrome already supports JPEG XL experimentally on Mac, Windows, Linux, ChromeOS and Android. To follow this tutorial, you must have installed at least version 91. To check and update your version, follow these steps:</p>
                    <p className='umain'>&#10004; Navigate to the Chrome menu.</p>
                    <p className='umain'>&#10004; Select Help and then About Chrome.</p>
                    <p className='umain'>&#10004; Review the version number. Chrome will automatically update itself if you do not have the latest version.</p>
                    <p className='umain'>&#10004; Restart Chrome after updating it.</p>
                    <img src={chrome} className='code5'/>
                </span>
                <Line/>

                <span id='chrome-flags'>
                    <h1 className="main">What are Chrome Flags?</h1>
                    <p className='umain'>Since version 91, JPEG XL is available behind a flag, which means the functionality is not enabled by default. The term "flag" refers to features in development for Chrome. They are often found in a hidden settings menu that you can access through the navigation bar. Before reading the following instructions for enabling JPEG XL, we remind you that Google considers flags unstable. There is no information as to when Chrome will support JPEG XL by default. Nevertheless, it is safe to say that your browser or computer will unlikely be adversely affected once image format support is activated.</p>
                </span>
                <Line/>

                <span id='chrome-jpeg-xl-tutorial'>
                    <h1 className="main">Chrome JPEG XL Tutorial</h1>
                    <p className='umain'>&#10004; Ensure you have at least Chrome version 91 installed.</p>
                    <p className='umain'>&#10004; Open a new browser tab.</p>
                    <p className='umain'>&#10004; Type the following: chrome://flags/#enable-jxl</p>
                    <p className='umain'>&#10004; The first and marked result shows an entry called Enable JXL image format with the description Adds image decoding support for the JPEG XL image format. Mac, Windows, Linux, Chrome OS, Android and the hashtag #enable-jxl.</p>
                    <p className='umain'>&#10004; On the right, click the drop-down menu and select Enable to enable the support. After completing the process, you will be prompted to restart your web browser for the changes to take effect.</p>
                    <img src={chrome2} className='code5'/>
                </span>
                <Line/>

                <span id='chrome-jpeg-xl-test-image'>
                    <h1 className="main">Chrome JPEG XL Test Image</h1>
                    <p className='umain'>Upon re-launching the browser, return to this website to see this incredible frog image:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <br/>
                    <br/>
                    <p className='umain'><b>Congratulations!</b></p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Chrome