import React from "react";
import Article from "../../../Components/JpegXLSupport/Article/Article";

function RelatedA(props) {
    return(
        <>
            <p className='search_heading'>Related Articles</p>
            <div className='related_articles'>
                {props.related.map((tutorial, index) => (
                    <Article key={index} href={tutorial.href} name={tutorial.name} side={tutorial.side}
                               text={tutorial.text}/>
                ))}
            </div>
        </>
    )
}

export default RelatedA