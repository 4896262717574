import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import jxl from "../../images/frog.jxl";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Opera(){
    const related = [
        {
            href: '/brave',
            name: 'Brave',
            side: 'partial support',
            text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/chrome',
            name: 'Chrome',
            side: 'partial support',
            text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/edge',
            name: 'Edge',
            side: 'partial support',
            text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
        },
        {
            href: '/firefox',
            name: 'Firefox',
            side: 'partial support',
            text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
        },
        {
            href: '/opera',
            name: 'Opera',
            side: 'partial support',
            text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
        },
        {
            href: '/safari',
            name: 'Safari',
            side: 'no support',
            text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
        },
        {
            href: '/vivaldi',
            name: 'Vivaldi',
            side: 'partial support',
            text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
        }
    ]
    return (
        <>
            <Heading h='Opera JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-opera' text='What is Opera?'/>
                        <LiContent n='2' a='#opera-jxl-support' text='Opera JXL support'/>
                        <LiContent n='3' a='#opera-jpeg-xl-test-image' text='Opera JPEG XL Test Image'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-opera'>
                    <h1 className="main">What is Opera?</h1>
                    <p className='umain'>Opera is a free web browser that is available for both computers and smartphones. Mobile apps are available for iOS and Android and the Opera browser on Windows, macOS, and Linux. Both Opera Touch, which is designed for smartphones, and Opera Mini, which is faster and more data-efficient, are available for both Android and iOS. A Norwegian telecommunications company developed the Opera web browser in 1995 as part of a research project. Since then, the browser has undergone numerous updates to keep up with technological advancements and respond to new developments. Every two weeks, Opera introduces new features and updates. One of the best features of Opera is its battery-saving capabilities. Opera reports that its battery saver can increase battery life by as much as 35% compared to other browsers, including Google Chrome and Microsoft Edge. According to the type and specifications of your laptop, this may result in an additional hour of battery life.</p>
                    <p className='umain'>To conclude, Opera is a highly modern web browser featuring numerous features such as built-in messenger, crypto wallet support, battery saving features, enhanced privacy and security, and an ad blocker.</p>
                </span>
                <Line/>

                <span id='opera-jxl-support'>
                    <h1 className="main">Opera JXL support</h1>
                    <p className='umain'>On the 15th of June in 2021, with version 77, Opera has enabled JPEG XL support behind a flag. Support is available for Mac, Windows, Linux, Chrome OS, and Android.</p>
                    <p className='umain'>You can easily enable support by doing the following:</p>
                    <p className='umain'>&#10004; Browse to opera://flags/#enable-jxl.</p>
                    <p className='umain'>&#10004; Enable first marked entry with the title Enable JXL image format.</p>
                    <p className='umain'>&#10004; Restart your browser.</p>
                </span>
                <Line/>

                <span id='opera-jpeg-xl-test-image'>
                    <h1 className="main">Opera JPEG XL Test Image</h1>
                    <p className='umain'>Upon re-launching the browser, return to this website to see this incredible frog image:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <br/>
                    <br/>
                    <p className='umain'><b>Congratulations!</b></p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Opera;