import './WhatIs.css'
import React, {useState} from "react";

function WhatIs(props) {
    const [state, setState] = useState(false);


    return (
        <>
            <span className='what_is' onMouseOver={()=>setState(true)} onMouseLeave={()=>setState(false)}>?
                {state && (<div className='what_is_hovered'>{props.text}</div>)}</span>

        </>
    )
}

export default WhatIs;