import React from "react";
import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import html from "../../images/html.png";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function HTML(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    const code1 = `<picture>
  <source srcSet="image.jxl" type="image/jxl" />
  <source srcSet="image.avif" type="image/avif" />
  <source srcSet="image.webp" type="image/webp" />
  <img
  width="1280" height="720" decoding="async" loading="lazy"
  src="image.jpg" alt="hopefully an jxl image" />
</picture>`
    const code2 = `<picture>
   <source
      sizes="(max-width: 640px) 100vw, 640px"
      srcSet="/image-1280.jxl 1280w, /image-640.jxl 640w, /image-320.jxl 320w"
      type="image/jxl" />
   <source
      sizes="(max-width: 640px) 100vw, 640px"
      srcSet="/image-1280.avif 1280w, /image-640.avif 640w, /image-320.avif 320w"
      type="image/avif" />
   <source
      sizes="(max-width: 640px) 100vw, 640px"
      srcSet="/image-1280.avif 1280w, /image-640.webp 640w, /image-320.webp 320w"
      type="image/webp" />
   <source
      sizes="(max-width: 640px) 100vw, 640px"
      srcSet="/image-1280.avif 1280w, /image-640.jpg 640w, /image-320.jpg 320w"
      type="image/jpg" />
   <img
      width="1280" height="720" decoding="async" loading="lazy"
      src="/image.jpg" alt="an avif image" />
</picture>`
    const code3 = `
export default function Image(props: { alt: string; url: string }) {
  return (
    <picture>
      <source
        type="image/jxl"
        srcSet={\`/img/\${props.url}-1536.jxl 1536w, /img/\${props.url}-768.jxl 768w, /img/\${props.url}-576.jxl 576w, /img/\${props.url}-384.jxl 384w\`}
        sizes="(max-width: 768px) 100vw, 768px"
      />
      <source
        type="image/avif"
        srcSet={\`/img/\${props.url}-1536.avif 1536w, /img/\${props.url}-768.avif 768w, /img/\${props.url}-576.avif 576w, /img/\${props.url}-384.avif 384w\`}
        sizes="(max-width: 768px) 100vw, 768px"
      />
      <source
        type="image/webp"
        srcSet={\`/img/\${props.url}-1536.webp 1536w, /img/\${props.url}-768.webp 768w, /img/\${props.yml}-576.webp 576w, /img/\${props.url}-384.webp 384w\`}
        sizes="(max-width: 768px) 100vw, 768px"
      />
      <source
        type="image/jpeg"
        srcSet={\`/img/\${props.url}-1536.jpg 1536w, /img/\${props.url}-768.jpg 768w, /img/\${props.url}-576.jpg 576w, /img/\${props.url}-384.jpg 384w\`}
        sizes="(max-width: 768px) 100vw, 768px"
      />
      <img
        loading="lazy"
        decoding="async"
        width={2880}
        height={1620}
        src={\`/img/\${props.url}-1536.jpg\`}
        alt={props.alt}
        title={props.alt}
      />
    </picture>
  );
}
`;
    const code4 = `<Image src="frog" alt="frog looking nicely" />`

    console.log(code3);


    return(
        <>
            <Heading h='HTML JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#browser-support' text='Browser Support'/>
                        <LiContent n='2' a='#the-picture-element' text='The picture element'/>
                        <LiContent n='3' a='#implementation' text='Implementation'/>
                        <LiContent n='4' a='#responsiveness' text='Responsiveness'/>
                        <LiContent n='5' a='#components' text='Components'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='browser-support'>
                    <h1 className="main">Browser Support</h1>
                    <p className='umain'>Unfortunately, JPEG XL has no browser support on stable releases of browser versions. Chromium-browsers like Chrome, Edge, and Opera, as well as the Firefox browser, support JXL as part of other experimental features behind a flag. Even if these browsers support JPEG XL on stable browser versions, other browsers like Safari and people with outdated browsers don't support JPEG XL. It takes several years to establish a new image format, and in the meantime, you should look for serving content that all users can view.</p>
                </span>
                <Line/>

                <span id='the-picture-element'>
                    <h1 className="main">The picture element</h1>
                    <p className='umain'>To support every user, you can use the native picture element in HTML. It allows for progressive support. You mention all the file formats you want to serve, and your visitor's browser will only load the first image it supports. This reduced load on your server and the bandwidth usage of your visitors. The advantages are:</p>
                    <p className='umain'>&#10004; Does not download more than one image at a time.</p>
                    <p className='umain'>&#10004; Native support for selecting the most appropriate image.</p>
                    <p className='umain'>&#10004; 96% browser support and automatic fallback.</p>
                    <p className='umain'>&#10004; Getting this implemented is easy and straightforward.</p>
                </span>
                <Line/>

                <span id='implementation'>
                    <h1 className="main">Implementation</h1>
                    <p className='umain'>Currently 96% of browsers support the picture element. Even if your grandma visits your website with her disgusting Internet Explorer 2 via her modem, her browser will revert to the default image format when you specify it. Have a look at the following example and copy it if you want to use it for your website:</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code1}
                          </code>
                    </pre>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            PS: We added several attributes to the IMG tag to speed up the loading.
                          </code>
                    </pre>
                    <p className='umain'>
                        Make sure the first source tag is a JXL image for the browser to
                        display the first media type it can handle. You can edit this code snippet
                        to your needs, depending on if you want to serve AVIF or WebP.
                    </p>
                </span>
                <Line/>

                <span id='responsiveness'>
                    <h1 className="main">Responsiveness</h1>
                    <p className='umain'>Creating a great visual experience for Retina and 4K displays and mobile devices, we want to make sure that images aren't pixelated or too large for the device, wasting bandwidth. We can do so by implementing the srcSet attribute.</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code2}
                          </code>
                    </pre>
                </span>
                <Line/>

                <span id='components'>
                    <h1 className="main">Components</h1>
                    <p className='umain'>Creating JPEG XL images for any viewport of any format can be exhausting, so if you're using a framework, make sure to use a component to save yourself some time. For example, we use the following image component in NextJS:</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code3}
                          </code>
                    </pre>
                </span>
                <p className='umain'>This allows us to easily add an image like this:</p>
                <pre style={{
                    display: 'block',
                    overflowX: 'auto',
                    padding: '0.5em',
                    background: '#1E1E1E',
                    color: '#DCDCDC',
                    fontSize: '12px'
                }}>
                    <code style={{whiteSpace: 'pre'}}>
                        {code4}
                    </code>
                </pre>
                <img src={html} className='code5'/>
                <br/>
                <p className='umain'>Please remember to provide fallback images for older browser versions or
                    experimental use. Otherwise, your user experience may suffer as images are not rendered.</p>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default HTML;