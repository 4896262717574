import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import jxl from "../../images/frog.jxl";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import exif from '../../images/exiftool.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";
function ExifTool(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return(
        <>
            <Heading h='ExifTool JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-exiftool' text='What is ExifTool?'/>
                        <LiContent n='2' a='#exiftool-jxl-support' text='ExifTool JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-exiftool'>
                    <h1 className="main">What is ExifTool?</h1>
                    <p className='umain'>ExifTool is a platform-independent, open-source, free, and free-of-charge program that allows you to edit, read, and write metadata in a wide variety of files. The ExifTool is frequently integrated into digital workflows and supports many types of metadata and manufacturer-specific metadata formats for many digital cameras. This tool uses its own open metadata format. Meta information from many sources is bundled with any file type as binary or textual information. It may consist of a single file that wraps existing data, or it may be a sidecar file that contains, for example, Exif or XMP metadata.</p>
                </span>
                <Line/>

                <span id='exiftool-jxl-support'>
                    <h1 className="main">ExifTool JXL support</h1>
                    <p className='umain'>Since version 12.23, ExifTool supports reading JPEG XL metadata. ExifTool shows you the file size, image width, image height, image dimensions and number of Megapixels.</p>
                    <img src={exif} className='code5'/>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default ExifTool