import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";


function CSS() {
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    const code1 = `function addClass(format) {
    document.documentElement.classList.add(format);
}
var jxl = new Image();
(jxl.src = "data:image/jxl;base64,/woIELASCAgQAFwASxLFgkWAHL0xqnCBCV0qDp901Te/5QM="),
(jxl.onload = function() {
    addClass("jxl");
}),
(jxl.onerror = function() {
    var avif = new Image();
    (avif.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="),
    (avif.onload = function() {
        addClass("avif");
    }),
    (avif.onerror = function() {
        var webp = new Image();
        (webp.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=="),
        (webp.onload = function() {
            addClass("webp");
        });
    });
};
`;
    const code2 = `function addClass(A){document.documentElement.classList.add(A)}var jxl=new Image;jxl.src="data:image/jxl;base64,/woIELASCAgQAFwASxLFgkWAHL0xqnCBCV0qDp901Te/5QM=",jxl.onload=function(){addClass("jxl")},jxl.onerror=function(){var A=new Image;A.src="data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=",A.onload=function(){addClass("avif")},A.onerror=function(){var A=new Image;A.src="data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",A.onload=function(){addClass("webp")}}};`
    const code3 = `<html></html> <!-- no class at all, you have to use jpg (oh god)-->
<html class="webp"></html> <!-- you can use webp -->
<html class="avif"></html> <!-- you can use avif -->
<html class="jxl"></html> <!-- you can use jxl (yeah!) -->
`
    const code4 = `.img {
  background-image: url("image.jpg");
}
.webp .img {
  background-image: url("image.webp");
}
.avif .img {
  background-image: url("image.avif");
}
.jxl .img {
  background-image: url("image.jxl");
}`
    const code5 = `@mixin bg-url(
  $url,
  $url2x: false,
  $webp1x: false,
  $webp2x: false,
  $avif1x: false,
  $avif2x: false,
  $jxl1x: false,
  $jxl2x: false
) {
  background-image: url($url);
  @if $webp1x {
    .webp & {
      background-image: url($webp1x);
    }
  }
  @if $avif1x {
    .avif & {
      background-image: url($avif1x);
    }
  }
  @if $jxl1x {
    .jxl & {
      background-image: url($jxl1x);
    }
  }
@if $url2x {
    @media screen and (-webkit-min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url($url2x);
@if $webp2x {
        .webp & {
          background-image: url($webp2x);
}
}
@if $avif2x {
        .avif & {
          background-image: url($avif2x);
}
}
@if $jxl2x {
        .jxl & {
          background-image: url($jxl2x);
}}}}}`
    const code6 = `background-image:
  image-set(
    "zebra.jxl" type("image/jxl"),
    "zebra.avif" type("image/avif"),
    "zebra.webp" type("image/webp"),
    "zebra.png" type("image/png")
  );`
    const code7 = `.img {
  background-image: url(img.jpg);
}`
    const code8 = `body.jxl .logo {
  background-image: url(img.jxl);
}
body.no-jxl .logo {
  background-image: url(img.jpg);
}`

    return (
        <>
            <Heading h='CSS JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#detect-browser-support' text='Detect Browser Support'/>
                        <LiContent n='3' a='#css-code-snippet' text='CSS Code Snippet'/>
                        <LiContent n='4' a='#scss-background-mixin' text='SCSS Background mixin'/>
                        <LiContent n='5' a='#future-jpeg-xl-detection' text='Future JPEG XL Detection'/>
                        <LiContent n='6' a='#post-css-plugin' text='Post CSS Plugin'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>JPEG XL was developed to meet the demands of web delivery and professional photography. In addition to supporting wide color gamut, it supports images with high dynamic range and high bit depth. To help a wide range of use cases, JPEG XL offers a range of features, including animation, alpha channels, layers, thumbnails, and lossless and progressive coding. In addition to providing improved image quality and compression ratios, JPEG XL also has a shorter specification. Encoding and decoding are computationally efficient when using software implementations without requiring additional hardware acceleration.</p>
                    <p className='umain'>In this tutorial, you will see how easy it is to use JPEG XL images through HTML. HTML provides a media-type hint which enables the browser to find the correct version of the image for you. This is not an option available in CSS. It is still possible to support JPEG XL, AVIF, and WebP with a simple Javascript function. Our detection script utilizes classes to manipulate the HTML elements on your page.</p>
                    <p className='umain'>We must remember that the first rule does not win in CSS, but the last rule prevails. We have created a script that checks JPEG XL, AVIF, and WebP support by loading a JPEG XL-encoded 1x1 pixel image. If the browser successfully loads the JXL image, the HTML element receives a "jxl" class. If no JXL support is detected, the browser checks for AVIF support and adds an "avif" class. If the browser does not load the AVIF image, the script will further check the browser's support for WebP using the method described above. If your browser successfully loads the image, the HTML element gets a "webp" class. If it is doubtful that your browser will not pass this test, the HTML element will not get any class at all. Obviously, the AVIF and WebP detection are entirely optional, and you can remove them according to your preferences.</p>
                </span>
                <Line/>

                <span id='detect-browser-support'>
                    <h1 className="main">Detect Browser Support</h1>
                    <p className='umain'>The following code can be added as an inline script in your header above any CSS content to avoid visible image changes when adding the jxl/avif/webp class to your DOM. There are only 1100 bytes in this script, and it takes approximately five milliseconds to load, which is sufficient for the purpose it serves.</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code1}
                          </code>
                    </pre>
                    <p className='umain'>Minified version:</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code2}
                          </code>
                    </pre>
                    <p className='umain'>This will result in the following HTML code to use:</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code3}
                          </code>
                    </pre>
                </span>
                <Line/>

                <span id='css-code-snippet'>
                    <h1 className="main">CSS Code Snippet</h1>
                    <p className='umain'>Once implemented, we can use the following CSS due to the high-level classes and cascading.</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code4}
                          </code>
                    </pre>
                </span>
                <Line/>

                <span id='scss-background-mixin'>
                    <h1 className="main">SCSS Background mixin</h1>
                    <p className='umain'>Here is an SCSS background mixin that can be used to support JXL, AVIF and WebP including Retina support.</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code5}
                          </code>
                    </pre>
                </span>
                <Line/>

                <span id='future-jpeg-xl-detection'>
                    <h1 className="main">Future JPEG XL Detection</h1>
                    <p className='umain'>According to the World Wide Web Consortium (W3C), we will define the image type using the CSS Images Module Level 4 in the future. We will be able to specify different image formats using the image-set property. The browser will render the first image format it supports. We demonstrate below how type() can be used to deliver multiple images. Type declarations are a feature that is unique to CSS4. 90% of browsers currently support image-set but do not yet support type declarations. In the absence of browser support for this feature, we must rely on JavaScript to detect the presence of JXL, AVIF, and WebP support.</p>
                                        <pre style={{
                                            display: 'block',
                                            overflowX: 'auto',
                                            padding: '0.5em',
                                            background: '#1E1E1E',
                                            color: '#DCDCDC',
                                            fontSize: '12px'
                                        }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code6}
                          </code>
                    </pre>
                </span>
                <Line/>

                <span id='post-css-plugin'>
                    <h1 className="main">Post CSS Plugin</h1>
                    <p className='umain'>Joan Leon authored a tiny JS script (150B) and PostCSS plugin to use JPEG XL as a CSS background. This PostCSS Plugin lets you easily create CSS backgrounds that use JPEG XL images and fall back to the original image in unsupported browsers. NPM and Github packages are available for common JS and ES6. You can find it here: JXL in CSS PostCSS Plugin by Joan.</p>
                    <p className='umain'>A code snippet like this</p>
                    <p className='umain'>According to the World Wide Web Consortium (W3C), we will define the image type using the CSS Images Module Level 4 in the future. We will be able to specify different image formats using the image-set property. The browser will render the first image format it supports. We demonstrate below how type() can be used to deliver multiple images. Type declarations are a feature that is unique to CSS4. 90% of browsers currently support image-set but do not yet support type declarations. In the absence of browser support for this feature, we must rely on JavaScript to detect the presence of JXL, AVIF, and WebP support.</p>
                    <pre style={{
                                            display: 'block',
                                            overflowX: 'auto',
                                            padding: '0.5em',
                                            background: '#1E1E1E',
                                            color: '#DCDCDC',
                                            fontSize: '12px'
                                        }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code7}
                          </code>
                    </pre>
                    <p className='umain'>will generate a JXL class on the HTML and a CSS output like this:</p>
                    <pre style={{
                        display: 'block',
                        overflowX: 'auto',
                        padding: '0.5em',
                        background: '#1E1E1E',
                        color: '#DCDCDC',
                        fontSize: '12px'
                    }}>
                          <code style={{whiteSpace: 'pre'}}>
                            {code8}
                          </code>
                    </pre>
                </span>
                <Line/>


                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default CSS