import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import img5 from '../../images/code5.png'
import jxl from "../../images/frog.jxl";
import RelatedT from "../Components/RelatedArticles/Related";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Brave() {
    const related = [
        {
            href: '/brave',
            name: 'Brave',
            side: 'partial support',
            text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/chrome',
            name: 'Chrome',
            side: 'partial support',
            text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/edge',
            name: 'Edge',
            side: 'partial support',
            text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
        },
        {
            href: '/firefox',
            name: 'Firefox',
            side: 'partial support',
            text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
        },
        {
            href: '/opera',
            name: 'Opera',
            side: 'partial support',
            text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
        },
        {
            href: '/safari',
            name: 'Safari',
            side: 'no support',
            text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
        },
        {
            href: '/vivaldi',
            name: 'Vivaldi',
            side: 'partial support',
            text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
        }
    ]
    return (
        <>
            <Heading h='Brave JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='3' a='#what-is-brave' text='What is Brave?'/>
                        <LiContent n='4' a='#what-are-brave-flags' text='What are Brave Flags?'/>
                        <LiContent n='5' a='#brave-jpeg-xl-tutorial' text='Brave JPEG XL Tutorial'/>
                        <LiContent n='6' a='#brave-jpeg-xl-test-image' text='Brave JPEG XL Test Image'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>Those who are interested will find an introduction explaining Brave and JPEG XL below for SEO
                        purposes. You can jump to the tutorial on <a className='ugreen' href='#brave-jpeg-xl-tutorial'>how to enable JPEG XL support on Brave by clicking
                            here.</a>
                    </p>
                </span>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>Web photographers and web designers benefit from the JPEG XL image format as a professional image delivery format. Along with supporting wide color gamuts and high dynamic ranges, it supports images with a high bit depth. The JPEG XL image format offers animation, alpha channels, layers, thumbnails, lossless, and progressive decoding.</p>
                    <p className='umain'>In comparison to legacy JPEG, JPEG XL offers significant improvements to image quality and compression ratio. By implementing lossless JPEG recompression, legacy JPEGs can be quickly converted to the new format.</p>
                    <p className='umain'>The JPEG XL format supports both lossless and lossy compression. Lossless compression typically reduces the file size by 20-60%. With software implementations, computationally efficient encoding and decoding are possible without the need for hardware acceleration.</p>
                </span>
                <Line/>

                <span id='what-is-brave'>
                    <h1 className="main">What is Brave?</h1>
                    <p className='umain'>Based on the Chromium engine, Brave is a free and open-source web browser. With its own search engine, wallet, and intense focus on privacy and third-party adblocking, Brave offers a range of features. Being a privacy-minded browser, Brave blocks ads and website trackers by default. The app also allows users to turn on optional ads that pay users for their attention in the form of the cryptocurrency Basic Attention Tokens (BAT). In return, users can send tips and the ability to keep the cryptocurrency earned to websites and content creators who support BAT. Currently, Brave has 42.1 million monthly active users, 14 million daily active users, and over 1.2 million content creators.</p>
                </span>
                <Line/>

                <span id='what-are-brave-flags'>
                    <h1 className="main">What are Brave Flags?</h1>
                    <p className='umain'>Brave features that are in development are known as "flags.". These are located in a hidden settings menu accessible through the navigation bar. A flag is required for JPEG XL, which means by default, this functionality is turned off. No information is available regarding when Brave will support JPEG XL by default.</p>
                </span>
                <Line/>

                <span id='brave-jpeg-xl-tutorial'>
                    <h1 className="main">Brave JPEG XL Tutorial</h1>
                    <p className='umain'>&#10004; Ensure you have at least Brave version installed.</p>
                    <p className='umain'>&#10004; Open a new browser tab.</p>
                    <p className='umain'>&#10004; Type the following: brave://flags#enable-jxl</p>
                    <p className='umain'>&#10004; The first and marked result shows an entry called Enable JXL image format with the description Adds image decoding support for the JPEG XL image format. Mac, Windows, Linux, Chrome OS, Android and the hashtag #enable-jxl.</p>
                    <p className='umain'>&#10004; On the right, click the drop-down menu and select Enable to enable the support. After completing the process, you will be prompted to restart your web browser for the changes to take effect.</p>
                    <img src={img5} className='code5'/>
                </span>
                <Line/>

                <span id='brave-jpeg-xl-test-image'>
                    <h1 className="main">Brave JPEG XL Test Image</h1>
                    <p className='umain'>Upon re-launching the browser, return to this website to see this incredible frog image:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <br/>
                    <br/>
                    <p className='umain'><b>Congratulations!</b></p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Brave