import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function PicView(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='PicView JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-picview' text='What is PicView?'/>
                        <LiContent n='2' a='#picview-jpeg-xl-support' text='PicView JPEG XL Support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-picview'>
                    <h1 className="main">What is PicView?</h1>
                    <p className='umain'>PicView is a software application for Windows that allows users to view images in a compact and fast manner. In addition to image effects, galleries, image information, and more, PicView provides many additional features. It is also capable of automatically adjusting its display to achieve the best possible viewing experience. Users will find the interface to be intuitive and straightforward. By pressing Alt + Z, PicView's UI can be toggled only to display the image, and the hover buttons and colored window border can be toggled on/off from the settings window. By pressing T, it is possible to change the image's transparency into a checkerboard background, a dark background, or a white background. PicView comprises 26 different shader effects (image filters), which include negative colors, old movie effects, pencil sketches, etc. Each of these effects can be applied to the current image. The option allows you to test the result before committing to it. You may also copy the effects to the clipboard using Ctrl + C or save them directly to your computer. Furthermore, the result will also be applied when setting images as wallpaper or lock screens.</p>
                </span>
                <Line/>

                <span id='picview-jpeg-xl-support'>
                    <h1 className="main">PhotoStack JPEG XL Support</h1>
                    <p className='umain'>Since the 27th of September 2021, PicView has JXL support. Make sure you have the latest version installed and you will be able to hande JPEG XL images as any other image files.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default PicView