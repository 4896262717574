import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import img4 from "../../images/code4.png";
import RelatedT from "../Components/RelatedTutorials/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Solution() {
    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];
    return (
        <>
            <Heading h='JPEG XL - Why the new image format is a comprehensive solution.' p='#articles'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#high-speed-compression' text='High-speed Compression'/>
                        <LiContent n='3' a='#progressive-decoding' text='Progressive Decoding'/>
                        <LiContent n='4' a='#lossless-transcoding' text='Lossless Transcoding'/>
                        <LiContent n='5' a='#high-standards-and-features' text='High Standards and Features'/>
                        <LiContent n='6' a='#no-license-fees' text='No License Fees'/>
                        <LiContent n='7' a='#summ4ry' text='Summary'/>
                    </ol>
                </nav>
                <br/>
                <Line/>
                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>JPEG XL is the latest format of JPEG, the Joint Photographic Experts Group. Due to its support for transparency and motion, it is not only a replacement for the current JPEG format but also PNG and GIF. In comparison to current standards, it works much more efficiently. This isn't a result of fewer image data, more artifacts, and worse image quality, but better file organization resulting in higher quality media with smaller file sizes.</p>
                </span>
                <Line/>

                <span id='high-speed-compression'>
                    <h1 className="main">High-speed Compression</h1>
                    <p className='umain'>Compared with legacy JPEG, JPEG XL offers significantly improved image quality and compression ratios. A software implementation is designed to encode and decode with computationally efficient algorithms without requiring additional hardware acceleration, even on mobile devices.</p>
                </span>
                <Line/>

                <span id='progressive-decoding'>
                    <h1 className="main">Progressive Decoding</h1>
                    <p className='umain'>Standardization of JPEG XL is imminent. As opposed to video-based image formats, such as HEIC and AVIF, JPEG XL allows progressive decoding. As a result, the image initially appears in poor resolution but improves as it downloads. In contrast, video-based image formats require total image loading before they can be displayed. JPEG XL is, therefore, an excellent choice for high-resolution images on mobile devices with impatient users, as it is faster than WebP, AVIF, or HEIC.</p>
                </span>
                <Line/>

                <span id='lossless-transcoding'>
                    <h1 className="main">Lossless Transcoding</h1>
                    <p className='umain'>The JPEG XL format is a superset of JPEG. It is important to note that even though JPEG XL provides superior compression, all the JPEG coding tools are preserved. The result is that all JPEG image data can also be represented as JPEG XL image data. Because of this, you do not need to decode JPEGs to pixels before converting to JPEG XLs. This means that JPEG XL files are exact copies of the original JPEG files. With lossless transitioning, each image is about 20% smaller.</p>
                </span>
                <Line/>

                <span id='high-standards-and-features'>
                    <h1 className="main">High Standards and Features</h1>
                    <p className='umain'>Unlike Google's WEBP and AOM's AVIF formats, JPEG XL does not have an 8bit or 12bit limit per color channel. JPEG XL allows for a bit depth up to 32bit, supports High Dynamic Range, and a maximum dimension of 1 million pixels per side.</p>
                </span>
                <Line/>

                <span id='no-license-fees'>
                    <h1 className="main">No License Fees</h1>
                    <p className='umain'>JPEG XL was built from the start as open source and doesn't use any third-party patents. Moreover, an Apache 2.0 license is provided from the outset for generating and displaying the JPEG XL format. The objective of this is to ensure a safe and risk-free implementation for everyone. A competing format, AVIF, is based on the HEIF standard, among others, and is therefore potentially subject to patents. JPEG XL is unaffected by such risks.</p>
                </span>
                <Line/>

                <span id='summ4ry'>
                    <h1 className="main">Summary</h1>
                    <p className='umain'>JPEG XL is ideally suited for the web. Developers have implemented all features needed for a modern image format to shine, combined with an efficient compression algorithm. We can expect JPEG XL to become the default image format in the following years. The only thing holding JPEG XL back right is software support.</p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={articles}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Solution;