import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import edge from "../../images/edge.png";
import edge2 from "../../images/edge2.png";
import React from "react";
import jxl from "../../images/frog.jxl";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Edge() {
    const related = [
        {
            href: '/brave',
            name: 'Brave',
            side: 'partial support',
            text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/chrome',
            name: 'Chrome',
            side: 'partial support',
            text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/edge',
            name: 'Edge',
            side: 'partial support',
            text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
        },
        {
            href: '/firefox',
            name: 'Firefox',
            side: 'partial support',
            text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
        },
        {
            href: '/opera',
            name: 'Opera',
            side: 'partial support',
            text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
        },
        {
            href: '/safari',
            name: 'Safari',
            side: 'no support',
            text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
        },
        {
            href: '/vivaldi',
            name: 'Vivaldi',
            side: 'partial support',
            text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
        }
    ]
    return (
        <>
            <Heading h='Edge JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='3' a='#what-is-edge' text='What is Edge'/>
                        <LiContent n='4' a='#jpeg-xl-support' text='JPEG XL Support'/>
                        <LiContent n='5' a='#edge-jpeg-xl-tutorial' text='Edge JPEG XL Tutorial'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>Those who are interested will find an introduction explaining Edge and JPEG XL below for SEO purposes. You can jump to the tutorial
                        on <a className='ugreen' href='#edge-jpeg-xl-tutorial'>how to enable JPEG XL support by clicking here.</a></p>
                </span>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>Compared to existing image formats, the JPEG XL format provides scalable web distribution of high-quality images and efficient compression. JPEG XL offers a significantly smaller file size at comparable quality, as well as fast, parallelizable decoding and encoding. It has a wide range of features, including progressive decoding, lossy compression, lossless compression, animation, and reversible transcoding of legacy JPEG. There is support for high-quality applications, such as wide gamuts, higher resolutions, bit depths, and dynamic ranges, as well as visually lossless coding. As an added benefit, the codec is free of all licensing fees.</p>
                    <p className='umain'>The feature set of this file format, including lossless transcoding / lossless JPEG recompression, is impressive, and the image compression speaks for itself. However, even with the potential to replace existing JPEG images and any other image codec such as AVIF or WEBP, as all new codecs, the new image format JPEG XL requires support, especially among Operating Systems including browser support on all devices. Google Chrome Canary and Firefox already enabled experimental support, but what about Edge?</p>
                </span>
                <Line/>

                <span id='what-is-edge'>
                    <h1 className="main">What is Edge</h1>
                    <p className='umain'>Microsoft's Edge is the company's most recent internet browser. Originally referred to as Project Spartan, the Edge browser debuted in 2015 under the name Edge and has since undergone numerous design changes, the most notable of which was the move to the open-source Chromium platform in 2020, on which Google Chrome is also built. In the past, Edge used proprietary Microsoft technologies under the hood, but Microsoft changed it to make it more compatible with the way many websites are built today. Edge is the third most popular browser in the world, after Google Chrome and Apple Safari. Microsoft Edge is included as the default browser in Windows 10 and Windows 11, and it's also available for iPhone, Android, Apple Mac, and Linux.</p>
                </span>
                <Line/>

                <span id='jpeg-xl-support'>
                    <h1 className="main">JPEG XL Support</h1>
                    <p className='umain'>Microsoft released stable version 91 of Edge, which is based on Chromium, on May 27, 2021. Support has been available since this version. However, support is not available by default. Edge has a website where you can activate experimental features. The JPEG XL feature, however, cannot be activated through that website.</p>
                    <img src={edge} className='code5'/>
                    <p className='umain'>The process of enabling JPEG XL support is complicated. Please follow these instructions:</p>
                </span>
                <Line/>

                <span id='edge-jpeg-xl-tutorial'>
                    <h1 className="main">Edge JPEG XL Tutorial</h1>
                    <p className='umain'>Getting JPEG XL to work in Microsoft Edge 91 is not easy. There are two possible approaches. The first one is a bit more user-friendly, as you only need to create a shortcut to Edge and make a few settings changes. To utilize JPEG XL support, the browser must be opened through the shortcut created. The second approach is a bit more nerdy and complex but will enable JPEG XL support regardless of how you start it.</p>
                    <p className='umain'>To view your current Edge version, open the three-dot menu on the top right, select Help and Feedback, and then navigate to Information about Edge.</p>
                    <p className='umain'><b>Runtime flag</b></p>
                    <p className='umain'>&#10004; Make sure Edge is updated to at least v91.</p>
                    <p className='umain'>&#10004; Close the browser.</p>
                    <p className='umain'>&#10004; Create a shortcut to Edge.</p>
                    <p className='umain'>&#10004; Open the Properties of the shortcut.</p>
                    <p className='umain'>&#10004; Add the following runtime flag to the end of the target field: --enable-features=JXL</p>
                    <img src={edge2} className='code5'/>
                    <p className='umain'><b>Enable permanently</b></p>
                    <p className='umain'>&#10004; Make sure Edge is updated to at least v91.</p>
                    <p className='umain'>&#10004; Close the browser.</p>
                    <p className='umain'>&#10004; Open %localappdata%MicrosoftEdgeUser Data in the Explorer</p>
                    <p className='umain'>&#10004; Open the file called Local State.</p>
                    <p className='umain'>&#10004; Search for an enabled_labs_experiments string.</p>
                    <p className='umain'>&#10004; Enter "enable-jxl@1" (with quotes) inside the empty brackets.</p>
                    <p className='umain'>&#10004; Could not find enabled_labs_experiments? Search for the browser_version_of_last_seen string and behind its value in brackets and the comma, add "enabled_labs_experiments":["enable-jxl@1"] right after.</p>
                    <p className='umain'>&#10004; Save and close the file, then open the browser.</p>

                    <p className='umain'>Your configuration was successful if you see the following JPEG XL image:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <br/>
                    <br/>
                    <p className='umain'><b>Congratulations!</b></p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Edge