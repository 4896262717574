import React from "react";
import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import thunderbird from '../../images/thunderbird.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";
function Thunderbird(){
    const related = [
        {
            href: '/thunderbird',
            name: 'Thunderbird',
            side: 'partial support',
            text: 'Thunderbird is the first email client to support JXL images. Find out how you can use JXL images in Thunderbird.'
        }
    ]
    return(
        <>
            <Heading h='Thunderbird JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-thunderbird' text='What is Thunderbird'/>
                        <LiContent n='2' a='#jpeg-xl-support' text='JPEG XL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-thunderbird'>
                    <h1 className="main">What is Thunderbird?</h1>
                    <p className='umain'>Using Thunderbird, you can manage email, news feeds, chat conversations, and newsgroups across multiple platforms for free. Thunderbird is open-source and free to use. Email is a compelling but easy-to-use application. Volunteers and paid staff devote a great deal of time developing, testing, translating, and supporting Thunderbird. The Thunderbird community develops the program independently. Thus, the Thunderbird Council, which is elected by the Thunderbird community, manages and oversees the paid staff, the budget, and fundraising activities. It is through the contributions of the Thunderbird community that Thunderbird is developed. Thunderbird is no longer developed by Mozilla Corporation, the company behind Firefox and Mozilla Messaging. However, Mozilla continues to provide support for Thunderbird by hosting a number of its resources. Thunderbird is a free application. Thunderbird offers several exciting features. It allows you to take complete control of your email account. Thunderbird offers a wide range of add-ons that can be used to extend and customize the experience of using email. As Thunderbird is rooted in Mozilla, it abides by the Mozilla Manifesto, which outlines a commitment to a free, open, and equal internet.</p>
                </span>
                <Line/>

                <span id='jpeg-xl-support'>
                    <h1 className="main">Thunderbird JPEG XL support</h1>
                    <p className='umain'>Since 01.05.2021, Thunderbird has supported JPEG XL. Please make sure you are using the latest version of Thunderbird. Click on the Config Editor button under Tools -> Options -> Advanced -> General. To enable JPEG XL support, manually set image.jxl.enabled to true.</p>
                    <img src={thunderbird} className='code5'/>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Thunderbird