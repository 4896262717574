import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Qimgv(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='qimgv JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-qimgv' text='What is qimgv?'/>
                        <LiContent n='2' a='#qimgv-jpeg-xl-support' text='JPEG XL Support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-qimgv'>
                    <h1 className="main">What is qimgv</h1>
                    <p className='umain'>The image viewer qimgv follows the principle of an uncluttered, simple, and easy-to-use UI that only shows elements when you need them. It is fully configurable, including themes and shortcuts. It also supports basic image editing, such as cropping, rotating, and resizing photos. The image viewer works on Windows, Linux, Ubuntu, and other GNU-related Operating Systems, such as Linux Mint, Manjaro, Fedora, OpenSUSE, Gentoo, Void Linux, and Alpine Linux. You can either download it as a package or compile it from the source.</p>
                </span>
                <Line/>

                <span id='qimgv-jpeg-xl-support'>
                    <h1 className="main">JPEG XL support</h1>
                    <p className='umain'>qimgv can open some different formats via third-party image plugins. them are included in the Windows package. For any other package, download the Qt plugin by Novemesk.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Qimgv