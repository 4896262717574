import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import exif from "../../images/exiftool.png";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Exiv2() {
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return (
        <>
            <Heading h='Exiv2 JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-exiv2' text='What is Exiv2?'/>
                        <LiContent n='2' a='#exiv-jxl-support' text='Exiv JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-exiv2'>
                    <h1 className="main">What is Exiv2?</h1>
                    <p className='umain'>The Exiv2 library provides cross-platform functionality for handling image metadata. The Exif, IPTC, and XMP metadata and ICC Profiles embedded within digital images in various formats are accessible via this application. As free software, Exiv2 is included in many projects, such as the KDE and GNOME desktops and a wide variety of applications, such as GIMP and DarkTable.</p>
                </span>
                <Line/>

                <span id='exiv-jxl-support'>
                    <h1 className="main">Exiv JXL support</h1>
                    <p className='umain'>The release of Exiv2 v0.27.4 happened on June 15th, 2021. All BMF formats, including AVIF, JXL, and HEIF, are supported.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default Exiv2