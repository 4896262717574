import './Header.css'
import logo from '../../images/discord logo.png'

function Header() {
    return (
        <div id='header'>
            <div className='header'>
                <div>
                    <p><a href='/#blog'>Blog</a></p>
                    <p><a href='/#tutorials'>Tutorials</a></p>
                </div>
                <div>
                    <a>
                        <img src={logo}/>
                    </a>
                    <button>
                        <a href='/#convert'>
                        Convert to JPEG XL
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                             stroke-width="2" className="ml-1 w-3 h-3" viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                        </a>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Header;