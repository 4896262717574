import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function ImageGlass(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='ImageGlass JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-imageglass' text='What is ImageGlass?'/>
                        <LiContent n='2' a='#imageglass-jpeg-xl-support' text='ImageGlass JPEG XL Support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-imageglass'>
                    <h1 className="main">What is ImageGlass?</h1>
                    <p className='umain'>Under Windows 10, 8, 7, and Vista, ImageGlass lets you view images in a lightweight, versatile way, which replaces Photo Viewer. Those who have trouble viewing PNG or GIF files in Photo Viewer will significantly benefit from this. With a simple interface and many essential features, the application is simple but powerful. ImageGlass offers a wide range of image formats thanks to the Magick.NET library. The user can easily customize the list of extensions as long as Magick.NET supports the desired file type.</p>
                </span>
                <Line/>

                <span id='imageglass-jpeg-xl-support'>
                    <h1 className="main">ImageGlass JPEG XL Support</h1>
                    <p className='umain'>As ImageGlass uses Magick.NET for its image codecs, and Magick.NET supports JPEG XL, the integration was easy! Version 8.1.4.18 was released on April 17th, 2021, and supports JPEG XL. JXL images can be opened like any other image.</p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>

                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default ImageGlass;