import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import ConvertButton from "../Components/ConvertButton";
import RelatedT from "../Components/RelatedTutorials/Related";
import React from "react";
import img4 from '../../images/code4.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";
function SiteMap(){
    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];
    return (
        <>
            <Heading h='Create image sitemaps' p='#articles'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#creating-a-sitemap' text='Creating a sitemap'/>
                        <LiContent n='3' a='#the-right-markup' text='The right markup'/>
                        <LiContent n='4' a='#example-image-sitemap' text='Example Image Sitemap'/>
                    </ol>
                </nav>
                <br/>
                <Line/>
                <span id='introduction'>
                    <h1 className='main'>Introduction</h1>
                    <p className='umain'>By adding images to your sitemap, Google can discover images that they would not find otherwise, such as images accessed using JavaScript. Follow best practices for publishing images by adding images to your existing sitemap or creating a separate image sitemap. Metadata can be added to an image, such as contact details or licensing information, which will be displayed in the image search results.</p>
                    <p className='umain'>When the Google Search Console returns errors for the sitemap, such a result represents a potential error that will cause the ranking of the website to be underestimated. In addition to the main sitemap, the Search Console panel displays an index of pictures based on map coordinates. In addition, users frequently search for pictures. In turn, they go to the website to see them. Create an Image Sitemap to improve your chances of being found in Google image search results. Title and alt attributes must also be filled in correctly. Both effect image ranking positively.</p>
            </span>
                <br/>
                <Line/>

                <span id='creating-a-sitemap'>
                    <h1 className='main'>Creating a sitemap</h1>
                    <p className='umain'>Image sitemaps include URLs and other details about the specific images you want Google, Bing, and other engines to index. Sitemaps are formatted with standard XML tags, and they are used by most search engines. Your standard sitemap needs to contain relevant details about images on your site for Google to understand them. Type of image, topic, title, caption, and location are a few examples. Using this method, website owners can determine which images are most relevant for their pages.</p>
                    <p className='umain'>Make a separate file for each picture that organizes its information. In this way, the pictures will be more likely to be indexed. The sitemap.xml file on the website can also be edited by adding the appropriate tags.</p>
                </span>
                <Line/>

                <span id='the-right-markup'>
                    <h1 className='main'>The right markup</h1>
                    <p className='umain'>Sitemap tags specific to images can be found below. Please use the required tags; optional tags are encouraged for a better user experience.</p>
                    <p className='umain'><b>image:image</b></p>
                    <p className='umain'>An image's metadata contains its information. Using &lt;url&gt; tags, you can include up to 1,000 &lt;image:image&gt; tags.</p>
                    <p className='umain'><b>image:loc</b></p>
                    <p className='umain'>This is the URL for the image. Occasionally, your main website may not host the same image URL. I don't have a problem with this as long as both domains are verified on Google Search Console. To ensure that you're using a verified hosting site in Google Search Console, you can, for example, use a content delivery network like Google Sites to host your images. Please check your robots.txt file to ensure that any content you would like indexed is not blocked.</p>
                    <p className='umain'><b>image:caption</b></p>
                    <p className='umain'>A caption for the image.</p>
                    <p className='umain'><b>image:geo_location</b></p>
                    <p className='umain'>The geographic location of the image.</p>
                    <p className='umain'><b>image:title</b></p>
                    <p className='umain'>The title of the image.</p>
                    <p className='umain'><b>image:license</b></p>
                    <p className='umain'>The license of the image can be found at the following URL. You may also use the image metadata.</p>
                </span>
                <Line/>

                <span id='example-image-sitemap'>
                    <h1 className='main'>Example Image Sitemap</h1>
                    <img src={img4} className='code4'/>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={articles}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default SiteMap;