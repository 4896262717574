import './Footer.css'

function Footer() {
    return (
        <>
            <div id='footer'>
                <div className='column'>
                    <a href='/'>jpegxl.io</a>
                    <p>Convert image formats like PNG, JPG, GIF, WEBP to JPEG XL.</p>
                    <p>@jschimtz</p>
                    <a href='https://aiforeveryone.org/' className='restored_link'>Restored by AiForEveryone</a>
                </div>
                <div className='column'>
                    <p>Categories</p>
                    <a href='/#blog'>Blog</a>
                    <a href='/#tutorials'>Tutorials</a>
                </div>
                <div className='column'>
                    <p>Most viewed</p>
                    <a href='/chrome'>JPEG XL in Chrome</a>
                    <a href='/firefox'>JPEG XL in Firefox</a>
                    <a href='/edge'>JPEG XL in Edge</a>
                    <a href='/opera'>JPEG XL in Opera</a>
                    <a href='/css'>JPEG XL in CSS</a>
                </div>
                <div className='column'>
                    <p>Other pages</p>
                    <a href='/sitemapping'>Sitemap</a>
                </div>
            </div>
        </>

    )
}

export default Footer