import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedTutorials/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function ANS(){
    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];
    return (
        <>
            <Heading h='A patent has been granted to Microsoft for ANS' p='#articles'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#background' text='Background'/>
                        <LiContent n='2' a='#outcome' text='Outcome'/>
                    </ol>
                </nav>
                <br/>
                <Line/>
                <span id='background'>
                    <h1 className="main">Background</h1>
                    <p className="umain">A patent has been granted to Microsoft for a free-code coding method.</p>
                    <p className="umain">Microsoft obtained the patent for ANS-Coding after a failed attempt by Google. The inventor is willing to release the process for public use.</p>
                    <p className="umain">Microsoft did not create ANS, but Jaroslaw (Jarek) Duda, a researcher at the University of Krakau. Due to Duda's own desire to never patent or otherwise protect ANS, his work is available on the Arxiv repository. Several years ago, the information scientist criticized Google's attempts to register a patent on ANS. Google's application for the patent was rejected as well.</p>
                    <p className="umain">A patent has been granted to software giant Microsoft after years of trying to obtain one from the US Patent Office. Several variants of the coding procedure Asymmetric Numerical Systems (ANS) may be found in most modern codecs, such as AV1, Z-Standard compression, or even rANS in JPEG XL.</p>
                </span>
                <Line/>

                <span id='outcome'>
                    <h1 className="main">Outcome</h1>
                    <p className="umain">Furthermore, Duda emphasizes that rANS is being used in JPEG XL, delivering significantly better compression and quality than the 30-year-old JPEG. The JPEG XL work has mainly been completed and standardized. The format will gain widespread adoption as a license-free file format, including web browsers and operating systems. Duda fears that Microsoft's patent will significantly hinder this development.</p>
                    <p className="umain">As of this writing, it is unclear whether it will eventually lead to this. To do so, companies would have to notify the ISO of their patents and any potential claims before standardization, which, according to The Register, is not happening, although Microsoft is active in these forums. As such, there should be no impact on JPEG XL, suggests the spokesperson for JPEG-XL, Jon Sneyers.</p>
                    <p className="umain">It is unclear what Microsoft intends with the patent or why the company has chosen to patent a supposedly free technology.</p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={articles}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default ANS;