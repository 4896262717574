import './Advantages.css'
import Advantage from "./Advantage/Advantage";
import pht1 from '../../images/downloaded_svgs/visualizer-1.svg'
import pht2 from '../../images/downloaded_svgs/visualizer-3.svg'
import pht3 from '../../images/downloaded_svgs/visualizer-4.svg'
import pht4 from '../../images/downloaded_svgs/visualizer-6.svg'
import pht5 from '../../images/downloaded_svgs/visualizer-7.svg'
import pht6 from '../../images/downloaded_svgs/visualizer-8.svg'
import pht10 from '../../images/downloaded_svgs/visualizer-8.svg'
import pht7 from '../../images/downloaded_svgs/visualizer-9.svg'
import pht8 from '../../images/downloaded_svgs/visualizer-11.svg'
import pht9 from '../../images/downloaded_svgs/visualizer-12.svg'

function Advantages() {
    return (
        <>
            <div id='advantages'>
                <Advantage text='reduces file size by an average of 20-60%' image={pht1}/>
                <Advantage text='developed by the JPEG committee' image={pht2}/>
                <Advantage text='progressive decoding for web experience' image={pht3}/>
                <Advantage text='lossless jpeg transcoding' image={pht4}/>
                <Advantage text='photographic & synthetic images' image={pht5}/>
                <Advantage text='embraces wide gamut / HDR and 32 bit depth' image={pht6}/>
                <Advantage text='fast software encoding and decoding' image={pht7}/>
                <Advantage text='full of smartphone features like overlays' image={pht8}/>
                <Advantage text='WIP support for animated frames' image={pht9}/>
                <Advantage text='FOSS and royalty-free license' image={pht10}/>
            </div>
            <br/>
            <br/>
            <span className='line'/>
            <br/>
            <br/>
        </>
    )
}

export default Advantages;