import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function PhotoStack(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='PhotoStack JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-photostack' text='What is PhotoStack?'/>
                        <LiContent n='2' a='#photostack-jpeg-xl-support' text='PhotoStack JPEG XL Support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-photostack'>
                    <h1 className="main">What is PhotoStack?</h1>
                    <p className='umain'>Photostack is an open-source batch photo editor that executes entirely in the browser. It supports watermarking and is compatible with all major web browsers. Multiple photos can be imported from a local storage device, an external web server, or Dropbox. You can then resize images, set the desired format, add a watermark and remove the EXIF data. Watermarks can be easily created in PhotoStack for use later if you wish to protect your images. PhotoStack does not restrict your choice of platform or require a monthly fee. The software is compatible with all modern web browsers. The entire source code is available on GitHub under the GPLv3 license. Images are processed locally in your browser. PhotoStack does not upload any photos anywhere, and the only data it collects is basic analytics. Thanks to a web technology known as Service Workers, PhotoStack can operate entirely offline on most browsers. PhotoStack is compatible with most modern browsers and platforms that support current web features, including Chrome, Firefox, Opera, Microsoft Edge.</p>
                </span>
                <Line/>

                <span id='photostack-jpeg-xl-support'>
                    <h1 className="main">PhotoStack JPEG XL Support</h1>
                    <p className='umain'>On the 16th of October, PhotoStack added JPEG XL support. You can use the JPEG XL format as you would use any other image file.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default PhotoStack