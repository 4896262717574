import './ConvertPage.css'
import {useEffect, useRef, useState} from "react";
import DragFile from "../DragFile/DragFile";
import Loading from "../Loading/Loading";
import {useLocation} from "react-router-dom";

function ConvertPage() {
    const location = useLocation()
    const refs = {'convert': useRef(null),}
    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash && refs[hash] && refs[hash].current) {
            refs[hash].current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location, refs]);

    return (
        <div id='convert' ref={refs['convert']}>
            <h1 className='convert_text'>Convert all images to JXL for free.</h1>
            <div>
                <p>No data is sent. The magic happens in your browser.&nbsp;
                    <div className='black'>How?
                        <div className='how'>We use C Libraries and WASM to convert your images clientside.</div>
                    </div>
                </p>
            </div>
            <br/>

            <DragFile/>
        </div>
    )
}

export default ConvertPage;