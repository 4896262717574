import './Heading.css'

function Heading(props){
    return(
        <div className='heading'>
            <div className='heading_content'>
                <h1>{props.h}</h1>
                <p>{props.p}</p>
            </div>
        </div>
    )
}

export default Heading;