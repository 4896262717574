import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function PhotoQt(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='PhotoQt JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-photoqt' text='What is PhotoQt?'/>
                        <LiContent n='2' a='#photoqt-jpeg-xl-support' text='PhotoQt JPEG XL Support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-photoqt'>
                    <h1 className="main">What is PhotoQt?</h1>
                    <p className='umain'>Lukas Spies developed the PhotoQt image viewer for Windows and Linux. Several people may be reminded of 'Lightbox' image viewers by its unique interface on some websites. While there may be similarities ultimately, PhotoQt was designed and optimized for an ordinary desktop computer. There is no user interface other than the image being viewed, with a few elements becoming visible as they fade in/slide in (such as thumbnails along the bottom edge). Its speed and flexibility allow it to adapt to different workflows seamlessly.</p>
                </span>
                <Line/>

                <span id='photoqt-jpeg-xl-support'>
                    <h1 className="main">PhotoQt JPEG XL Support</h1>
                    <p className='umain'>With the addition of the qjpegxl.dll plug-in, the original PhotoQt v2.3 can be easily enhanced to display JXL images on Windows. The qjpegxl.dll file enables PhotoQt v2.3 to open JXL files. Several Win64 Qt-based applications can make use of qjpegxl.dll. However, the application must be built using the MSVC compiler, and Qt must be at least version 5.14.</p>
                    <p className='umain'>Please copy the qjpegxl.dll file into C:/Program Files/PhotoQt/imageformats/ folder. Then open JPEG XL images as you would any other image type.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default PhotoQt;