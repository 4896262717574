import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function PHP(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return (
        <>
            <Heading h='PHP JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-php' text='What is PHP?'/>
                        <LiContent n='2' a='#php-jxl-support' text='PHP JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-php'>
                    <h1 className="main">What is PHP?</h1>
                    <p className='umain'>PHP is a widely-used general-purpose scripting language that is particularly well suited for web development and embedded within HTML. Rather than using lots of commands to output HTML (as with C or Perl), PHP pages contain HTML with embedded code that accomplishes a task (in this case, "Hello, I'm a PHP script! "). It is important to note that PHP differs from client-side JavaScript in that the code is executed on the server, which generates HTML, which is then sent to the client. Although the client would receive the results of running that script, they would not know the underlying code. In addition, you can configure your webserver to process all your HTML files using PHP, so your users will be unable to discover what you are doing behind the scenes. There are several advantages to using PHP, including that it is straightforward to use for a newcomer but offers a range of advanced features for professional programmers.</p>
                </span>
                <br/>
                <Line/>

                <span id='php-jxl-support'>
                    <h1 className="main">PHP JXL support</h1>
                    <p className='umain'>PHP has recently added support for AVIF in version 8.1. To this date, no native PHP support is available. Luckily, Johannes Siipola has created a PHP library for encoding JPEG XL images. It requires PHP 7.2.5 or later and works under Linux, macOS, or Windows OS with x64 architecture. Check out the plugin and the installation guide.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default PHP;