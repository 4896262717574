import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";

function GThumb(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='gThumb JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#what-is-gthumb' text='What is gThumb?'/>
                        <LiContent n='3' a='#gthumb-supports-jpeg-xl' text='gThumb supports JPEG XL'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>gThumb, an image viewer and organizer for the GNOME environment, was released in version 3.11.3 a few days ago. A significant change in this version is the addition of the JPEG XL format (.jxl). The JPEG XL format builds upon Google's Pik format and Cloudinary's FUIF format. The JPEG Committee developed the codec as the next generation of image compression.</p>
                </span>
                <Line/>

                <span id='what-is-gthumb'>
                    <h1 className="main">What is gThumb?</h1>
                    <p className='umain'>gThumb is an image viewer and browser written for the GNOME environment. It allows you to browse your hard drive and view thumbnail images of image files and individual pictures of various formats. Graphic formats supported by gThumb include JPEG, GIF, TIF, and PNG. gThumb can display other image types, including TGA, ICO, BMP, XPM, and others if the supporting libraries are installed. If you have the dcraw program installed on your computer, you will support RAW image types. gThumb not only allows you to view image files but has several other valuable features such as the ability to add comments to images, organize images into catalogs, print images, create slideshows, set your desktop background, and more. GThumb includes a feature for importing photos from attached cameras and memory card readers. Many metadata formats such as EXIF, XMP, and IPTC can be read by gThumb. gThumb offers a system of extensions (or plug-ins) that can be used to extend its functionality.</p>
                </span>
                <Line/>

                <span id='gthumb-supports-jpeg-xl'>
                    <h1 className="main">gThumb supports JPEG XL</h1>
                    <p className='umain'>GThumb has supported JPEG XL since version 3.11.3. Ensure that this version is installed and that you can read and write JPEG XL files as you would any other image.</p>
                </span>
                <Line/>


                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default GThumb;