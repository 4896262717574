import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function ImageMagick(){
    const related = [
        {
            href: '/css',
            name: 'CSS',
            side: 'full support',
            text: 'Learn how to detect JXL support and use JXL images within CSS, like for background images.'
        },
        {
            href: '/exiftool',
            name: 'ExifTool',
            side: 'full support',
            text: 'ExifTool is a platform-independent library. Learn how to read JXL images within ExifTool.'
        }, {
            href: '/exiv2',
            name: 'Exiv2',
            side: 'full support',
            text: 'Exiv2 is a C++ library and utility to read and write image metadata. Learn how to read JXL images within Exiv2.'
        }, {
            href: '/html', name: 'HTML', side: 'full support', text: 'Learn how to use JXL images within HTML.'
        }, {
            href: '/imagemagick',
            name: 'ImageMagick',
            side: 'full support',
            text: 'ImageMagick is an open-source software suite for editing images. Learn how to use JXL images within ImageMagick.'
        }, {
            href: '/kde-frameworks',
            name: 'KDE Frameworks',
            side: 'full support',
            text: 'KDE Frameworks are a set of libraries for programming with Qt. Learn how to use JXL images within KDE.'
        }, {
            href: '/libvips', name: 'libvips', side: 'full support', text: 'Learn how to use JXL images within libvips.'
        }, {
            href: '/php',
            name: 'PHP',
            side: 'partial support',
            text: 'PHP is a widely used scripting language. Learn how to use JXL images within PHP.'
        }, {
            href: '/rust',
            name: 'Rust',
            side: 'full support',
            text: 'Rust is a high-level, general-purpose programming language. Learn how to use JXL images within Rust.'
        }, {
            href: '/squoosh',
            name: 'squoosh',
            side: 'partial support',
            text: 'Squoosh is a free image converter from Google. Learn how to use JXL images with Squoosh.'
        }
    ]
    return (
        <>
            <Heading h='ImageMagick JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-imagemagick' text='What is ImageMagick?'/>
                        <LiContent n='2' a='#imagemagick-jxl-support' text='ImageMagick JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-imagemagick'>
                    <h1 className="main">What is ImageMagick?</h1>
                    <p className='umain'>You can use ImageMagick to create, edit, compose or convert digital images. It supports a wide range of image formats (over 200), including PNG, JPEG, GIF, WebP, HEIC, SVG, PDF, DPX, EXR, and TIFF. Images can be resized, flipped, mirrored, rotated, deformed, sheared, and transformed using ImageMagick. They can also be adjusted for colors, applied with various effects, or be made into lines, polygons, ellipses, or text. You may use, copy, modify, and distribute ImageMagick in both open-source and proprietary applications. ImageMagick is available either ready-to-run binary distribution or source code that you may use, copy, modify, and distribute. The software is distributed under a license derived from Apache 2.0. ImageMagick utilizes multiple computational threads to increase performance. It can read, process, and write images up to mega-, giga-, or terabytes in size.</p>
                </span>
                <Line/>

                <span id='imagemagick-jxl-support'>
                    <h1 className="main">ImageMagick JXL support</h1>
                    <p className='umain'>Since version 7.0.10-54, ImageMagick supports JPEG XL. You can utilize the format as you would with any other image codec.</p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default ImageMagick;