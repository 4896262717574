import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import FAQComponent from "../Components/FAQComponent/FAQComponent";
import React from "react";


function ShimmerCat() {
    const related = [
        {
            href: '/cloudinary',
            name: 'Cloudinary',
            side: 'partial support',
            text: 'Image CDNs help you serve the best image to users. Find out how you can use JPEG XL images in Cloudinary.'
        },
        {
            href: '/shimmercat',
            name: 'ShimmerCat',
            side: 'full support',
            text: 'Image CDNs help you serve the best image to users. Find out how you can use JPEG XL images in ShimmerCat.'
        }
    ]
    return (
        <>
            <Heading h='ShimmerCat AVIF support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='2' a='#what-is-shimmercat' text='What is ShimmerCat'/>
                        <LiContent n='3' a='#shimmercat-supports-jpeg-xl' text='ShimmerCat supports JPEG XL'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>With JPEG XL, you can save 60 percent in image size along with modern features like HDR, animation, alpha channel, lossless recompression, and lossless and progressive modes.</p>
                    <p className='umain'>The image quality and compression ratio of JPEG XL is significantly better than legacy JPEG. It is possible to quickly convert JPEGs from the past to the new format by using lossless JPEG recompression.</p>
                    <p className='umain'>Lossless, as well as lossy compression, are supported by JPEG XL. File sizes can be reduced by 20-60% using lossless compression. Using software implementations makes it possible to perform encoding and decoding efficiently without hardware acceleration.</p>
                    <p className='umain'>JPEG XL's support for HDR and animation, along with its small file size and progressive decoding mechanism, make it an ideal image format for the web. It has also attracted the attention of web developers, CDNs, and large tech companies like Google and Facebook.</p>
                </span>
                <Line/>


                <span id='what-is-shimmercat'>
                    <h1 className="main">What is ShimmerCat</h1>
                    <p className='umain'>ShimmerCat claims to reduce the file size by 70% when it converts, optimizes, and serves images in next-generation formats. Adding a fast, cost-efficient, and robust image CDN can be as simple as clicking a few buttons. There are only two Javascript scripts that need to be added to your web application, as well as a script tag. You can use their service worker to optimize and cache your images at their edge servers. This service utilizes the clouds you are already using to ensure low latency and proximity to your target audience. Shimmercat plans range between 100 and 10,000 dollars per month, which is a bit more expensive than other CDNs.</p>
                </span>
                <Line/>


                <span id='shimmercat-supports-jpeg-xl'>
                    <h1 className="main">ShimmerCat supports JPEG XL</h1>
                    <p className='umain'>ShimmerCat supports JPEG XL because it offers significantly superior compression and quality characteristics to the outdated and traditional PNG and JPEG formats. By encoding images in next-generation formats, you will improve the quality and user experience, decrease loading times, and consume less cellular data.</p>
                </span>
                <Line/>


                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default ShimmerCat