import './FAQComponent.css'
import LiContent from "../LiContent/LiContent";
import React from "react";

function FAQComponent(){
    return(
        <div className='faq_component'>
            <h5 className='faq_component_h5'>FAQ</h5>
            <ol className='links_to_faq'>
                <a href='/faq/#general-information' className='link_to_faq'>⏩GENERAL INFORMATION</a>
                <a href='/faq/#introduction' className='link_to_faq'>Introduction</a>
                <a href='/faq/#what-is-a-jpeg-xl-file' className='link_to_faq'>What is a JPEG XL file?</a>
                <a href='/faq/#what-is-the-file-extension-jxl' className='link_to_faq'>What is the file extension
                    JXL?</a>
                <a href='/faq/#benefits-of-jpeg-xl' className='link_to_faq'>What are the benefits of JPEG XL?</a>
                <a href='/faq/#downside-of-jpeg-xl' className='link_to_faq'>What is the downside of JPEG XL?</a>
                <a href='/faq/#need-for-jpeg-xl' className='link_to_faq'>Why is a new image format like JPEG XL
                    needed?</a>
                <a href='/faq/#primary-goal-of-jxl' className='link_to_faq'>What is the primary goal of JXL?</a>
                <a href='/faq/#creation-of-jpeg-xl' className='link_to_faq'>⏩CREATION OF JPEG XL</a>
                <a href='/faq/#who-created-jpeg-xl' className='link_to_faq'>Who created JPEG XL?</a>
                <a href='/faq/#key-contributors-of-jpeg-xl' className='link_to_faq'>Who are the key contributors of JPEG
                    XL?</a>
                <a href='/faq/#why-jpeg-xl' className='link_to_faq'>Why is JPEG XL called JPEG XL?</a>
                <a href='/faq/#timeline-of-jpeg-xl' className='link_to_faq'>Since when does JPEG XL exist?</a>
                <a href='/faq/#timeline-of-jpeg-xl2' className='link_to_faq'>What is the timeline of JPEG XL?</a>
                <a href='/faq/#based-on-other-formats' className='link_to_faq'>Is JPEG XL based on other formats?</a>
                <a href='/faq/#royalty-free' className='link_to_faq'>Is JPEG XL royalty-free?</a>
                <a href='/faq/#standard-for-jpeg-xl' className='link_to_faq'>Is there a Standard for JPEG XL?</a>
                <a href='/faq/#basic-features' className='link_to_faq'>⏩BASIC FEATURES</a>
                <a href='/faq/#features-of-jpeg-xl' className='link_to_faq'>What features does JPEG XL support?</a>
                <a href='/faq/#alpha-transparency' className='link_to_faq'>Does JPEG XL support alpha transparency?</a>
                <a href='/faq/#animation' className='link_to_faq'>Does JPEG XL support animation?</a>
                <a href='/faq/#progressive-decoding' className='link_to_faq'>Does JXL support progressive decoding?</a>
                <a href='/faq/#hdr' className='link_to_faq'>Does JPEG XL support HDR?</a>
                <a href='/faq/#highest-megapixel-count' className='link_to_faq'>What is the highest megapixel count for
                    JPEG XL?</a>
                <a href='/faq/#compression-features' className='link_to_faq'>⏩COMPRESSION FEATURES</a>
                <a href='/faq/#optimize-images' className='link_to_faq'>How does JPEG XL optimize my images?</a>
                <a href='/faq/#lossless-compression' className='link_to_faq'>Does JPEG XL support lossless
                    compression?</a>
                <a href='/faq/#lossy-compression' className='link_to_faq'>Does JPEG XL support lossy compression?</a>
                <a href='/faq/#low-fidelity-images' className='link_to_faq'>Is JPEG XL suitable for low-fidelity
                    images?</a>
                <a href='/faq/#medium-fidelity-images' className='link_to_faq'>Is JPEG XL suitable for medium-fidelity
                    images?</a>
                <a href='/faq/#high-fidelity-images' className='link_to_faq'>Is JPEG XL suitable for high-fidelity
                    images?</a>
                <a href='/faq/#non-photographic-images' className='link_to_faq'>Is JPEG XL suitable for non-photographic
                    images?</a>
                <a href='/faq/#lossless-file-size-save' className='link_to_faq'>How much file size does lossless JPEG XL
                    save?</a>
                <a href='/faq/#lossy-file-size-save' className='link_to_faq'>How much file size does lossy JPEG XL
                    save?</a>
                <a href='/faq/#vast-header' className='link_to_faq'>Does JPEG XL have a vast header like AVIF?</a>
                <a href='/faq/#speed-features' className='link_to_faq'>⏩SPEED FEATURES</a>
                <a href='/faq/#single-core-encode-speed' className='link_to_faq'>Single-core encode speed</a>
                <a href='/faq/#single-core-decode-speed' className='link_to_faq'>Single-core decode speed</a>
                <a href='/faq/#parallelizable' className='link_to_faq'>Parallelizable</a>
                <a href='/faq/#multi-core-speed' className='link_to_faq'>Multi-core speed</a>
                <a href='/faq/#progressive-image' className='link_to_faq'>Progressive image based on DCT</a>
                <a href='/faq/#advanced-features' className='link_to_faq'>⏩ADVANCED FEATURES</a>
                <a href='/faq/#lossless-transcoding' className='link_to_faq'>Lossless transcoding from JPEG</a>
                <a href='/faq/#high-fidelity-images' className='link_to_faq'>Does JPEG XL work with high fidelity
                    images?</a>
                <a href='/faq/#max-bit-depth' className='link_to_faq'>What is the max bit depth of JPEG XL?</a>
                <a href='/faq/#color-spaces' className='link_to_faq'>What color spaces does JXL support?</a>
                <a href='/faq/#xyb-color-space' className='link_to_faq'>What is the XYB color space?</a>
                <a href='/faq/#generation-loss-resilience' className='link_to_faq'>Generation Loss Resilience</a>
                <a href='/faq/#automatic-decoding' className='link_to_faq'>Full automatic decoding</a>
                <a href='/faq/#film-grain' className='link_to_faq'>Does JPEG XL support film grain?</a>
                <a href='/faq/#max-channels' className='link_to_faq'>What is the maximum number of channels for JPEG
                    XL?</a>
                <a href='/faq/#depth-map' className='link_to_faq'>Does JXL support depth map?</a>
                <a href='/faq/#overlays' className='link_to_faq'>Does JXL support overlays?</a>
                <a href='/faq/#expert-questions' className='link_to_faq'>⏩EXPERT QUESTIONS</a>
                <a href='/faq/#encoder-architecture' className='link_to_faq'>What does the encoder architecture for JPEG
                    XL look like?</a>
                <a href='/faq/#coding-modes' className='link_to_faq'>What coding modes does JPEG XL have?</a>
                <a href='/faq/#coding-tools' className='link_to_faq'>What coding tools does JPEG XL provide?</a>
                <a href='/faq/#variable-block-sizes' className='link_to_faq'>Does JPEG XL use variable block sizes?</a>
                <a href='/faq/#patches' className='link_to_faq'>What are Patches in JPEG XL?</a>
                <a href='/faq/#adaptive-quantization' className='link_to_faq'>What is Adaptive quantization?</a>
                <a href='/faq/#adaptive-predictor' className='link_to_faq'>What is an Adaptive predictor?</a>
                <a href='/faq/#handle-artifacts-banding' className='link_to_faq'>How does JPEG XL handle artifacts and
                    banding?</a>
                <a href='/faq/#prevent-banding-artifacts' className='link_to_faq'>What else does JPEG XL do to prevent
                    banding and artifacts?</a>
                <a href='/faq/#loop-filters' className='link_to_faq'>What are Loop Filters?</a>
                <a href='/faq/#ac-encoding' className='link_to_faq'>AC Encoding</a>
                <a href='/faq/#entropy-coding' className='link_to_faq'>Entropy Coding</a>
                <a href='/faq/#working-with-jpeg-xl' className='link_to_faq'>⏩WORKING WITH JPEG XL</a>
                <a href='/faq/#use-cases' className='link_to_faq'>What are the use cases for JPEG XL?</a>
                <a href='/faq/#can-i-use-jpeg-xl' className='link_to_faq'>Can I use JPEG XL?</a>
                <a href='/faq/#should-i-use-jpeg-xl' className='link_to_faq'>Should I use JPEG XL?</a>
                <a href='/faq/#use-jpeg-xl-website' className='link_to_faq'>Should I use JPEG XL for my website?</a>
                <a href='/faq/#new-hardware' className='link_to_faq'>Do I need new hardware to encode and decode JPEG
                    XL?</a>
                <a href='/faq/#convert-images' className='link_to_faq'>How can I convert images to JPEG XL?</a>
                <a href='/faq/#recompress-jpg' className='link_to_faq'>Can I recompress my JPG images to JXL
                    losslessly?</a>
                <a href='/faq/#jpeg-xl-support' className='link_to_faq'>⏩JPEG XL SUPPORT</a>
                <a href='/faq/#image-viewers' className='link_to_faq'>What image viewers support JPEG XL?</a>
                <a href='/faq/#browsers' className='link_to_faq'>What browsers support JPEG XL?</a>
                <a href='/faq/#cdns' className='link_to_faq'>What CDNs support JPEG XL?</a>
                <a href='/faq/#operating-systems' className='link_to_faq'>What Operating system support JPEG XL?</a>
                <a href='/faq/#mobile-phones' className='link_to_faq'>Do mobile phones support JPEG XL?</a>
                <a href='/faq/#compare-jpeg-xl-with-jpeg' className='link_to_faq'>Compare JPEG XL with JPEG</a>
            </ol>
        </div>
    )
}

export default FAQComponent