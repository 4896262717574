import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";

function DigiKam(){
    const related = [
        {
            href: '/digikam',
            name: 'digiKam',
            side: 'full support',
            text: 'digiKam is an advanced photo management application. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/geeqie',
            name: 'Geeqie',
            side: 'full support',
            text: 'Geeqie is a free software image viewer. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/gimp',
            name: 'GIMP',
            side: 'partial support',
            text: 'GIMP is a free image editing software. Learn how to read and save JXL images within GIMP.'
        },
        {
            href: '/gthumb',
            name: 'gThumb',
            side: 'full support',
            text: 'gThumb is an image viewer and browser for the GNOME Desktop. Learn how to use JXL images within gThumb.'
        },
        {
            href: '/gwenview',
            name: 'Gwenview',
            side: 'full support',
            text: 'Gwenview is a fast and easy to use image viewer by KDE. Learn how to use JXL images within Geeqie.'
        },
        {
            href: '/imageglass',
            name: 'ImageGlass',
            side: 'full support',
            text: 'ImageGlass is a free-to-use image viewer. Learn how to use JXL images within ImageGlass.'
        },
        {
            href: '/irfanview',
            name: 'IrfanView',
            side: 'partial support',
            text: 'IrfanView is an open source image viewer. Learn how to use JXL images within IrfanView.'
        },
        {
            href: '/nomacs',
            name: 'nomacs',
            side: 'partial support',
            text: 'nomacs is an open source image viewer. Learn how to use JXL images within nomacs.'
        },
        {
            href: '/photoqt',
            name: 'PhotoQt',
            side: 'full support',
            text: 'PhotoQt is a free-to-use image viewer. Learn how to use JXL images within PhotoQt.'
        },
        {
            href: '/photostack',
            name: 'PhotoStack',
            side: 'full support',
            text: 'PhotoStack is a free-to-use image viewer. Learn how to use JXL images within PhotoStack.'
        },
        {
            href: '/picview',
            name: 'PicView',
            side: 'full support',
            text: 'PicView is a free-to-use image viewer. Learn how to use JXL images within PicView.'
        },
        {
            href: '/qimgv',
            name: 'qimgv',
            side: 'full support',
            text: 'qimgv is a fast and free image viewer. Learn how to use JXL images within qimgv.'
        },
        {
            href: '/xnview',
            name: 'XnView',
            side: 'full support',
            text: 'View, resize and edit your photos - with JXL support! Learn how to use JXL images within XnView.'
        }
    ]
    return (
        <>
            <Heading h='digiKam JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-digikam' text='What is digiKam?'/>
                        <LiContent n='2' a='#digikam-jxl-support' text='digiKam JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-digikam'>
                    <h1 className="main">What is digiKam?</h1>
                    <p className='umain'>In addition to managing many images and videos, digiKam also provides integrated tools for editing and tagging.</p>
                    <p className='umain'>It is available as a Linux, FreeBSD and Windows package.</p>
                    <p className='umain'>DigiKam supports direct photo, raw file, and video transfers from your camera and external storage devices (SD cards, USB disks, etc.). It allows you to set import settings and rules that organize and process imported items automatically.</p>
                    <p className='umain'>Using digiKam, you can organize photos, raw files, and videos into albums. In addition, the application has powerful tagging tools for assigning tags, ratings, and labels to pictures and raw files. By using filtering, you can quickly find items that match specific criteria. Additionally, digiKam offers powerful search capabilities that let you search the photo library by various criteria. Images can be searched using tags, labels, ratings, data, location, and even specific metadata such as EXIF, IPTC, and XMP. Several criteria can also be combined for more advanced searches.</p>
                    <p className='umain'>In digiKam, the album view is the starting point. Images from your collection can be tagged, geo-dated, categorized, and rated here. By using the displayed tree view on the left side of the window, you can view your images at any time and open them with a single click. Among the special features is batch editing, which lets you rename, edit, and optimize many images at once. With digiKam's image editing tools, you can manipulate photos, perform image repairs, add effects, and so much more. Additionally, you can automatically identify people from photographs using the face recognition feature.</p>
                </span>
                <Line/>

                <span id='digikam-jxl-support'>
                    <h1 className="main">digiKam JXL support</h1>
                    <p className='umain'>As digiKam has an ImageMagick image loader and ExifTool support, the JPEG XL format can be already supported as well. Go to Setup/configure/Plugin/Image Loader page and look in the IM supported formats.</p>
                </span>
                <Line/>


                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default DigiKam