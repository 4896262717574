import React, {useRef, useState} from 'react';
import './DragFile.css';
import arrow from '../../images/arrow.svg';
import settings from '../../images/settings.svg';
import axios from "axios";
import WhatIs from "../WhatIs/WhatIs";

function DragFile() {
    const [set, setSet] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const [downloadLinks, setDownloadLinks] = useState([]);
    const [settingsData, setSettingsData] = useState({
        quality: '',
        distance: '3',  // default distance value
        effort: '7',    // default effort value
        progressive: false,
        modular: '0'    // default modular value
    });
    const inputRef = useRef(null);


    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const filesArray = Array.from(e.dataTransfer.files);
            sendFiles(filesArray);
        }
    };

    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            const filesArray = Array.from(e.target.files);
            sendFiles(filesArray);
        }
    };

    const sendFiles = async (filesArray) => {
        for (let i = 0; i < filesArray.length; i++) {
            const formData = new FormData();
            formData.append('image', filesArray[i]);
            formData.append('quality', settingsData.quality);
            formData.append('distance', settingsData.distance);
            formData.append('effort', settingsData.effort);
            formData.append('progressive', settingsData.progressive);
            formData.append('modular', settingsData.modular);

            try {
                const response = await axios.post("https://176.223.132.38/upload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const downloadLink = response.data;
                setDownloadLinks(prevLinks => [...prevLinks, {
                    downloadLink,
                    name: filesArray[i].name,
                    size: filesArray[i].size
                }]);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleSettingsChange = (e) => {
        const {name, value, type, checked} = e.target;
        setSettingsData(prevSettings => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const styles = {
        style1: {
            animation: 'animation: rotate360 2s linear infinite',
            filter: 'invert(62%) sepia(10%) saturate(2195%) hue-rotate(130deg) brightness(91%) contrast(94%)'
        },
        style2: {
            animation: 'animation: rotate360 2s linear infinite',
            filter: 'none'
        }
    }

    const styles2 = {
        style1: {
            display: 'flex'
        },
        style2: {
            display: 'none'
        }
    }
    const hoverFunc = () => {
        setStylesHover(styles2.style1)
    }

    const [stylesHover, setStylesHover] = useState(styles2.style2);
    const handleSet = () => {
        setSet(!set);
        if (set) {
            setStyle(styles.style2);
        } else {
            setStyle(styles.style1);
        }
    };

    let [style, setStyle] = useState(styles.style2);

    const onButtonClick = () => {
        inputRef.current.click();
    };

    function bytesToMB(bytes) {
        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2);
    }

    return (
        <div className='converter'>
            {set &&
                <div className='settings_menu'>
                    <h2>Settings</h2>
                    <p>Settings don't change a running conversion.</p>
                    <h3>Approach</h3>
                    <div className='group'>
                        <div className="setting-group">
                            <label>
                                <input
                                    type="radio"
                                    name="conversionType"
                                    value="quality"
                                    checked={settingsData.quality !== ''}
                                    onChange={() => setSettingsData(prev => ({...prev, quality: '75', distance: ''}))}
                                />
                                Quality
                                <WhatIs
                                    text='Convert images based on fixed quality parameter. 100 = mathematically lossless. Higher equals higher quality.'/>
                            </label>
                            {settingsData.quality !== '' && (<div className='ranged'>
                                    <input
                                        type="range"
                                        name="quality"
                                        min="0"
                                        max="100"
                                        value={settingsData.quality}
                                        onChange={handleSettingsChange}
                                    />
                                    <span className="range-value">{settingsData.quality}</span>
                                </div>
                            )}

                        </div>
                        <div className="setting-group">
                            <label>
                                <input
                                    type="radio"
                                    name="conversionType"
                                    value="distance"
                                    checked={settingsData.distance !== ''}
                                    onChange={() => setSettingsData(prev => ({...prev, distance: '1', quality: ''}))}
                                />
                                Distance
                                <WhatIs
                                    text='Convert images based on psychovisual similarity. 0 equals mathematically losless. 1 equals visually lossless. Higher numbers equal less quality.'/>
                            </label>
                            {settingsData.distance !== '' && (<div className='ranged'>
                                <input
                                    type="range"
                                    name="distance"
                                    min="0"
                                    max="25"
                                    step="0.1"
                                    value={settingsData.distance}
                                    onChange={handleSettingsChange}
                                />
                                <span className="range-value">{settingsData.distance}</span>
                            </div>)}
                        </div>
                    </div>
                    <h3>Speed</h3>
                    <div className='group'>
                        <div className="setting-group">
                            <label>Effort
                                <WhatIs
                                    text='Processing power. More effort equals longer time of conversion, but better compression.'/>
                            </label>
                            <div className='ranged'>
                                <input
                                    type="range"
                                    name="effort"
                                    min="1"
                                    max="10"
                                    value={settingsData.effort}
                                    onChange={handleSettingsChange}
                                />
                                <span className="range-value">{settingsData.effort}</span>
                            </div>
                        </div>
                    </div>
                    <h3>Expert settings</h3>
                    <div className='group'>
                        <div className="setting-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="progressive"
                                    checked={settingsData.progressive}
                                    onChange={handleSettingsChange}
                                />
                                Progressive <WhatIs
                                text='Enable progressive / responsive decoding.'/>
                            </label>
                        </div>
                        <div className="setting-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="modular"
                                    value="1"
                                    checked={settingsData.modular === '1'}
                                    onChange={e => setSettingsData(prev => ({
                                        ...prev,
                                        modular: e.target.checked ? '1' : '0'
                                    }))}
                                />
                                Modular
                                <WhatIs
                                    text='Use the modular mode (lossy / lossless)'/>
                            </label>
                        </div>
                    </div>
                </div>
            }
            <div className='converter_content'>
                <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                    <div id='settings'>
                        <img src={settings} style={style} onClick={handleSet} className={set ? 'rotated' : 'not'}/>
                    </div>
                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange}/>
                    <label id="label-file-upload" htmlFor="input-file-upload"
                           className={dragActive ? "drag-active" : ""}>
                        <div id='text'>
                            <button className="upload-button" onClick={onButtonClick}></button>
                            <p><b>Drop images or browse</b></p>
                            <p>supports png · jpg · gif · webp · avif</p>
                            <img src={arrow}/>
                        </div>
                    </label>
                    {dragActive &&
                        <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                             onDragOver={handleDrag} onDrop={handleDrop}></div>}
                </form>
            </div>

            {downloadLinks.map((linkObj, index) => (
                <div className='download' key={index}>
                    <div><p>{index + 1}</p>
                        <p>{linkObj.name} | {bytesToMB(linkObj.size)} MB</p></div>
                    <a href={linkObj.downloadLink} target="_blank" rel="noreferrer">Download</a>
                </div>
            ))}
        </div>
    );
};

export default DragFile;
