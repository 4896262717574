import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import jxl from '../../images/frog.jxl'
import React from "react";
import vivaldi from '../../images/vivaldi.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function Vivaldi() {
    const related = [
        {
            href: '/brave',
            name: 'Brave',
            side: 'partial support',
            text: 'Brave has JPEG XL support, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/chrome',
            name: 'Chrome',
            side: 'partial support',
            text: 'Google introduced JXL support for Chrome, but there are still some limitations. We show you how to use it.'
        },
        {
            href: '/edge',
            name: 'Edge',
            side: 'partial support',
            text: 'Edge is the latest browser from Microsoft. Learn how to use JXL images within Edge.'
        },
        {
            href: '/firefox',
            name: 'Firefox',
            side: 'partial support',
            text: 'Firefox is the largest not-for-profit browser. Learn how to use JXL images within Firefox.'
        },
        {
            href: '/opera',
            name: 'Opera',
            side: 'partial support',
            text: 'Opera is a fast and safe browser alternative. Learn how to use JXL images within Opera.'
        },
        {
            href: '/safari',
            name: 'Safari',
            side: 'no support',
            text: 'Is Safari adding JPEG XL support in 2022? Find out more about the current state.'
        },
        {
            href: '/vivaldi',
            name: 'Vivaldi',
            side: 'partial support',
            text: 'Vivaldi has JPEG XL support, but there are still some limitations. We show you how to use it.'
        }
    ]
    return (
        <>
            {/*<p className='umain'>&#10004; </p>*/}
            {/*<a className='ugreen' href='#'></a>*/}
            {/*<p className='umain'></p>*/}
            {/*<img src={jxl} alt='your browser may show this JXL img if you enabled support'/>*/}
            <Heading h='Vivaldi JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#what-is-jpeg-xl' text='What is JPEG XL?'/>
                        <LiContent n='3' a='#what-is-vivaldi' text='What is Vivaldi?'/>
                        <LiContent n='4' a='#what-are-vivaldi-flags' text='What are Vivaldi Flags?'/>
                        <LiContent n='5' a='#vivaldi-jpeg-xl-tutorial' text='Vivaldi JPEG XL Tutorial'/>
                        <LiContent n='6' a='#vivaldi-jpeg-xl-test-image' text='Vivaldi JPEG XL Test Image'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className='umain'>Those who are interested will find an introduction explaining Vivaldi and JPEG XL below for SEO purposes. You can jump to the tutorial on <a
                        className='ugreen' href='#vivaldi-jpeg-xl-tutorial'>how to enable JPEG XL support on Vivaldi by clicking here.</a></p>
                </span>
                <Line/>

                <span id='what-is-jpeg-xl'>
                    <h1 className="main">What is JPEG XL?</h1>
                    <p className='umain'>JPEG XL is a professional image delivery format that is useful for web photographers and web designers. Additionally, it supports images with a high bit depth in addition to wide color gamuts and high dynamic ranges. Among the many features of JPEG XL are animation, alpha channels, layers, thumbnails, lossless decoding, and progressive decoding.</p>
                    <p className='umain'>The image quality and compression ratio of JPEG XL are significantly better than those of legacy JPEG. JPEGs created in the past can be quickly converted to the new format by implementing lossless JPEG recompression.</p>
                    <p className='umain'>The JPEG XL format supports lossless and lossy compression. Lossless compression tends to reduce filesizes by 20-60%. Encoding and decoding can be computationally efficient using software implementations.</p>
                </span>
                <Line/>

                <span id='what-is-vivaldi'>
                    <h1 className="main">What is Vivaldi?</h1>
                    <p className='umain'>Vivaldi is a freeware, cross-platform web browser developed by Vivaldi Technologies, founded by former Opera CEO and co-founder Tatsuki Tomita. On April 6, 2016, Vivaldi was officially launched.</p>
                    <p className='umain'>The program is intended to be used by anyone. Still, its primary audience is technical users who might have been disgruntled by Opera's switch from its Presto layout engine to a Chromium-based browser. Even though Vivaldi is also based on Chrome, it aims to revive the features of the Presto-based Opera.</p>
                    <p className='umain'>Vivaldi has more than 2.3 million active users as of September 2021.</p>
                </span>
                <Line/>

                <span id='what-are-vivaldi-flags'>
                    <h1 className="main">What are Vivaldi Flags?</h1>
                    <p className='umain'>Flags represent Vivaldi features that are currently being developed. Those can be found in the hidden settings menu accessible from the navigation bar. A flag must be set for JPEG XL, so the functionality is disabled by default. It is not known when Vivaldi's default behavior will include JPEG XL support.</p>
                </span>
                <Line/>

                <span id='vivaldi-jpeg-xl-tutorial'>
                    <h1 className="main">Vivaldi JPEG XL Tutorial</h1>
                    <p className='umain'>&#10004; Ensure you have at least Vivaldi version installed.</p>
                    <p className='umain'>&#10004; Open a new browser tab.</p>
                    <p className='umain'>&#10004; Type the following: vivaldi://flags#enable-jxl</p>
                    <p className='umain'>&#10004; The first and marked result shows an entry called Enable JXL image format with the description Adds image decoding support for the JPEG XL image format. Mac, Windows, Linux, Chrome OS, Android and the hashtag #enable-jxl.</p>
                    <p className='umain'>&#10004; On the right, click the drop-down menu and select Enable to enable the support. After completing the process, you will be prompted to restart your web browser for the changes to take effect.</p>
                    <img src={vivaldi} className='code5'/>
                    <br/>
                    <br/>
                </span>
                <Line/>

                <span id='vivaldi-jpeg-xl-test-image'>
                    <h1 className="main">Vivaldi JPEG XL Test Image</h1>
                    <p className='umain'>Upon re-launching the browser, return to this website to see this incredible frog image:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                    <br/>
                    <br/>
                    <p className='umain'><b>Congratulations!</b></p>
                </span>
                <Line/>

                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>)
}

export default Vivaldi