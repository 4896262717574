import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import RelatedT from "../Components/RelatedArticles/Related";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function KaOS(){
    const related = [
        {
            href: '/kaos',
            name: 'KaOS',
            side: 'full support',
            text: 'KaOS is a community Linux distribution. Learn how to use JXL images within KaOS.'
        },
        {
            href: '/macos',
            name: 'macOS',
            side: 'partial support',
            text: 'macOS does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
        {
            href: '/openmandriva',
            name: 'OpenMandriva',
            side: 'full support',
            text: 'OpenMandriva is a community Linux distribution. Learn how to use JXL images within OpenMandriva.'
        },
        {
            href: '/windows',
            name: 'Windows',
            side: 'partial support',
            text: 'Windows does not natively support JPEG XL. Learn how to still view files in the explorer and open them.'
        },
    ]
    return(
        <>
            <Heading h='KaOS JPEG XL support' p='#tutorials'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#what-is-kaos' text='What is KaOS?'/>
                        <LiContent n='2' a='#kaos-jxl-support' text='KaOS JXL support'/>
                    </ol>
                </nav>
                <br/>
                <Line/>

                <span id='what-is-kaos'>
                    <h1 className="main">What is KaOS?</h1>
                    <p className='umain'>KaOS is a Linux distribution developed from the ground up with a distinct focus on Qt and KDE. This operating system is distributed as an ISO image, and it supports only 64-bit x86 processors. The purpose of KaOS is to create an integrated and transparent distribution for a modern desktop, built from scratch and with a particular focus. While KaOS is presently based on the Linux kernel, the developers are "continually evaluating" the illumos kernel and say that "a future switch is a possibility". Emphasizes one desktop environment (KDE Plasma), one toolkit (Qt), and one architecture (x86_64) as well as evaluating and selecting the most suitable applications and tools.</p>
                </span>
                <Line/>

                <span id='kaos-jxl-support'>
                    <h1 className="main">KaOS JXL support</h1>
                    <p className='umain'>With the release of version 2021.04 of KaOS's rolling release distribution, its package manager "kcp" has been further revised, JPEG XL images are supported, many software packages have been updated, and Plasma 5.21.30 is the default Desktop Environment.</p>
                    <p className='umain'>The KDE Applications 21.04.0, which are known as KDE Gears, are also included. KDE Frameworks 5.81.0 and Qt 5.15.2 serve as the foundation. The KaOS team receives the patches published by the KDE project.</p>
                    <p className='umain'>Integration is provided by the qt-jpegxl-image-plugin, which enables the opening & viewing of .jxl images in Gwenview.</p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={related}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default KaOS