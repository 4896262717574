import React from "react";

function ConvertButton() {
    return (
        <a className='convert_button' href='/#convert'>
            <div className='glow'></div>
            <p>
                Profit from a faster website, higher ranking and better conversions.
            </p>
            <h1>
                Convert your images to JPEGXL for free.
            </h1>
        </a>

    )
}

export default ConvertButton;