import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import ConvertButton from "../Components/ConvertButton";
import React, {useEffect, useRef} from "react";
import jxl from "../../images/frog.jxl";
import img1 from '../../images/jpegxl-architecture-768.avif'
import RelatedT from "../Components/RelatedTutorials/Related";
import { useLocation } from 'react-router-dom';

function FAQ() {
    const location = useLocation();

    const refs = {
        'general-information': useRef(null),
        'introduction': useRef(null),
        'what-is-a-jpeg-xl-file': useRef(null),
        'what-is-the-file-extension-jxl': useRef(null),
        'benefits-of-jpeg-xl': useRef(null),
        'downside-of-jpeg-xl': useRef(null),
        'need-for-jpeg-xl': useRef(null),
        'primary-goal-of-jxl': useRef(null),
        'creation-of-jpeg-xl': useRef(null),
        'who-created-jpeg-xl': useRef(null),
        'key-contributors-of-jpeg-xl': useRef(null),
        'why-jpeg-xl': useRef(null),
        'timeline-of-jpeg-xl': useRef(null),
        'timeline-of-jpeg-xl2': useRef(null),
        'based-on-other-formats': useRef(null),
        'royalty-free': useRef(null),
        'standard-for-jpeg-xl': useRef(null),
        'basic-features': useRef(null),
        'features-of-jpeg-xl': useRef(null),
        'alpha-transparency': useRef(null),
        'animation': useRef(null),
        'progressive-decoding': useRef(null),
        'hdr': useRef(null),
        'highest-megapixel-count': useRef(null),
        'compression-features': useRef(null),
        'optimize-images': useRef(null),
        'lossless-compression': useRef(null),
        'lossy-compression': useRef(null),
        'low-fidelity-images': useRef(null),
        'medium-fidelity-images': useRef(null),
        'high-fidelity-images': useRef(null),
        'non-photographic-images': useRef(null),
        'lossless-file-size-save': useRef(null),
        'lossy-file-size-save': useRef(null),
        'vast-header': useRef(null),
        'speed-features': useRef(null),
        'single-core-encode-speed': useRef(null),
        'single-core-decode-speed': useRef(null),
        'parallelizable': useRef(null),
        'multi-core-speed': useRef(null),
        'progressive-image': useRef(null),
        'advanced-features': useRef(null),
        'lossless-transcoding': useRef(null),
        'max-bit-depth': useRef(null),
        'color-spaces': useRef(null),
        'xyb-color-space': useRef(null),
        'generation-loss-resilience': useRef(null),
        'automatic-decoding': useRef(null),
        'film-grain': useRef(null),
        'max-channels': useRef(null),
        'depth-map': useRef(null),
        'overlays': useRef(null),
        'expert-questions': useRef(null),
        'encoder-architecture': useRef(null),
        'coding-modes': useRef(null),
        'coding-tools': useRef(null),
        'variable-block-sizes': useRef(null),
        'patches': useRef(null),
        'adaptive-quantization': useRef(null),
        'adaptive-predictor': useRef(null),
        'handle-artifacts-banding': useRef(null),
        'prevent-banding-artifacts': useRef(null),
        'loop-filters': useRef(null),
        'ac-encoding': useRef(null),
        'entropy-coding': useRef(null),
        'working-with-jpeg-xl': useRef(null),
        'use-cases': useRef(null),
        'can-i-use-jpeg-xl': useRef(null),
        'should-i-use-jpeg-xl': useRef(null),
        'use-jpeg-xl-website': useRef(null),
        'new-hardware': useRef(null),
        'convert-images': useRef(null),
        'recompress-jpg': useRef(null),
        'jpeg-xl-support': useRef(null),
        'image-viewers': useRef(null),
        'browsers': useRef(null),
        'cdns': useRef(null),
        'operating-systems': useRef(null),
        'mobile-phones': useRef(null),
        'compare-jpeg-xl-with-jpeg': useRef(null)
    };

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        if (hash && refs[hash] && refs[hash].current) {
            refs[hash].current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location, refs]);


    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];
    return (<>
        <Heading h='JPEG XL FAQ: Everything You Need to Know!' p='#articles'/>
        <article>
            <nav className='content_table'>
                <h4>Table of Content</h4>
                <ol className='content_ol'>
                    <LiContent n='1' a='#general-information' text='⏩GENERAL INFORMATION'/>
                    <LiContent n='2' a='#introduction' text='Introduction'/>
                    <LiContent n='3' a='#what-is-a-jpeg-xl-file' text='What is a JPEG XL file?'/>
                    <LiContent n='4' a='#what-is-the-file-extension-jxl' text='What is the file extension JXL?'/>
                    <LiContent n='5' a='#benefits-of-jpeg-xl' text='What are the benefits of JPEG XL?'/>
                    <LiContent n='6' a='#downside-of-jpeg-xl' text='What is the downside of JPEG XL?'/>
                    <LiContent n='7' a='#need-for-jpeg-xl' text='Why is a new image format like JPEG XL needed?'/>
                    <LiContent n='8' a='#primary-goal-of-jxl' text='What is the primary goal of JXL?'/>
                    <LiContent n='9' a='#creation-of-jpeg-xl' text='⏩CREATION OF JPEG XL'/>
                    <LiContent n='10' a='#who-created-jpeg-xl' text='Who created JPEG XL?'/>
                    <LiContent n='11' a='#key-contributors-of-jpeg-xl'
                               text='Who are the key contributors of JPEG XL?'/>
                    <LiContent n='12' a='#why-jpeg-xl' text='Why is JPEG XL called JPEG XL?'/>
                    <LiContent n='13' a='#timeline-of-jpeg-xl' text='Since when does JPEG XL exist?'/>
                    <LiContent n='14' a='#timeline-of-jpeg-xl2' text='What is the timeline of JPEG XL?'/>
                    <LiContent n='15' a='#based-on-other-formats' text='Is JPEG XL based on other formats?'/>
                    <LiContent n='16' a='#royalty-free' text='Is JPEG XL royalty-free?'/>
                    <LiContent n='17' a='#standard-for-jpeg-xl' text='Is there a Standard for JPEG XL?'/>
                    <LiContent n='18' a='#basic-features' text='⏩BASIC FEATURES'/>
                    <LiContent n='19' a='#features-of-jpeg-xl' text='What features does JPEG XL support?'/>
                    <LiContent n='20' a='#alpha-transparency' text='Does JPEG XL support alpha transparency?'/>
                    <LiContent n='21' a='#animation' text='Does JPEG XL support animation?'/>
                    <LiContent n='22' a='#progressive-decoding' text='Does JXL support progressive decoding?'/>
                    <LiContent n='23' a='#hdr' text='Does JPEG XL support HDR?'/>
                    <LiContent n='24' a='#highest-megapixel-count'
                               text='What is the highest megapixel count for JPEG XL?'/>
                    <LiContent n='25' a='#compression-features' text='⏩COMPRESSION FEATURES'/>
                    <LiContent n='26' a='#optimize-images' text='How does JPEG XL optimize my images?'/>
                    <LiContent n='27' a='#lossless-compression' text='Does JPEG XL support lossless compression?'/>
                    <LiContent n='28' a='#lossy-compression' text='Does JPEG XL support lossy compression?'/>
                    <LiContent n='29' a='#low-fidelity-images' text='Is JPEG XL suitable for low-fidelity images?'/>
                    <LiContent n='30' a='#medium-fidelity-images'
                               text='Is JPEG XL suitable for medium-fidelity images?'/>
                    <LiContent n='31' a='#high-fidelity-images'
                               text='Is JPEG XL suitable for high-fidelity images?'/>
                    <LiContent n='32' a='#non-photographic-images'
                               text='Is JPEG XL suitable for non-photographic images?'/>
                    <LiContent n='33' a='#lossless-file-size-save'
                               text='How much file size does lossless JPEG XL save?'/>
                    <LiContent n='34' a='#lossy-file-size-save' text='How much file size does lossy JPEG XL save?'/>
                    <LiContent n='35' a='#vast-header' text='Does JPEG XL have a vast header like AVIF?'/>
                    <LiContent n='36' a='#speed-features' text='⏩SPEED FEATURES'/>
                    <LiContent n='37' a='#single-core-encode-speed' text='Single-core encode speed'/>
                    <LiContent n='38' a='#single-core-decode-speed' text='Single-core decode speed'/>
                    <LiContent n='39' a='#parallelizable' text='Parallelizable'/>
                    <LiContent n='40' a='#multi-core-speed' text='Multi-core speed'/>
                    <LiContent n='41' a='#progressive-image' text='Progressive image based on DCT'/>
                    <LiContent n='42' a='#advanced-features' text='⏩ADVANCED FEATURES'/>
                    <LiContent n='43' a='#lossless-transcoding' text='Lossless transcoding from JPEG'/>
                    <LiContent n='44' a='#high-fidelity-images'
                               text='Does JPEG XL work with high fidelity images?'/>
                    <LiContent n='45' a='#max-bit-depth' text='What is the max bit depth of JPEG XL?'/>
                    <LiContent n='46' a='#color-spaces' text='What color spaces does JXL support?'/>
                    <LiContent n='47' a='#xyb-color-space' text='What is the XYB color space?'/>
                    <LiContent n='48' a='#generation-loss-resilience' text='Generation Loss Resilience'/>
                    <LiContent n='49' a='#automatic-decoding' text='Full automatic decoding'/>
                    <LiContent n='50' a='#film-grain' text='Does JPEG XL support film grain?'/>
                    <LiContent n='51' a='#max-channels' text='What is the maximum number of channels for JPEG XL?'/>
                    <LiContent n='52' a='#depth-map' text='Does JXL support depth map?'/>
                    <LiContent n='53' a='#overlays' text='Does JXL support overlays?'/>
                    <LiContent n='54' a='#expert-questions' text='⏩EXPERT QUESTIONS'/>
                    <LiContent n='55' a='#encoder-architecture'
                               text='What does the encoder architecture for JPEG XL look like?'/>
                    <LiContent n='56' a='#coding-modes' text='What coding modes does JPEG XL have?'/>
                    <LiContent n='57' a='#coding-tools' text='What coding tools does JPEG XL provide?'/>
                    <LiContent n='58' a='#variable-block-sizes' text='Does JPEG XL use variable block sizes?'/>
                    <LiContent n='59' a='#patches' text='What are Patches in JPEG XL?'/>
                    <LiContent n='60' a='#adaptive-quantization' text='What is Adaptive quantization?'/>
                    <LiContent n='61' a='#adaptive-predictor' text='What is an Adaptive predictor?'/>
                    <LiContent n='62' a='#handle-artifacts-banding'
                               text='How does JPEG XL handle artifacts and banding?'/>
                    <LiContent n='63' a='#prevent-banding-artifacts'
                               text='What else does JPEG XL do to prevent banding and artifacts?'/>
                    <LiContent n='64' a='#loop-filters' text='What are Loop Filters?'/>
                    <LiContent n='65' a='#ac-encoding' text='AC Encoding'/>
                    <LiContent n='66' a='#entropy-coding' text='Entropy Coding'/>
                    <LiContent n='67' a='#working-with-jpeg-xl' text='⏩WORKING WITH JPEG XL'/>
                    <LiContent n='68' a='#use-cases' text='What are the use cases for JPEG XL?'/>
                    <LiContent n='69' a='#can-i-use-jpeg-xl' text='Can I use JPEG XL?'/>
                    <LiContent n='70' a='#should-i-use-jpeg-xl' text='Should I use JPEG XL?'/>
                    <LiContent n='71' a='#use-jpeg-xl-website' text='Should I use JPEG XL for my website?'/>
                    <LiContent n='72' a='#new-hardware'
                               text='Do I need new hardware to encode and decode JPEG XL?'/>
                    <LiContent n='73' a='#convert-images' text='How can I convert images to JPEG XL?'/>
                    <LiContent n='74' a='#recompress-jpg' text='Can I recompress my JPG images to JXL losslessly?'/>
                    <LiContent n='75' a='#jpeg-xl-support' text='⏩JPEG XL SUPPORT'/>
                    <LiContent n='76' a='#image-viewers' text='What image viewers support JPEG XL?'/>
                    <LiContent n='77' a='#browsers' text='What browsers support JPEG XL?'/>
                    <LiContent n='78' a='#cdns' text='What CDNs support JPEG XL?'/>
                    <LiContent n='79' a='#operating-systems' text='What Operating system support JPEG XL?'/>
                    <LiContent n='80' a='#mobile-phones' text='Do mobile phones support JPEG XL?'/>
                    <LiContent n='81' a='#compare-jpeg-xl-with-jpeg' text='Compare JPEG XL with JPEG'/>
                </ol>
            </nav>
            <br/>
            <Line/>

            <span id="general-information" ref={refs['general-information']}>
                    <h1 className='main'>⏩GENERAL INFORMATION</h1>
                    <p className='umain'>The sources of this article can be found at the end of this page. Thanks to all the high value content that has resulted in this FAQ!</p>
                </span>
            <Line/>

            <span id="introduction" ref={refs['introduction']}>
                    <h1 className='main'>Introduction</h1>
                    <p className='umain'>This next-generation image codec, called JPEG XL, is being developed by the JPEG Committee. JPEG XL promises to offer significantly better compression efficiency than existing formats and other features fit for web distribution, exceptionally high-quality images.</p>
                    <p className='umain'>With billions of images captured, created, uploaded, and shared every day, the need for efficient image compression is evident. Websites and user interfaces are increasingly image-rich, with images used for sharing experiences, visualizing information, and creating appealing designs.</p>
                    <p className='umain'>Devices with a strict network connection and power consumption constraints can benefit from better compression. Due to the smaller file size, businesses can utilize images with higher resolutions, wider color gamuts, and higher dynamic ranges without worrying about overwhelming datasets.</p>
                    <p className='umain'>The most common coding formats are JPEG, PNG, and WebP for most applications. The described applications will benefit from more efficient compression, shorter network transmission times, and more interactive applications.</p>
                    <p className='umain'>The bit count of images is relatively low when compared with video data. In any case, websites and interfaces generally can contain hundreds of photos, or at least several high-resolution images, adding up to megabytes of data. Unlike video streams, which can be buffered before playback, image-based UIs need to be responsive and interactive, without stalling and taking several seconds to download or be interactive.</p>
                    <p className='umain'>Recently, new image formats with more efficient compression capabilities than JPEG have been developed. However, these formats have some shortcomings concerning the use cases discussed below. Several evidence-based compression algorithms have recently been presented that outperform the currently used standards for image coding.</p>
                    <p className='umain'>While these technological advances have been made, no widely accepted standard exists that can support state-of-the-art compression performance and is widely accepted by consumer devices and browsers alike.</p>
                    <p className='umain'>The JPEG XL format aims to become this widespread format for images. It is a standard for capturing, archiving, transmitting, and distributing images and graphics, illustrations, mixed content (e.g., screenshots), and even animations.</p>
                    <p className='umain'>JPEG XL is perfect for web applications requiring responsiveness, a wide color gamut, or a high dynamic range. It combines legacy transition features with effective compression, resulting in excellent image quality. This FAQ describes JPEG XL in detail.</p>
                </span>
            <Line/>

            <span id="what-is-a-jpeg-xl-file" ref={refs['what-is-a-jpeg-xl-file']}>
                    <h1 className='main'>What is a JPEG XL file?</h1>
                    <p className='umain'>With JPEG XL, you can choose between lossy and lossless compression of raster graphics files. This format aims to outperform existing raster formats and become a universal replacement.</p>
                    <p className='umain'>With JPEG XL, you can distribute high-quality photos over the web and compress them efficiently with a practical, royalty-free codec. As well as previews, progressiveness, animation, transparency, wide color gamut, and high bit depth, it offers high dynamic range and high bit depth.</p>
                    <p className='umain'>The user expects accurate reproduction of images of ever-higher resolutions. The experiments performed during the standardization process have demonstrated the feasibility of economically storing images without perceptible quality loss, decoding existing JPEG files without quality loss, and developing fast software encoders and decoders.</p>
                    <p className='umain'>For several years, new codecs must coexist with their predecessors. JPEG XL offers value to users of both existing JPEGs and new JPEGs. With its coding tools, it can reduce the transmission and storage costs of JPEG by 22% and allow exact byte-for-byte reproduction of the original file.</p>
                    <p className='umain'>The decoding process must be fast and low-power. JPEG XL takes advantage of multicore and SIMD and decodes faster than JPEG as soon as you have at least four cores to work with.</p>
                </span>
            <Line/>

            <span id="what-is-the-file-extension-jxl" ref={refs['what-is-the-file-extension-jxl']}>
                    <h1 className='main'>What is the file extension JXL?</h1>
                    <p className='umain'>In addition to having a file extension of JXL, JPEG XL has the internet media type image/jxl. JXL files are images saved in the JPEG XL file format developed by JPEG (Joint Photographic Experts Group). Files contain a photo, illustration, screenshot, or other raster image data that has been compressed using the JPEG XL compression algorithm.</p>
                </span>
            <Line/>

            <span id="benefits-of-jpeg-xl" ref={refs['benefits-of-jpeg-xl']}>
                    <h1 className='main'>What are the benefits of JPEG XL?</h1>
                    <p className='umain'>Compared with existing codecs, the JPEG XL format offers several advantages:</p>
                    <p className='umain'>&#10004; Over and above the common coding standards at the same subjective quality, significant improvement in compression efficiency, e.g.,>60% over JPEG</p>
                    <p className='umain'>&#10004; Support for alpha-channel coding and animated images, among other features</p>
                    <p className='umain'>&#10004; Designed with high fidelity in mind (unlike any video-derived format). Compression of high-quality images with high resolution, high bit depth, high dynamic range, and wide color gamut coding is supported</p>
                    <p className='umain'>&#10004; Progressive decoding for automatic low-quality image placeholders, including saliency progression to prioritize more critical parts of the image first (like face details)</p>
                    <p className='umain'>&#10004; A reference encoder with fully automatic encoding based on a visual target, not on technical parameters like with JPEG</p>
                    <p className='umain'>&#10004; A royalty-free baseline to encourage widespread adoption</p>
                    <p className='umain'>&#10004; Unique legacy-friendliness allows to transcode JPEG losslessly</p>
                </span>
            <Line/>

            <span id="downside-of-jpeg-xl" ref={refs['downside-of-jpeg-xl']}>
                    <h1 className='main'>What is the downside of JPEG XL?</h1>
                    <p className='umain'>XL shares the same disadvantage as any new format that needs to be established: It lacks support. In addition, few operating systems support JPEG XL. There is a lack of browsers and image viewers that support it even though many browsers added support for JPEG XL behind a flag.</p>
                </span>
            <Line/>

            <span id="need-for-jpeg-xl" ref={refs['need-for-jpeg-xl']}>
                    <h1 className='main'>Why is a new image format like JPEG XL needed?</h1>
                    <p className='umain'>&#10004; JPEG has been hitting its limits for an extended period:</p>
                    <p className='umain'>&#10004; JPEG can only do 8-bit color depth, no HDR.</p>
                    <p className='umain'>&#10004; JPEG can only do lossy, with no lossless support</p>
                    <p className='umain'>&#10004; JPEG lacks good compression for graphics images</p>
                    <p className='umain'>&#10004; JPEG cannot do alpha transparency</p>
                    <p className='umain'>&#10004; JPEG cannot do animations</p>
                    <p className='umain'>&#10004; JPEG does not support multiple layers</p>
                    <p className='umain'>&#10004; JPEG compression efficiency is 30 years old and not as good</p>
                    <p className='umain'>&#10004; JPEG comes with annoying compression artifacts we all love</p>
                    <p className='umain'>&#10004; Banding, Noise, Blockiness, and more banding.</p>
                    <p className='umain'>WebP has been a relief, but it does not support HDR, and compression could be better. AVIF has been a significant step into the future, but it lacks efficiency in encoding images. Both WebP and AVIF have been derived from video formats, while JPEG XL has an image architecture from the start in mind.</p>
                </span>
            <Line/>

            <span id="primary-goal-of-jxl" ref={refs['primary-goal-of-jxl']}>
                    <h1 className='main'>What is the primary goal of JXL?</h1>
                    <p className='umain'>Historically, image data quality has been reduced for the sake of quantity. Images and videos delivered to users suffered from block artifacts.</p>
                    <p className='umain'>The goal of JPEG XL is to provide solutions to deliver images faster, more economically, more robustly, without surprises, and generally with better-perceived quality.</p>
                    <p className='umain'>The JPEG XL format is well suited to handling high-quality images. It aims to serve the needs of today's and tomorrow's increasingly rich media experiences. The ideal compression level for an image is one where there is no discernible loss of quality from the original.</p>
                    <p className='umain'>JPEG XL is a practical solution for scalable web distribution and efficient, high-quality image compression. Its main benefits are the following:</p>
                    <p className='umain'>&#10004; Significantly smaller size at the same subjective quality</p>
                    <p className='umain'>&#10004; Fast and parallelized encoding and decoding configurations</p>
                    <p className='umain'>&#10004; Features such as progressive decoding</p>
                    <p className='umain'>&#10004; Lossless compression and no generation loss</p>
                    <p className='umain'>&#10004; High-quality applications (wide color gamut, bit depth, dynamic range)</p>
                    <p className='umain'>As a bonus, a royalty-free baseline encourages adaptation.</p>
                </span>
            <Line/>

            <span id="creation-of-jpeg-xl" ref={refs['creation-of-jpeg-xl']}>
                    <h1 className='main'>⏩CREATION OF JPEG XL</h1>
                </span>
            <Line/>

            <span id="who-created-jpeg-xl" ref={refs['who-created-jpeg-xl']}>
                    <h1 className='main'>Who created JPEG XL?</h1>
                    <p className='umain'>The JPEG committee has developed JPEG XL, the next-generation image compression codec. Google's Pik proposal and Cloudinary's FUIF proposal served as the foundation. All three companies have authors and developers responsible for creating JPEG XL.</p>
                </span>
            <Line/>

            <span id="key-contributors-of-jpeg-xl" ref={refs['key-contributors-of-jpeg-xl']}>
                    <h1 className='main'>Who are the key contributors of JPEG XL?</h1>
                    <p className='umain'>Google has an experienced team partially responsible for Brotli, Woff 2.0, Butteraugli, Brunsli, or Pik.</p>
                    <p className='umain'>Influential authors and developers include Jyrki Alakuijala, Sami Boukortt, Martin Bruse, Iulia-Maria Comsa, Alex Deymo, Moritz Firsching, Thomas Fischbacher, Sebastian Gomez, Renata Khasanova, Evgenii Kliuchnikov, Jeffrey Lim, Robert Obryk, Krzysztof Potempa, Alexander Rhatushnyak, Zoltan Szabadka, Lode Vandevenne, Luca Versari and Jan Wassenberg.</p>
                    <p className='umain'>Cloudinary's team members, partially responsible for FLIF / FUIF, include Jon Sneyers, Eric Portis, Tal Lev-Ami, and Colin Bendell.</p>
                    <p className='umain'>Other JPEG experts include Touradj Ebrahimi, Walt Husak, Andy Kuzma, Fernando Pereira, Antonio Pinheiro, Thomas Richter and Peter Schelkens. Please note that this list may or may not be complete.</p>
                </span>
            <Line/>

            <span id="why-jpeg-xl" ref={refs['why-jpeg-xl']}>
                    <h1 className='main'>Why is JPEG XL called JPEG XL?</h1>
                    <p className='umain'>The name is easily understood. Joint Photographic Experts Group (JPEG) designed the format. Since 2000, the letter X has been part of more than one JPEG standard: JPEG XT, JPEG XR, JPEG XS. L means Long-term because it will replace the legacy JPEG and last as long.</p>
                  </span>
            <Line/>

            <span id="timeline-of-jpeg-xl" ref={refs['timeline-of-jpeg-xl']}>
                    <h1 className='main'>Since when does JPEG XL exist?</h1>
                    <p className='umain'>A Call for Proposals for JPEG XL, a next-generation image compression standard, was issued in 2017 by JTC1/SC29/WG1. On December 25, 2020, the bitstream format (file format) was frozen, ensuring that it will be decodable by future releases.</p>
                  </span>
            <Line/>

            <span id="timeline-of-jpeg-xl2" ref={refs['timeline-of-jpeg-xl2']}>
                    <h1 className='main'>What is the timeline of JPEG XL?</h1>
                    <p className='umain'>&#10004; Oct 2018: 7 submissions to JPEG XL CfP investigated</p>
                    <p className='umain'>&#10004; Jan 2019: Combination of Pik+FUIF selected</p>
                    <p className='umain'>&#10004; April 2019: New Project (NP) started</p>
                    <p className='umain'>&#10004; July 2019: Committee Draft (CD)</p>
                    <p className='umain'>&#10004; Jan 2020: Draft International Standard (DIS)</p>
                    <p className='umain'>&#10004; July 2020: DIS approved, with 181 ballot comments (!)</p>
                    <p className='umain'>&#10004; October 2020: all DIS ballot comments addressed</p>
                    <p className='umain'>&#10004; November 2020: final validation experiments</p>
                    <p className='umain'>&#10004; December 2020: bitstream frozen</p>
                    <p className='umain'>&#10004; January 2021: Part 1 (codestream) FDIS submitted</p>
                    <p className='umain'>&#10004; April 2021: Part 2 (file format) FDIS submitted;</p>
                    <p className='umain'>&#10004; Draft Amendment to Part 1 (Profile & Levels)</p>
                    <p className='umain'>&#10004; Continue integration & adoption!</p>
                    <p className='umain'>&#10004; International Standard (IS): before the end of 2021?</p>
                    <p className='umain'>&#10004; Finalize Part 3 (conformance)</p>
                    <p className='umain'>Source: Google Docs Slide</p>
                  </span>
            <Line/>

            <span id="based-on-other-formats" ref={refs['based-on-other-formats']}>
                    <h1 className='main'>Is JPEG XL based on other formats?</h1>
                    <p className='umain'>Both Google and Cloudinary developed a combination of the PIK and FUIF formats to create JPEG XL. But JPEG XL can't be considered a combination of these two formats. After several years of joint development, PIK and FUIF were combined, and many improvements were made.</p>
                    <p className='umain'>It shares similarities with JPEG, as it preserves features such as DCT, progressive decoding, and 4:4:4 or optional chroma subsampling. However, several additional features have been added along the way:</p>
                    <p className='umain'>&#10004; VarDCT (4x4, 32x32, 8x16, ...)+</p>
                    <p className='umain'>&#10004; Adaptive quantization</p>
                    <p className='umain'>&#10004; Better entropy coding</p>
                    <p className='umain'>&#10004; Better progressive</p>
                    <p className='umain'>&#10004; XYB color space</p>
                    <p className='umain'>&#10004; Parallel Decoding</p>
                    <p className='umain'>&#10004; Lossless</p>
                    <p className='umain'>&#10004; High bit depth (up to 32-bit)</p>
                    <p className='umain'>&#10004; Channels, Alpha Transparency, Depth Map</p>
                    <p className='umain'>&#10004; Multi-frame, layers</p>
                  </span>
            <Line/>

            <span id="royalty-free" ref={refs['royalty-free']}>
                    <h1 className='main'>Is JPEG XL royalty-free?</h1>
                    <p className='umain'>A reference implementation of JPEG XL has been licensed under the Apache 2.0 license by the creators of JPEG XL. Therefore, JPEG XL is free and open-source software (FOSS) and patent-free. Additionally, they offer an open-source, royalty-free implementation of JPEG XL, also available on Gitlab.</p>
                    <p className='umain'>A large part of their success has been due to contributors' royalty-free patent licensing commitments to past standards. Similar commitments will be helpful to the adoption of the next-generation image coding standard.</p>
                  </span>
            <Line/>

            <span id="standard-for-jpeg-xl" ref={refs['standard-for-jpeg-xl']}>
                    <h1 className='main'>Is there a Standard for JPEG XL?</h1>
                    <p className='umain'>JPEG XL is a multi-part specification, like most other JPEG standards. Currently, the following parts are being developed or released:</p>
                    <p className='umain'>&#10004; ISO/IEC 18181-1. Part 1, Core coding system: Defines the JPEG XL codestream and decoder, which can be used for lossy encoding, lossless encoding, and lossless recompression of existing JPEG images. (released)</p>
                    <p className='umain'>&#10004; ISO/IEC 18181-2. Part 2, File format: Specifies an extensible box-based file format that supports metadata (e.g., EXIF and JUMBF) and legacy JPEG bitstream reconstruction data. (released)</p>
                    <p className='umain'>&#10004; ISO/IEC 18181-3. Part 3, Conformance testing: Provides test material and procedures to validate proprietary solutions against the standard specification. (development, WD Stage)</p>
                    <p className='umain'>&#10004; ISO/IEC 18181-4. Part 4, Reference software: Provides a free and open-source, royalty-free JPEG XL reference implementation, also available on Gitlab. (development, WD Stage)</p>
                    <p className='umain'>Usually, once the process reaches the FDIS stage, the specification is "frozen," and JPEG XL can be used. In the meantime, software support for JPEG XL in applications and platforms will still take time and effort.</p>
                    <p className='umain'>In the past, several attempts at dethroning the old JPEG have failed. JPEG XL's creators are hopeful that they have succeeded this time and have created a worthy successor to a 30-year-old image format that is as old as the internet.</p>
                  </span>
            <Line/>

            <span id="basic-features" ref={refs['basic-features']}>
                    <h1 className='main'>⏩BASIC FEATURES</h1>
                  </span>
            <Line/>

            <span id="features-of-jpeg-xl" ref={refs['features-of-jpeg-xl']}>
                    <h1 className='main'>What features does JPEG XL support?</h1>
                    <p className='umain'>The JPEG XL format allows users to balance three primary criteria:</p>
                    <p className='umain'>&#10004; High fidelity to the source image (matches human perception)</p>
                    <p className='umain'>&#10004; High-speed encoding and decoding</p>
                    <p className='umain'>&#10004; High compression ratio (20:1 to 50:1)</p>
                    <p className='umain'>The JPEG XL codec has the following key features:</p>
                    <p className='umain'>&#10004; Improved functionality and efficiency over traditional formats</p>
                    <p className='umain'>&#10004; Progressive decoding (by resolution and precision)</p>
                    <p className='umain'>&#10004; Lossless JPEG transcoding</p>
                    <p className='umain'>&#10004; Support for both photographic and synthetic imagery</p>
                    <p className='umain'>&#10004; Graceful quality degradation across an extensive range of bitrates</p>
                    <p className='umain'>&#10004; Perceptually optimized reference encoder</p>
                    <p className='umain'>&#10004; Support for wide gamut and HDR</p>
                    <p className='umain'>&#10004; Support for animated content</p>
                    <p className='umain'>&#10004; Efficient encoding and decoding without requiring specialized hardware</p>
                    <p className='umain'>Unlike the usual method of configuring encoders based on bitrate or quantization settings, which results in image-dependent visual quality, the reference software configures encoders by perceptual target, reliably reaching a desired visual quality.</p>
                    <p className='umain'>These key results pertain to compression performance:</p>
                    <p className='umain'>&#10004; Lossless JPEG transcoding reduces JPEG size by around 16% to 22%.</p>
                    <p className='umain'>&#10004; About 35% smaller than PNG (50% smaller for HDR)</p>
                    <p className='umain'>&#10004; JPEG XL is visually lossless at about 40% of the bitrate required by JPEG.</p>
                  </span>
            <Line/>

            <span id="alpha-transparency" ref={refs['alpha-transparency']}>
                    <h1 className='main'>Does JPEG XL support alpha transparency?</h1>
                    <p className='umain'>Since JPEG XL supports alpha transparency, it can be used in place of WebP, PNG, and AVIF with transparency. Even after lossless transcoding existing JPEG images to JPEG XL, you can add alpha to them.</p>
                  </span>
            <Line/>

            <span id="animation" ref={refs['animation']}>
                    <h1 className='main'>Does JPEG XL support animation?</h1>
                    <p className='umain'>Among the features of JPEG XL are animation, alpha channels, layers, thumbnails, lossless coding, and progressive coding. "Multi-frame" is a feature intended to emulate photo bursts or animations. It is important to remember that a video codec will achieve much better compression because JPEG XL does not provide inter-prediction.</p>
                    <p className='umain'>&#10004; Features of JPEG XL multi-frame are:</p>
                    <p className='umain'>&#10004; Can subtract the previous frame</p>
                    <p className='umain'>&#10004; The frame can be smaller than the canvas</p>
                    <p className='umain'>&#10004; Blend and dispose modes like in APNG/GIF/AWebP</p>
                    <p className='umain'>&#10004; Use patches for moving sprites</p>
                    <p className='umain'>&#10004; Zero-delay frames are allowed</p>
                  </span>
            <Line/>

            <span id="progressive-decoding" ref={refs['progressive-decoding']}>
                    <h1 className='main'>Does JXL support progressive decoding?</h1>
                    <p className='umain'>Some image codecs can decode even partially loaded images, resulting in a lower-quality (or lower-resolution) preview. With progressive decoding, no bytes are wasted on separate previews: the first bytes of the high-quality image is the preview image. JPEG XL provides progressive decoding based on resolution and precision.</p>
                    <p className='umain'>To generate the first preview more quickly, you can progressively encode the DC itself in JPEG XL. The DC, or the first full-image preview for a progressive JPEG file, typically takes 15 to 20 percent of the total file size. Using progressive DC, a feature of JPEG XL, you can create a first placeholder image when only 1 percent of the total file size is available.</p>
                    <p className='umain'>There are two more advanced progressive encoding options in JPEG XL: Middle-out scans: In JPEG, scans are top to bottom. In JPEG XL, where encoding occurs in groups of 256x256 pixels, you can reorder the groups. As a result, you should start all scans with the groups in the middle, likely to contain the most exciting parts of the image. Progression of saliency: In progressive scans of JPEGs, each image part is given the same amount of detail. This is not the case for JPEG XL. That means you can encode images progressively based on saliency, such as encoding faces or foreground objects in more detail first, followed by the background.</p>
                    <p className='umain'>Did you know? Multiple passes of AC coefficients can be contained in the JPEG XL bitstream. This allows images to be encoded progressively by sending low-frequency coefficients first and later high-frequency coefficients. During a given pass, the quality of selected regions of the image can also be refined, allowing progressive decoding of area-of-interest.</p>
                  </span>
            <Line/>

            <span id="hdr" ref={refs['hdr']}>
                    <h1 className='main'>Does JPEG XL support HDR?</h1>
                    <p className='umain'>&#10004; JPEG XL is intended to be a format that offers high fidelity and a small file size. Therefore, it can support many high-quality applications, including wide gamut / HDR, visually lossy 4:4:4 up to 32 bits of depth, dynamic range, and generation loss resilience.</p>
                    <p className='umain'>&#10004; Lossless up to 32 bits per channel (float or int)</p>
                    <p className='umain'>&#10004; Compatible with HDR & wide gamut</p>
                    <p className='umain'>&#10004; Not for end-user image delivery but also authoring workflows</p>
                  </span>
            <Line/>

            <span id="highest-megapixel-count" ref={refs['highest-megapixel-count']}>
                    <h1 className='main'>What is the highest megapixel count for JPEG XL?</h1>
                    <p className='umain'>Image dimensions of over a billion (230-1) pixels on each side.</p>
                  </span>
            <Line/>

            <span id="compression-features" ref={refs['compression-features']}>
                    <h1 className='main'>⏩COMPRESSION FEATURES</h1>
                  </span>
            <Line/>

            <span id="optimize-images" ref={refs['optimize-images']}>
                    <h1 className='main'>How does JPEG XL optimize my images?</h1>
                    <p className='umain'>The reference encoder in JPEG XL is perceptually optimized. By focusing on perceptual targets, the reference software consistently achieves a desired visual quality, as opposed to the usual strategy of configuring utilizing bitrate or quantization, which results in a highly image-dependent visual quality. End users will also benefit from this since they no longer need to guess what quality to give an image, like the 1-100 scale for JPEG, but rather specify what quality they want.</p>
                  </span>
            <Line/>

            <span id="lossless-compression" ref={refs['lossless-compression']}>
                    <h1 className='main'>Does JPEG XL support lossless compression?</h1>
                    <p className='umain'>The JPEG XL format supports lossless compression, with around 35% better compression than PNG. In addition, existing JPEG files can be losslessly converted to JPEG XL, significantly reducing their size.</p>
                    <p className='umain'>JPEG XL is incredibly efficient at compressing images at perceptual qualities ranging from visually nearly lossless (in a side-by-side comparison) to visually lossless (in a flicker test, which is more rigorous than a side-by-side comparison).</p>
                </span>
            <Line/>

            <span id="lossy-compression" ref={refs['lossy-compression']}>
                    <h1 className='main'>Does JPEG XL support lossy compression?</h1>
                    <p className='umain'>For the same quality, lossy is about 60% smaller than JPEG. Adaptive quantization allows adjusting quality locally as needed.</p>
                </span>
            <Line/>

            <span id="low-fidelity-images" ref={refs['low-fidelity-images']}>
                    <h1 className='main'>Is JPEG XL suitable for low-fidelity images?</h1>
                    <p className='umain'>JPEG XL is excellent for low-fidelity images, even though AVIF offers better compression in terms of file size.</p>
                </span>

            <Line/>
            <span id="medium-fidelity-images" ref={refs['medium-fidelity-images']}>
                    <h1 className='main'>Is JPEG XL suitable for medium-fidelity images?</h1>
                    <p className='umain'>JPEG XL offers the same compression as AVIF images and better compression than WebP images, and it's an excellent choice for medium-fidelity images.</p>
                </span>

            <Line/>
            <span id="high-fidelity-images" ref={refs['high-fidelity-images']}>
                    <h1 className='main'>Is JPEG XL suitable for high-fidelity images?</h1>
                    <p className='umain'>JPEG XL focuses on offering high fidelity images while keeping file sizes small. JPEG XL was designed with this in mind, making it an ideal candidate for high-fidelity images.</p>
                </span>
            <Line/>
            <span id="non-photographic-images" ref={refs['non-photographic-images']}>
                    <h1 className='main'>Is JPEG XL suitable for non-photographic images?</h1>
                    <p className='umain'>In addition to photos, JPEG XL can compress non-photographic images efficiently, including icons and sprites.</p>
                </span>
            <Line/>
            <span id="lossless-file-size-save" ref={refs['lossless-file-size-save']}>
                    <h1 className='main'>How much file size does lossless JPEG XL save?</h1>
                    <p className='umain'>Transcoding JPEGs without loss reduces their size by approximately 16% to 22%. Lossy is even more robust, between 20 and 60%. This will vary according to the compression effort and quality of the image.</p>
                </span>
            <Line/>
            <span id="lossy-file-size-save" ref={refs['lossy-file-size-save']}>
                    <h1 className='main'>How much file size does lossy JPEG XL save?</h1>
                    <p className='umain'>JPEG XL is visually lossless at about half the bitrate required by JPEG. JPEG XL is visually lossless in side-by-side comparisons, typically at similar bitrates as HEVC-HM-Y444.</p>
                </span>
            <Line/>
            <span id="vast-header" ref={refs['vast-header']}>
                    <h1 className='main'>Does JPEG XL have a vast header like AVIF?</h1>
                    <p className='umain'>&#10004; Compared to other formats, JPEG XL has the tiniest header of them all:</p>
                    <p className='umain'>&#10004; PNG: 67 bytes</p>
                    <p className='umain'>&#10004; GIF: 35 bytes</p>
                    <p className='umain'>&#10004; JPEG: 107 bytes</p>
                    <p className='umain'>&#10004; WebP: 26 bytes</p>
                    <p className='umain'>&#10004; JPEG XL: 12 bytes</p>
                    <p className='umain'>&#10004; AVIF: 298 bytes</p>
                    <p className='umain'>In a presentation about JPEG XL, I've found a funny example that displays how efficient JPEG XL is:</p>
                    <p className='umain'>117-byte AVIF file: "Hi, there! I am an AVIF image, with the brands avif, mif1, miaf, MA1A, encoded with libavif, and you should handle me as a picture, and my dimensions are —"</p>
                    <p className='umain'>117 byte JPEG XL file:</p>
                    <img src={jxl} alt='your browser may show this JXL img if you enabled support'/>
                </span>
            <Line/>

            <span id="speed-features" ref={refs['speed-features']}>
                    <h1 className='main'>⏩SPEED FEATURES</h1>
                </span>
            <Line/>

            <span id="single-core-encode-speed" ref={refs['single-core-encode-speed']}>
                    <h1 className='main'>Single-core encode speed</h1>
                    <p className='umain'>JPEG XL offers a fast single-core encode speed, but of course, not as fast as the fancy JPEG encoders.</p>
                    <p className='umain'>&#10004; libjpeg-turbo: 100 MP/s</p>
                    <p className='umain'>&#10004; Mozjpeg: 10 MP/s</p>
                    <p className='umain'>&#10004; AVIF: 0.8 MP/s</p>
                    <p className='umain'>&#10004; JPEG XL: 15 MP/s</p>
                </span>
            <Line/>

            <span id="single-core-decode-speed" ref={refs['single-core-decode-speed']}>
                    <h1 className='main'>Single-core decode speed</h1>
                    <p className='umain'>JPEG XL offers a fast single-core encode speed, but not as fast as the fancy JPEG decoders.</p>
                    <p className='umain'>&#10004; libjpeg-turbo: 180 MP/s</p>
                    <p className='umain'>&#10004; Mozjpeg: 100 MP/s</p>
                    <p className='umain'>&#10004; AVIF: 25 MP/s</p>
                    <p className='umain'>&#10004; JPEG XL: 30 MP/s</p>
                </span>
            <Line/>

            <span id="parallelizable" ref={refs['parallelizable']}>
                    <h1 className='main'>Parallelizable</h1>
                    <p className='umain'>To encode a large image (more than 256 pixels on any axis), it is split into subrectangles 256 * 256 in size, and each is encoded independently. An index of the bitstream positions of the rectangles allows decoders to seek the beginning of each rectangle.</p>
                    <p className='umain'>Four benefits follow: the decoder can process each rectangle independently, it can process only selected regions of large images, it can rearrange rectangles (e.g., in saliency order), and it can restart decoding if a previous rectangle is corrupted.</p>
                </span>
            <Line/>

            <span id="multi-core-speed" ref={refs['multi-core-speed']}>
                    <h1 className='main'>Multi-core speed</h1>
                    <p className='umain'>The JPEG format is a single-core format. On multicore platforms, JPEG XL is faster to encode and decode. Hardware is not necessary; software encodes and decodes are fast enough. libjpeg turbo has a decoding speed of 200 MP/s, and mozjpeg has 100 MP/s. JPEG XL has a decoding speed of 30 MP/s, but with eight cores, that results in 240 MP/s.</p>
                </span>
            <Line/>

            <span id="progressive-image" ref={refs['progressive-image']}>
                    <h1 className='main'>Progressive image based on DCT</h1>
                    <p className='umain'>A JPEG XL bitstream may contain multiple passes of AC coefficients that are summed together. Gradually encoding images is possible by sending low-frequency coefficients first and higher frequency coefficients later. A particular section of the image can also be refined in a given pass, allowing for area-of-interest progressive decoding.</p>
                    <p className='umain'>Since DC coefficients are sent first, an eight* downsampled image can be obtained without reading the entire file from the JPEG XL bitstream.</p>
                </span>
            <Line/>

            <span id="advanced-features" ref={refs['advanced-features']}>
                    <h1 className='main'>⏩ADVANCED FEATURES</h1>
                </span>
            <Line/>

            <span id="lossless-transcoding" ref={refs['lossless-transcoding']}>
                    <h1 className='main'>Lossless transcoding from JPEG</h1>
                    <p className='umain'>Using JPEG recompression, legacy JPEG files can be converted losslessly to the more compact JPEG XL format: existing JPEG files can be transferred seamlessly. These can be restored into the same JPEG file to maintain backward compatibility with existing JPEG applications.</p>
                    <p className='umain'>Previous attempts to develop "next-generation" image formats, including JPEG 2000, JPEG XR, and HEIC and AVIF, didn't achieve this. A JPEG image must be decoded into pixels and then re-encoded with one of those other formats. This irreversible process results in generation losses.</p>
                    <p className='umain'>In the JPEG XL Project, those challenges were considered from the start. The format was designed to overcome them as much as possible.</p>
                    <p className='umain'>The JPEG XL format is a superset of the JPEG format. In addition, JPEG XL developers did not change any of the JPEG coding tools, even with its numerous under-the-hood coding tools that achieve superior compression. Therefore, all JPEG image data is represented as JPEG XL.</p>
                    <p className='umain'>In part, this is due to:</p>
                    <p className='umain'>The JPEG image is based on the discrete cosine transform (DCT) of 8x8 with fixed quantization tables. JPEG XL offers a much more robust approach, including variable DCT sizes ranging from 2x2 to 256x256 as well as adaptive quantization, of which the simple JPEG DCT is merely a particular case.</p>
                    <p className='umain'>For high-fidelity, perceptually optimized image encoding, JPEG XL uses a novel internal color space called XYB. It can also process simple YCbCr color transformations applied by JPEG.</p>
                    <p className='umain'>As a result, you do not need to decode JPEGs to pixels to convert them to JPEG XLs. Rather than relying on the JPEG internal representation (DCT coefficients), utilize JPEG XL directly. Even though only the subset of JPEG XL that corresponds to JPEG is used, the converted images would be 20 percent smaller.</p>
                    <p className='umain'>The JPEG XL files, on the other hand, are exact copies of the original JPEG files. Additionally, the conversion is reversible: you can convert JPEG XL files back to JPEGs with minimal computation - so fast that you can do it right on the fly.</p>
                    <p className='umain'>JPEG XL's legacy-friendly feature is essential. You can also save both storage and bandwidth by preserving legacy images with lossless compression at the same time. Hence, JPEG XL offers immediate benefits, whereas other approaches require sacrifices in storage to reduce bandwidth.</p>
                </span>
            <Line/>

            <span id="high-fidelity-images" ref={refs['high-fidelity-images']}>
                    <h1 className='main'>Does JPEG XL work with high fidelity images?</h1>
                    <p className='umain'>In terms of image compression, JPEG XL has a high perceptual quality that ranges from visually near-lossless (in a side-by-side comparison) to visually lossless (in a flicker test, which is stricter than a side-by-side evaluation) to mathematically lossless.</p>
                    <p className='umain'>There has been a great deal of effort to preserve the images' subtle texture and other fine details. While it is possible to use the codec at lower bitrates, where the degradation becomes more evident, it excels at the relatively high bitrates.</p>
                    <p className='umain'>A video codec-based image format, on the other hand, tends to do well at low bitrates: it can produce an excellent image in a few bytes. The picture looks good at first but, on closer inspection, often seems washed out. Usually, that won't be a problem for video codecs: low bitrates keep the file size or bandwidth low, and frames are typically shown for less than 40 milliseconds, so the audience won't notice such artifacts. Still images, however, are often of higher quality.</p>
                </span>
            <Line/>

            <span id="max-bit-depth" ref={refs['max-bit-depth']}>
                    <h1 className='main'>What is the max bit depth of JPEG XL?</h1>
                    <p className='umain'>With JPEG XL, you can achieve the highest bit depth and express values of up to 32-bit floats. This is a significant increase from JPEG and WebP's 8 bits or AVIF's 12 bits.</p>
                </span>
            <Line/>

            <span id="color-spaces" ref={refs['color-spaces']}>
                    <h1 className='main'>What color spaces does JXL support?</h1>
                    <p className='umain'>JPEG XL supports RGB, YCgCo, and XYB.</p>
                </span>
            <Line/>

            <span id="xyb-color-space" ref={refs['xyb-color-space']}>
                    <h1 className='main'>What is the XYB color space?</h1>
                    <p className='umain'>A color space inspired by the human visual system, XYB facilitates perceptually uniform quantization. For computation-efficient decoding, gamma is set at 3.</p>
                    <p className='umain'>Human visual system based color space:</p>
                    <p className='umain'>&#10004; Y: luma</p>
                    <p className='umain'>&#10004; X: difference L-M</p>
                    <p className='umain'>&#10004; B: S</p>
                    <p className='umain'>JPEG XL uses a color space derived from LMS called XYB. Based on the lower spatial density of S cones, this is interpreted as a hybrid color theory where L and M oppose each other while S is handled trichromatically. As a result, less data is needed for storing blue signals without losing much quality. JPEG XL's colorspace was derived from Guetzli's butteraugli metric and is based on Google's Pik project.</p>
                </span>
            <Line/>

            <span id="generation-loss-resilience" ref={refs['generation-loss-resilience']}>
                    <h1 className='main'>Generation Loss Resilience</h1>
                    <p className='umain'>The typical transcoding process decodes JPEG images into pixels and then encodes them into another format. Unfortunately, this is a risky approach because it causes generation loss.</p>
                    <p className='umain'>Additionally, you must determine the optimal quality setting. In this case, the already lossy image would become even lossier, potentially ruining the shot. A too high setting will result in a larger file than the original JPEG.</p>
                    <p className='umain'>The new codec can expend large amounts of data to preserve JPEG artifacts, potentially leading to bigger files than the original JPEG. Converting a lossy image collection to a new lossy codec can be daunting because of the task of preserving fidelity, reducing storage, and automating it.</p>
                    <p className='umain'>The JPEG XL Project took into account those transition problems and designed the format as best as possible to overcome them. The JPEG XL format is a superset of the JPEG format. In addition, JPEG XL developers did not change any of the JPEG coding tools, even with its numerous under-the-hood coding tools that achieve superior compression. Therefore, all JPEG image data is represented as JPEG XL.</p>
                </span>
            <Line/>

            <span id="automatic-decoding" ref={refs['automatic-decoding']}>
                    <h1 className='main'>Fulll automatic decoding</h1>
                    <p className='umain'>The JPEG XL reference offers an entire automatic decoding process in addition to losslessly transcoding images from JPEG to JPEG XL. Instead of specifying a number on a technical scale, JPEG XL lets you specify a visual target.</p>
                </span>
            <Line/>

            <span id="film-grain" ref={refs['film-grain']}>
                    <h1 className='main'>Does JPEG XL support film grain?</h1>
                    <p className='umain'>It is common for noise to be lost when compressed images, even if it is present in the original image. It is possible to add noise to JPEG XL images to achieve more realistic-looking images. Using a pseudorandom procedure, JPEG XL generates the noise added to the image using an intensity-dependent noise model.</p>
                    <p className='umain'>&#10004; Synthetic noise generation, intensity-modulated</p>
                    <p className='umain'>&#10004; Useful to replicate capture noise/grain or to make surfaces look more realistic</p>
                    <p className='umain'>&#10004; cjxl --photon_noise=ISO3200</p>
                </span>
            <Line/>

            <span id="max-channels" ref={refs['max-channels']}>
                    <h1 className='main'>What is the maximum number of channels for JPEG XL?</h1>
                    <p className='umain'>JPEG XL supports up to 4100 channels, i.e., grayscale or RGB, optional alpha, and up to 4096 "extra" channels.</p>
                </span>
            <Line/>

            <span id="depth-map" ref={refs['depth-map']}>
                    <h1 className='main'>Does JXL support depth map?</h1>
                    <p className='umain'>JPEG XL supports a depth map.</p>
                </span>
            <Line/>

            <span id="overlays" ref={refs['overlays']}>
                    <h1 className='main'>Does JXL support overlays?</h1>
                    <p className='umain'>JPEG XL supports overlays.</p>
                </span>
            <Line/>

            <span id="expert-questions" ref={refs['expert-questions']}>
                    <h1 className='main'>⏩EXPERT QUESTIONS</h1>
                </span>
            <Line/>

            <span id="encoder-architecture" ref={refs['encoder-architecture']}>
                    <h1 className='main'>What does the encoder architecture for JPEG XL look like?</h1>
                    <img className='img1' src={img1}/>
                    <p className='umain'>Input images can be compressed using lossless or lossy compression to create a compressed JPEG XL codestream. The output images can be identical or perceptually similar when decoded. An input JPEG bitstream can be encoded into a JPEG XL file, which contains an image representation codestream and reconstruction data, which can then be decoded back into the original bitstream.</p>
                </span>
            <Line/>

            <span id="coding-modes" ref={refs['coding-modes']}>
                    <h1 className='main'>What coding modes does JPEG XL have?</h1>
                    <p className='umain'>There are two coding modes in JPEG XL:</p>
                    <p className='umain'>VarDCT: lossy encoding of photos, emphasizing human vision; it can also be applied to lossless transcoding of JPEG images.</p>
                    <p className='umain'>Modular: mathematically lossless encoding or generic lossy encoding. When using VariDCT mode, modulated sub-images such as DC, control fields, and extracted image features are stored as described further below.</p>
                    <p className='umain'>Suppose you wish to perform lossless transcoding of JPEG bitstreams (reconstructing both image data and the bit-exact file). In that case, you can also store optional JPEG bitstream reconstruction data.</p>
                </span>
            <Line/>

            <span id="coding-tools" ref={refs['coding-tools']}>
                    <h1 className='main'>What coding tools does JPEG XL provide?</h1>
                    <p className='umain'>One or more frames may be present in a JPEG XL file, for example, for animation or overlays. A frame can be subtracted from a previous frame without motion compensation and having smaller bounding boxes, blend mode, and dispose mode. They may also refer to regions from prior frames that have been designated as reference frames, which can serve as a simple interframe coding function and as a tool for coding the content of a screen for still images.</p>
                    <p className='umain'>Each frame is divided into parallel encoding and decoding groups for efficient decoding of cropped regions of interest (regions of interest). For VarDCT mode, the group size is 256x256 pixels (with special border handling), whereas, for Modular mode, it ranges from 128x128 to 1024x1024 pixels. Aside from thread-parallelism, the JPEG XL codec has also been designed with SIMD (single instruction, multiple data) features, which makes it suitable for efficient CPU encoders and decoders on today's desktop and mobile processors. An emphasis has been placed on reducing header and metadata overhead, including the compression of color profiles and Exif/XMP metadata, which is particularly important to delivering smaller images via the web.</p>
                </span>
            <Line/>

            <span id="variable-block-sizes" ref={refs['variable-block-sizes']}>
                    <h1 className='main'>Does JPEG XL use variable block sizes?</h1>
                    <p className='umain'>JPEG XL uses variable block sizes.</p>
                    <p className='umain'>&#10004; Square: DCT8x8, DCT16x16, DCT32x32, DCT64x64, DCT128x128, DCT256x256</p>
                    <p className='umain'>&#10004; Rectangular: DCT8x16, DCT16x8, DCT16x32, DCT32x16, DCT8x32, DCT32x8, DCT32x64, DCT64x32, DCT64x128, DCT128x64, DCT128x256, DCT256x128</p>
                    <p className='umain'>&#10004; Subdivided 8x8: DCT4x4, DCT2x2, DCT4x8, DCT8x4, "Corner" 8x8 DCT: AFV1 - AFV4, "Hornuss" transform (8x8, not DCT)</p>
                </span>
            <Line/>

            <span id="patches" ref={refs['patches']}>
                    <h1 className='main'>What are Patches in JPEG XL?</h1>
                    <p className='umain'>There can be a "reference frame" that can be used as a basis for working with other frames. This makes it possible to encode repeating elements only once and reuse them repeatedly.</p>
                    <p className='umain'>JPEG XL lets you add patches to images. Patches consist of small pixel patterns. There is some benefit in storing those patches and the positions where they appear in an image, only once when they appear multiple times in the picture. In a DCT-based codec, it is challenging to represent compressed printed text, such as compressed printed text in an image.</p>
                </span>
            <Line/>

            <span id="adaptive-quantization" ref={refs['adaptive-quantization']}>
                    <h1 className='main'>What is Adaptive quantization?</h1>
                    <p className='umain'>Quality can be adjusted locally as necessary with adaptive quantization. With the help of advanced math algorithms, it attempts to choose the best quantizer for each macroblock.</p>
                    <p className='umain'>JPEG only allows a single quantization matrix to be selected (per channel) for a whole image. As a result, quantization is applied uniformly across the board, even if some areas have more detail and might benefit from more quantization.</p>
                    <p className='umain'>JPEG XL still uses a global quantization matrix for each integral transformation; however, it allows this matrix to be scaled locally to decrease artifacts in more complex areas without affecting other parts of the image.</p>
                    <p className='umain'>Encoders can target a roughly uniform amount of loss across the image, avoiding significant variations by combining this with a measure of loss.</p>
                </span>
            <Line/>

            <span id="adaptive-predictor" ref={refs['adaptive-predictor']}>
                    <h1 className='main'>What is an Adaptive predictor?</h1>
                    <p className='umain'>Legacy JPEG increases the compression ratio by subtracting the DC coefficient from the DC coefficient for the currently encoded block. The local similarities in typical images allow for substantial savings in compression ratio.</p>
                    <p className='umain'>However, the prediction mode is rather primitive and does not account for the two-dimensional nature of images. Adaptive prediction in JPEG XL uses eight different modes based on the behavior of nearby pixels to address this issue. DC encoding, as well as encoding of various control fields, are all handled by this predictor.</p>
                    <p className='umain'>When predicting the value of a pixel in position (x,y), the adaptive predictor identifies the prediction mode that is most likely to produce the least amount of error by calculating the maximum error expected for pixels (x-1, y), (x,y, -1) and (-1, y -1). An adaptive predictor that computes the expected error in this way also keeps track of the number of prediction modes expected to reach the level of accuracy calculated.</p>
                    <p className='umain'>Multiple distributions are created based on the expected error and the number of "correct" prediction modes. This technique detects flat areas in an image, uses specific distributions, and achieves significant size savings.</p>
                    <p className='umain'>In lossless mode, this predictor is modified. Lossless predictors use fewer prediction modes (four instead of eight), but the weighted average is calculated instead of choosing one.</p>
                </span>
            <Line/>

            <span id="handle-artifacts-banding" ref={refs['handle-artifacts-banding']}>
                    <h1 className='main'>How does JPEG XL handle artifacts and banding?</h1>
                    <p className='umain'>A notable artifact of JPEG compression at lower quality is banding, a phenomenon in which an area of an image that contains a slowly varying color (such as the sky at sunset) transforms into one that consists of a few bands of the same color.</p>
                    <p className='umain'>As a result, glaring artifacts are at the boundaries between the two bands. DC quantization is the cause of banding in JPEG. With JPEG XL, residuals close to zero in the original image can be encoded with finer quantization steps to avoid this effect. The result is the same as using finer quantization steps for slowly-varying areas, but without sacrificing compression ratio to the extent that finer quantization would be used on the whole image.</p>
                    <p className='umain'>Furthermore, JPEG XL smooths the DC image selectively to reduce banding in areas with steep gradients. The algorithm is only allowed to change values within the quantization boundaries. A smoothed value that would fall outside the quantization boundaries would be discarded. Its original value would be used instead.</p>
                </span>
            <Line/>

            <span id="prevent-banding-artifacts" ref={refs['prevent-banding-artifacts']}>
                    <h1 className='main'>What else does JPEG XL do to prevent banding and artifacts?</h1>
                    <p className='umain'>The low-frequency coefficients of each X * Y transform (top-left corner of the size excluding the corner) are estimated from the DC image by JPEG XL to reduce banding further.</p>
                    <p className='umain'>As the known coefficients are extended to have size, the missing coefficients are filled with zeros, starting with a known coefficient. Then it applies the inverse procedure described in Lemma 4.1 to each block so that the DC image is 2* upsampled.</p>
                    <p className='umain'>The smoothed DC image is converted back into DCT coefficients using a smoothing algorithm similar to the one used on the DC image; the produced low-frequency values are then added to the encoded low-frequency coefficients.</p>
                </span>
            <Line/>

            <span id="loop-filters" ref={refs['loop-filters']}>
                    <h1 className='main'>What are Loop Filters?</h1>
                    <p className='umain'>Although JPEG XL delivers substantial improvements in artifact reduction, block boundaries and ringing can still be seen, particularly at lower qualities. Ringing artifacts are spurious signals that occur in sharp edges when high-frequency components are quantized or truncated. JPEG XL uses two loop filters to reduce their impact following the decompression process.</p>
                    <p className='umain'>First, we have a smoothing convolution loop filter. During the DCT step, a sharpening filter is applied before the smoothing process to compensate for the inherent sharpness loss when smoothing an image. As a result, block boundaries are reduced in visual impact while sharp details from the original image are preserved.</p>
                    <p className='umain'>By using the second loop filter, ringing can be reduced while the texture is still preserved. A non-local smoothing algorithm is applied to achieve this effect, but with enhancements to speed up the processing.</p>
                    <p className='umain'>Moreover, the JPEG XL format allows the decoder to add a quantization constraint to the output of this filter to improve the detail preservation of the image: The DCT step is repeated, and the decoder ensures that the resulting coefficients do not exceed the value from the bitstream which was quantized by clamping. After applying an IDCT step, the decoder produces the final output of the filter.</p>
                </span>
            <Line/>

            <span id="ac-encoding" ref={refs['ac-encoding']}>
                    <h1 className='main'>AC Encoding</h1>
                    <p className='umain'>In JPEG, AC coefficients are encoded in zig-zag order, which increases the sum of coordinates within the block in increasing order.</p>
                    <p className='umain'>Its zig-zag order is generalized to those cases since JPEG XL has different transform sizes. Furthermore, since this order may not be optimal for a particular image, a custom order may be encoded. A Lehmer15-like code is employed to encode efficient identification permutations for this custom order, which encrypts the zig-zag order as a permutation of the zig-zag order.</p>
                    <p className='umain'>In the encoding of AC coefficients, the order of encoding is followed; it keeps track of the number of non-zero coefficients in the current block, and uses this value, along with the position in the current block, to split the coefficients into multiple distributions. Whenever there are no non-zero coefficients left in the bitstream, no further values are present.</p>
                </span>
            <Line/>

            <span id="entropy-coding" ref={refs['entropy-coding']}>
                    <h1 className='main'>Entropy Coding</h1>
                    <p className='umain'>For entropy coding, legacy JPEG formats support either Huffman coding12 or Arithmetic Coding13. There are some drawbacks to both of these methods: Huffman coding is suboptimal when symbol probabilities are not exact powers of two, and Arithmetic Coding implementations are typically slow.</p>
                    <p className='umain'>JPEG XL uses ANS (also called Asymmetric Numeral Systems14). This recently introduced entropy coder achieves compression ratios similar to Arithmetic Coding, while decoding is significantly faster.</p>
                    <p className='umain'>ANS symbols drawn from multiple probability distributions are encoded as JPEG XL. We provide encoders with more flexibility using two techniques: distribution clustering and variable-precision distribution encoding.</p>
                    <p className='umain'>Many different distributions of symbols can be included in an ANS stream. The amount of data that would have to be encoded for each of these distributions would be significant, making this a sub-optimal choice except for the longest streams. As a result, JPEG XL allows defining clusters of similar distributions, with only one histogram encoded for the whole cluster. With this method, clustering can be set up per-file instead of a fixed setting, allowing for more flexibility than fewer distributions. A move-to-front transformation is used to encode the clusters. Run-length encoding is used to minimize bitstream overhead.</p>
                </span>
            <Line/>

            <span id="working-with-jpeg-xl" ref={refs['working-with-jpeg-xl']}>
                    <h1 className='main'>⏩WORKING WITH JPEG XL</h1>
                </span>
            <Line/>

            <span id="use-cases" ref={refs['use-cases']}>
                    <h1 className='main'>What are the use cases for JPEG XL?</h1>
                    <p className='umain'>User interfaces and websites are becoming increasingly image-driven. Imagery plays a vital role in user interaction, selecting topics, stories, movies, articles, etc. We create, upload, download, and reupload millions of images each day, such as on social media. With JPEG XL, providers can reduce bandwidth usage, increase download speed, and eliminate generation loss.</p>
                    <p className='umain'>E-commerce websites can present higher resolution images of their products and services. A correlation has been observed between catalog image quality and sales. As a result, JPEG XL delivers high-quality images while retaining performance, vital for conversion purposes.</p>
                    <p className='umain'>Many users upload their images to cloud storage applications. Depending on how they are uploaded, the images are stored either as a copy or after transcoding, either lossless or lossy. You can transcode images to more efficient formats and lower resolutions when browsing and creating timeline-style thumbnails. It is helpful to convert pictures to more efficient formats for storing and browsing.</p>
                    <p className='umain'>News agencies, journalists, and users capture images, which are then published on media sites. Images can be high-resolution or thumbnail-sized, resulting in web pages containing multiple megabyte images. Webmasters with extensive collections of JPEG images should benefit from a new format to facilitate its adoption.</p>
                    <p className='umain'>Animations are often used to convey emotions and increase interactivity. The vast majority of animated image sequences are encoded using the GIF file format, with a limited color palette and inefficient compression. Animations of this type are brief and play in a loop, and they can often be stored in memory. Existing aPNG, WebP, and GIF files can be used as examples of existing animations. All animations need to support transparency to be used as overlays. In browsers, users can use seeking when switching between tabs. As soon as the user returns to the tab, the animation should resume where it was.</p>
                    <p className='umain'>A user's decision to download or remove a mobile application or game often depends on the download and installation size. Typically, images take up most of the total file size, and effective compression can help reduce the file size. A fast decoding rate is essential for quick loading.</p>
                    <p className='umain'>Users often collect dozens of images in albums, such as vacation pictures. Photos of this type have a large resolution, such as 20-30 Megapixels. Users expect fast response times and faithful reproduction on tablets at full-screen resolution (currently around 2 Megapixels).</p>
                    <p className='umain'>HDR/WCG images are needed in many applications, such as on-demand video services and gaming, to support HDR/WCG videos or enhance the user experience. For example, a user may want to store single frames (stills) from movies. There are currently no formats that can display HDR/WCG content. Such applications require a new HDR/WCG coding standard. ICC defines the format as enabling content creators to specify the rendering intent.</p>
                    <p className='umain'>Cameras (medium-format) currently produce images with 100-400 MPixels. These should be opened to be viewed (at full resolution) or processed immediately. Raw (color-filter array) images can also be stored as lossless/near-lossless encodings. Images may later be 'cooked' using more advanced processing algorithms.</p>
                    <p className='umain'>The printing industry widely uses CMYK color models. In addition, spot colors can be applied in additional channels. To distribute images intended for printing, high-resolution and lossless or high quality are required, as is the capability to show CMYK and possibly additional channels (for spot colors/OGV). As CMYK is generally used for interchange rather than storage or delivery, its storage will typically be lossless or near-lossless.</p>
                </span>
            <Line/>

            <span id="can-i-use-jpeg-xl" ref={refs['can-i-use-jpeg-xl']}>
                    <h1 className='main'>Can I use JPEG XL?</h1>
                    <p className='umain'>For now, you're free to use JPEG XL however you'd like. Due to the format's newness, support is minimal.</p>
                </span>
            <Line/>

            <span id="should-i-use-jpeg-xl" ref={refs['should-i-use-jpeg-xl']}>
                    <h1 className='main'>Should I use JPEG XL?</h1>
                    <p className='umain'>If you do not have enough hard drive space, you can utilize JPEG XL to store your images. JPEG XL can be used to test the performance. Await more support before going full commit!</p>
                </span>
            <Line/>

            <span id="use-jpeg-xl-website" ref={refs['use-jpeg-xl-website']}>
                    <h1 className='main'>Should I use JPEG XL for my website?</h1>
                    <p className='umain'>Not yet. No browser support is enabled by default. Expect support in Summer 2022.</p>
                </span>
            <Line/>

            <span id="new-hardware" ref={refs['new-hardware']}>
                    <h1 className='main'>Do I need new hardware to encode and decode JPEG XL?</h1>
                    <p className='umain'>With JPEG XL, images are significantly better quality, compressed more efficiently, and have shorter specifications. Using software implementations, this encoding and decoding technology is designed to be computationally efficient, even on mobile devices, without additional hardware acceleration.</p>
                </span>
            <Line/>

            <span id="convert-images" ref={refs['convert-images']}>
                    <h1 className='main'>How can I convert images to JPEG XL?</h1>
                    <p className='umain'>&#10004; Use the reference software cjxl/djxl available on GitLab</p>
                    <p className='umain'>&#10004; Or convert images on jpegxl.io</p>
                </span>
            <Line/>


            <span id="recompress-jpg" ref={refs['recompress-jpg']}>
                    <h1 className='main'>Can I recompress my JPG images to JXL losslessly?</h1>
                    <p className='umain'>JPEG recompression allows seamless migration from legacy JPEG to JPEG XL. Existing JPEG files can be converted to the newer, more compact format while saving up to 20% of their original size. The format supports the transcoding of progressive JPEGs, but the reference software has yet to implement it.</p>
                </span>
            <Line/>

            <span id="jpeg-xl-support" ref={refs['jpeg-xl-support']}>
                    <h1 className='main'>⏩JPEG XL SUPPORT</h1>
                </span>
            <Line/>

            <span id="image-viewers" ref={refs['image-viewers']}>
                    <h1 className='main'>What image viewers support JPEG XL?</h1>
                    <p className='umain'>Many image viewers support JPEG XL:</p>
                    <p className='umain'>&#10004; Digikam</p>
                    <p className='umain'>&#10004; Geeqie</p>
                    <p className='umain'>&#10004; Gimp</p>
                    <p className='umain'>&#10004; Gthumb</p>
                    <p className='umain'>&#10004; Gwenview</p>
                    <p className='umain'>&#10004; Imageglass</p>
                    <p className='umain'>&#10004; Irfanview</p>
                    <p className='umain'>&#10004; nomacs</p>
                    <p className='umain'>&#10004; Photoqt</p>
                    <p className='umain'>&#10004; PhotoStack</p>
                    <p className='umain'>&#10004; PicView</p>
                    <p className='umain'>&#10004; qimgv</p>
                    <p className='umain'>&#10004; XnView</p>
                </span>
            <Line/>

            <span id="browsers" ref={refs['browsers']}>
                    <h1 className='main'>What browsers support JPEG XL?</h1>
                    <p className='umain'>Many browsers support JPEG XL behind a flag:</p>
                    <p className='umain'>&#10004; Brave</p>
                    <p className='umain'>&#10004; Chrome</p>
                    <p className='umain'>&#10004; Edge</p>
                    <p className='umain'>&#10004; Firefox</p>
                    <p className='umain'>&#10004; Opera</p>
                    <p className='umain'>&#10004; Vivaldi</p>
                </span>
            <Line/>

            <span id="cdns" ref={refs['cdns']}>
                    <h1 className='main'>What CDNs support JPEG XL?</h1>
                    <p className='umain'>Not many CDNs support JPEG XL:</p>
                    <p className='umain'>&#10004; Cloudinary</p>
                    <p className='umain'>&#10004; ShimmerCat</p>
                </span>
            <Line/>

            <span id="operating-systems" ref={refs['operating-systems']}>
                    <h1 className='main'>What Operating system support JPEG XL?</h1>
                    <p className='umain'>Some OS support JPEG XL natively, others with help:</p>
                    <p className='umain'>&#10004; KaOS</p>
                    <p className='umain'>&#10004; OpenMandriva</p>
                    <p className='umain'>&#10004; macOS</p>
                    <p className='umain'>&#10004; Windows</p>
                </span>
            <Line/>

            <span id="mobile-phones" ref={refs['mobile-phones']}>
                    <h1 className='main'>Do mobile phones support JPEG XL?</h1>
                    <p className='umain'>No mobile Operating System supports JPEG XL. Chrome Canary and Firefox Nightly support viewing JPEG XL images after enabling support in the flags.</p>
                </span>
            <Line/>

            <span id="compare-jpeg-xl-with-jpeg" ref={refs['compare-jpeg-xl-with-jpeg']}>
                    <h1 className='main'>Compare JPEG XL with JPEG</h1>
                    <p className='umain'>Over the years, the legacy JPEG format has been thoroughly examined, with most of its drawbacks being addressed in JPEG XL:</p>
                    <p className='umain'>&#10004; DC coefficients are predicted more robustly.</p>
                    <p className='umain'>&#10004; The coefficients of AC0x and AC0x are predicted using neighboring blocks.</p>
                    <p className='umain'>&#10004; ANS and Binary Arithmetic Coding replace Huffman Entropy Coding</p>
                    <p className='umain'>&#10004; Most commonly used ICC profiles, Huffman code tables, and quantization tables are encoded as template parameters.</p>
                    <p className='umain'>&#10004; Using context modeling, we can separate entropy sources.</p>
                    <p className='umain'>&#10004; In the DCT, coefficients are reordered so that more blocks have longer series of zeros at the end. In contrast to limited RLE, the index of the last non-zero coefficient is encoded explicitly.</p>
                    <p className='umain'>According to research, those improvements enable sizes to be reduced by 16% on average in a corpus of 100 000 random web images. Depending on the JPEG encoder and quality settings, the savings range from 13% to 22% for larger photographic images.</p>
                </span>
            <Line/>
            <RelatedT related={articles}/>

        </article>
        <ConvertButton/>
        <br/>
    </>)
}

export default FAQ;