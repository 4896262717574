import Heading from "../Components/Heading/Heading";
import LiContent from "../Components/LiContent/LiContent";
import Line from "../Components/Line/Line";
import ConvertButton from "../Components/ConvertButton";
import React from "react";
import RelatedT from "../Components/RelatedTutorials/Related";
import img1 from '../../images/code1.png'
import img2 from '../../images/code2.png'
import img3 from '../../images/code3.png'
import FAQComponent from "../Components/FAQComponent/FAQComponent";

function SEO() {
    const articles = [{
        href: '/support-overview',
        name: 'JPEG XL - Browser Support Overview',
        text: 'Check if your browser supports the new image format JPEG XL.'
    }, {
        href: '/faq',
        name: 'JPEG XL FAQ',
        text: 'Frequently asked questions about the new JPEG XL format answered here.'
    }, {
        href: '/seo', name: 'Image SEO', text: 'Learn how to fully optimize your images from a SEO perspective.'
    }, {href: '/sitemap', name: 'Image SiteMap', text: 'Learn how to create image sitemaps in 2024.'}, {
        href: '/solution',
        name: 'JPEG XL - A comprehensive Solution',
        text: 'JPEG XL aims to replace standard image formats. Learn why JPEG XL could replace PNG, GIF, and JPEG altogether.'
    }, {
        href: '/patent',
        name: 'ANS Patent',
        text: 'Is Microsoft\'s patent on rANS jeopardizing JPEG XL and open-source development? Find out why ANS creator fears potential infringement claims in this must-read article for software engineers and image experts.'
    },];
    return (
        <>
            <Heading h='Optimize your images for SEO' p='#articles'/>
            <article>
                <nav className='content_table'>
                    <h4>Table of Content</h4>
                    <ol className='content_ol'>
                        <LiContent n='1' a='#introduction' text='Introduction'/>
                        <LiContent n='2' a='#adv4ntages' text='Advantages'/>
                        <LiContent n='3' a='#content-tips' text='Content Tips'/>
                        <LiContent n='4' a='#description-tips' text='Description Tips'/>
                        <LiContent n='5' a='#storage-tips' text='Storage Tips'/>
                        <LiContent n='6' a='#performance-tips' text='Performance Tips'/>
                    </ol>
                </nav>
                <br/>
                <Line/>
                <br/>
                <span id='introduction'>
                    <h1 className="main">Introduction</h1>
                    <p className="umain"><b>What this guide is about</b></p>
                    <p className="umain">Most people focus on the easy-to-pick-up parts of image SEO (Search Engine Optimization): loading-time optimization and alternative text. Images SEO is a much deeper topic. SEO is one factor that affects how your images rank in Google images, but it's not the only one. Search engine optimization of your images contributes to the overall ranking potential of your page as well: optimizing your images will improve your rankings. Using this SEO guide, you can optimize your images for search engines.</p>
                    <p className="umain"><b>Images matter</b></p>
                    <p className="umain">Websites have many different elements. The users can view some of these, whereas others are not, such as source code. Websites mainly consist of images, along with text. Product images influence customers' purchasing behavior in e-commerce, and their quality and design are critical factors to their success. Retailers such as Zalando regularly optimize images for optimal results. Both visible and invisible factors contribute to the best images. While a typical SEO campaign focuses on a website, image SEO aims to achieve good rankings for images themselves. Image SEO plays an integral part in a comprehensive search engine optimization strategy.</p>
                    <p className="umain"><b>What is image SEO?</b></p>
                    <p className="umain">Images can be optimized for search engines using variables such as alt text, appropriate captions, and optimal file sizes. When you optimize your content for images, search engine crawlers can better understand your content, enabling you to rank higher on search results pages and image results pages.</p>
                </span>
                <Line/>

                <span id='adv4ntages'>
                    <h1 className="main">Advantages</h1>
                    <p className="umain"><b>Visibility</b></p>
                    <p className="umain">Most people focus on the easy-to-pick-up parts of image SEO (Search Engine Optimization): loading-time optimization and alternative text. Images SEO is a much deeper topic. SEO is one factor that affects how your images rank in Google images, but it's not the only one. Search engine optimization of your images contributes to the overall ranking potential of your page as well: optimizing your images will improve your rankings. Using this SEO guide, you can optimize your images for search engines.</p>
                    <p className="umain"><b>Relevance</b></p>
                    <p className="umain">By tagging important pictures, a website's entire theme can be improved. Due to this, it becomes more likely for a website to rank well in Google search.</p>
                    <p className="umain"><b>Engagement</b></p>
                    <p className="umain">A website with high-quality and properly compressed images is more beneficial. Mobile optimization requires compressing images to use smartphones on the go so that limited bandwidth can be used. This may result in slower page loading times.</p>
                </span>
                <Line/>

                <span id='content-tips'>
                    <h1 className="main">Content Tips</h1>
                    <p className="umain"><b>Start using images</b></p>
                    <p className="umain">To help your readers understand your article better, you should illustrate it. The presence of images can spice up 1,000 dull words, enhance an infographic, or add spice to a social media post, no matter what Google thinks. Images appeal to each article, and visual content is predicted to dominate future searches. Consequently, you may gain more traffic. You have images on your website, so prioritizing image SEO should be a priority. Google Images has filters, metadata, and attribution. Google shows its growing understanding of images and their contexts among its new filters.</p>
                    <p className="umain"><b>Use original images</b></p>
                    <p className="umain">To rank well in images searches, you have to produce original images that Google (and readers) will appreciate. For eCommerce sites, product images are a must. Shopping online is done by examining pictures, and over half of online buyers attribute their buying decisions to product images. Without clear images that highlight your product, you'll be hidden behind those of your competitors. A high-quality image of your site may be linked to a company. As a result, images can significantly impact the number of leads and customers your business receives.</p>
                    <p className="umain"><b>Don't embed text</b></p>
                    <p className="umain">The use of text in images is not recommended, especially for essential text elements such as menus and page headings. Not all users will see the image translation tool's output. Be sure to include alt text with images. Format your content in HTML. It indexes HTML images but not CSS images.</p>
                    <p className="umain"><b>EXIF</b></p>
                    <p className="umain">EXIF metadata is added to a photograph. Date, time, camera model, and location data are embedded. Police often use this information to pinpoint an image's location. To understand your image, Google algorithms rely on these files. Optimizing your image shouldn't include EXIF data. Optimizing image names, alternative texts, and compression should be your number one priority. You should optimize the EXIF Data of a few images right away if you haven't done so already. Increasing this element could significantly increase your rankings.</p>
                </span>
                <Line/>

                <span id='description-tips'>
                    <h1 className="main">Description Tips</h1>
                    <p className="umain"><b>Introduction</b></p>
                    <p className="umain">Google Images automatically generates a snippet and title to clarify a result's relationship with the user's query. So, the user will figure out what the image represents and whether it is worth clicking on. We obtain information about each page from multiple sources, including its title and meta tags. As you improve the title and snippet of your page, Google favors it more.</p>
                    <p className="umain"><b>Use a good alternative text</b></p>
                    <p className="umain">Alternative texts tend to be unknown to most people, so they cannot create them. These texts describe how images will appear. The first thing to comprehend is that alt text, also known as alt tags or alt descriptions, helps search engines index a website more effectively, thereby improving its search engine rankings. Additionally, alt text can improve the user experience. Alternative text can be used for blind readers and when an image cannot be loaded.</p>
                    <p className="umain">An ALT tag describes both the content of the image and its function on a web page. To every image on your website that serves a purpose, you should provide an alternative text. While Google uses computer vision algorithms to determine what the image's subject matter is, it also considers the page's content when determining what the image's subject matter is. Ensure that your alt text on web pages is helpful, informative, use relevant keywords, and is contextually relevant. Your site could be regarded as spam in the future if it is stuffed with keywords in the alt attribute (Keyword Stuffing).</p>
                    <p className="umain"><b>The benefits of ALT text</b></p>
                    <p className="umain">To do it right, your alt text should fulfill three functions:</p>
                    <p className="umain">&#10004; Accessibility: The alt text allows visually impaired or blind users to read the content of the picture so they can access it.</p>
                    <p className="umain">&#10004; Back-up: When the browser cannot load the file, ALT tags are displayed instead of the image.</p>
                    <p className="umain">&#10004; SEO: It not only enhances the semantic meaning of your page but also assists search engines in finding your image's content. Therefore, image SEO is crucial for Google Image search ranking and will also result in a positive effect on your overall SEO campaign.</p>
                    <img className='code1' src={img1}/>
                    <p className="umain"><b>Use captions</b></p>
                    <p className="umain">Using the text of the page, the caption, and the picture's title, Google determines what the picture is about. Whenever possible, place images next to relevant text and alongside related pages. I have not included captions in this blog post because I have included contextual descriptions of each image instead. You might consider adding a caption to an image-rich site that explains its placement on the page. While captions are not recommended, they may be helpful for readers (and bots) in deciphering an image.</p>
                    <p className="umain">On the page, you will find a caption for each image. Do captions matter for image SEO? People check the captions first. People are drawn to headings, photos, and captions when scanning a website. FIGCAPTION describes a picture underneath its image. Titles should not be blank, alt texts should be descriptive, and image names should be brief; however, captions should be interesting. If possible, include SEO-friendly keywords in your punchline. Start by considering how users will feel.</p>
                    <p className="umain"><b></b></p>
                    <img className='code2' src={img2}/><br/><br/>
                    <p className="umain"><b>Schema</b></p>
                    <p className="umain">Those who use Google for information are likely more familiar with schema markup. Are you currently optimizing images for schema markup? The relevant images are also displayed as thumbnails in Google's image search results for mobile devices.</p>
                    <p className="umain">Follow Google's guidelines to list your images in Google image searches. The structured data property must match the image type, for example. Moreover, your pictures should have the image attribute, and they should be crawlable and searchable. You can find a list of Google's structured data guidelines here.</p>
                </span>
                <span id='storage-tips'>
                    <h1 className="main">Storage Tips</h1>
                    <p className="umain"><b>Name your files the right way</b></p>
                    <p className="umain">You can upload pictures to your website more effectively if you rename the file name so that search engines understand what the picture is. As with alt text, use keywords rather than generic "img20190102" to describe what the image contains. In addition, it will ensure your image appears in the search results for image searches, thereby attracting traffic to your site and improving its ranking. Ensure your pictures accurately represent what your site visitors will see. Furthermore, the picture will appear within the context of the site. Google and Bing can determine the topic of a website based on the domain's name.</p>
                    <p className="umain">An image should be given a short descriptive name. Having analyzed data since the creation of our plugin, we have found that the most compelling photos have approximately 12 characters. It would be best to aim for images between 8 and 16 characters in length and stay within this range. Use a maximum of two or three keywords per image. Google has no problem understanding your photos if you don't stuff them with keywords.</p>
                    <img src={img3} className='code3'/>
                    <br/>
                    <br/>
                    <br/>
                    <p className="umain"><b>Use a Sitemap</b></p>
                    <p className="umain">If you want search engines to find your images, you should add them to your sitemap - or you can create a page for images. You can access these images by utilizing JavaScript forms that Google can't crawl. Your images must be indexed by Google first to appear in the SERP. Create image sitemaps to help Google index your images, and make your site successful by influencing the indexing process. The use of a sitemap is not necessary if your site's internal linking is optimized and your site is small. Having a large site can cause crawlers to miss some of your content. Sitemaps and image sitemaps guarantee that Google can index your content in this respect: sitemaps and image sitemaps help SEO.</p>
                </span>
                <span id='performance-tips'>
                    <h1 className="main">Performance Tips</h1>
                    <p className="umain"><b>Compress images</b></p>
                    <p className="umain">Image compression should be included in a website optimization strategy. What makes this possible? By making your website load faster, you'll be able to improve user experience, and you'll improve your website's search engine ranking. Modern file formats such as WebP, AVIF, or JPEG XL can provide the best compression.</p>
                    <p className="umain"><b>Use responsive images</b></p>
                    <p className="umain">The images on your site must be visible on any device so that readers can access your content. Search engine optimization and user experience are enhanced when your site is optimized for mobile. If your image is non-responsive, it won't look as attractive on mobile devices, negatively affecting your SEO and your reader's perception of your brand. Using HubSpot automatically ensures your images are responsive.</p>
                </span>
                <Line/>
                <br/><FAQComponent/><br/>
                <RelatedT related={articles}/>
            </article>


            <ConvertButton/>
            <br/>
        </>
    )
}

export default SEO;